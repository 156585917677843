/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Button, Modal, Tooltip } from "flowbite-react";
import { toast } from "sonner";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteEntityQuestion,
  useDeleteEntityQuestionsV2,
  useDeleteQuestionCategories,
  useDeleteQuestionKeyword,
  useGetQuestionCategories,
  useGetQuestionKeywords,
  useGetQuestionSets,
  useGetSetIdsForQuestions,
  usePostAddEntityQuestion,
  usePostAddQuestionCategories,
  usePostAddQuestionKeyword,
  usePostAddQuestionSet,
  usePostUpdateEntityQuestionsV2,
  usePutEditQuestionCategories,
  usePutEditQuestionKeyword,
  usePutUpdateEntityQuestion,
} from "../../../query/useEntity";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import { Asterisk, CirclePlus, CircleX, Pencil, X } from "lucide-react";
import { Switch } from "@headlessui/react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { DeleteModal } from "./modal";
import QuestionSetDropdownForModal from "./QuestionSetDropdownForModal";
import CategoryDropdownForModal from "./CategoryDropdownForModal";
import KeywordDropdownForModal from "./KeywordDropdownForModal";
import ResponseOptionsForModal from "./ResponseOptionsForModal";

const EditQuestionModal = ({
  openEditModal,
  setOpenEditModal,
  refetchQuesitons,
  item,
  // question,
  // setQuestion,
}) => {
  const inputRef = useRef(null);
  // const categoryRef = useRef(null);
  // const keywordRef = useRef(null);
  // const questionSetRef = useRef(null);
  // const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [newCategory, setNewCategory] = useState("");
  // const [openCategoryEdit, setOpenCategoryEdit] = useState("");
  // const [editCategory, setEditCategory] = useState("");
  const [question, setQuestion] = useState({
    question: "",
    category_link: null,
    keyword_link: null,
    response_options: ["Excellent", "Good", "Average", "Bad"],
    has_multiple_responses: false,
    show_as: "CHIPS",
    sets: [],
    is_mandatory: false,
  });

  // const [openKeywordSuggestionBox, setOpenKeywordSuggestionBox] =
  //   useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  // const [newKeyword, setNewKeyword] = useState("");
  // const [openKeywordEdit, setOpenKeywordEdit] = useState("");
  // const [editKeyword, setEditKeyword] = useState("");

  // const [openQuestionSetBox, setOpenQuestionSetBox] = useState(false);
  const [selectedQuestionSets, setSelectedQuestionSets] = useState([]);
  // const [newQuestionSet, setNewQuestionSet] = useState("");

  const [isOpenDeleteCategory, setIsOpenDeleteCategory] = useState(false);
  const [deleteCategorySlug, setDeleteCategorySlug] = useState("");

  const [isOpenDeleteKeyword, setIsOpenDeleteKeyword] = useState(false);
  const [deleteKeywordSlug, setDeleteKeywordSlug] = useState("");

  const { data: categories, refetch: refetchCategory } =
    useGetQuestionCategories();
  // const { mutate: addCategory, isLoading: addCategoryLoading } =
  //   usePostAddQuestionCategories();
  // const { mutate: updateCategory, isLoading: editCategoryLoading } =
  //   usePutEditQuestionCategories();
  const { mutate: deleteCategory, isLoading: deleteCategoryLoading } =
    useDeleteQuestionCategories();

  const { data: keywords, refetch: refetchKeyword } = useGetQuestionKeywords();
  // const { mutate: addKeyword, isLoading: addKeywordLoading } =
  //   usePostAddQuestionKeyword();
  // const { mutate: updateKeyword, isLoading: editKeywordLoading } =
  //   usePutEditQuestionKeyword();
  const { mutate: deleteKeyword, isLoading: deleteKeywordLoading } =
    useDeleteQuestionKeyword();

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();
  // const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
  //   usePostAddQuestionSet();

  const { data: setIdsForQuestions, isLoading: setIdsForQuesitonsLoading } =
    useGetSetIdsForQuestions(item?.id, {
      enabled: Boolean(item?.id),
    });

  const { mutate: addQuestion, isLoading: addQuestionLoading } =
    usePostAddEntityQuestion();
  const { mutate: updateQuestion, isLoading: updateQuestionLoading } =
    usePutUpdateEntityQuestion();
  const queryClient = useQueryClient();

  // const handleAddCategory = () => {
  //   addCategory(
  //     {
  //       category: selectedCategory,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Added new category successfully");
  //         refetchCategory();
  //         setSelectedCategory(res?.question_category?.category);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           current.category_link = res?.question_category?.id;
  //           return current;
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleUpdateCategory = () => {
  //   updateCategory(
  //     {
  //       category: editCategory,
  //       category_slug: openCategoryEdit,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Updated category successfully");
  //         setOpenCategoryEdit("");
  //         refetchCategory();
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error("Something went wrong");
  //         }
  //       },
  //     }
  //   );
  // };

  const handleDeleteCategory = () => {
    deleteCategory(deleteCategorySlug, {
      onSuccess: (res) => {
        toast.success("Deleted category successfully");
        refetchCategory();
        setIsOpenDeleteCategory(false);
        const deleteCategory = deleteCategorySlug?.split("-")?.join(" ");
        if (deleteCategory === selectedCategory?.toLowerCase()) {
          setSelectedCategory(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.category_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  // const handleAddKeyword = () => {
  //   addKeyword(
  //     {
  //       keyword: selectedKeyword,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Added new keyword successfully");
  //         refetchKeyword();
  //         setSelectedKeyword(res?.question_keyword?.keyword);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           current.keyword_link = res?.question_keyword?.id;
  //           return current;
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleUpdateKeyword = () => {
  //   updateKeyword(
  //     {
  //       keyword: editKeyword,
  //       keyword_slug: openKeywordEdit,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Updated keyword successfully");
  //         setOpenKeywordEdit("");
  //         refetchKeyword();
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  const handleDeleteKeyword = () => {
    deleteKeyword(deleteKeywordSlug, {
      onSuccess: (res) => {
        toast.success("Deleted keyword successfully");
        refetchKeyword();
        setIsOpenDeleteKeyword(false);
        const deleteKeyword = deleteKeywordSlug?.split("-")?.join(" ");
        if (deleteKeyword === selectedKeyword?.toLowerCase()) {
          setSelectedKeyword(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.keyword_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  // const handleAddQuestionSet = () => {
  //   addQuestionSet(
  //     {
  //       name: newQuestionSet,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success(res.message);
  //         setNewQuestionSet("");
  //         setSelectedQuestionSets((prev) => [
  //           ...prev,
  //           { name: res?.Set?.name },
  //         ]);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           const currentSets = [...current.sets, { name: res?.Set?.name }];
  //           return { ...current, sets: currentSets };
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  const handlePush = () => {
    updateQuestion(
      {
        id: item.id,
        data: {
          question: question.question,
          category_link: question.category_link,
          keyword_link: question.keyword_link,
          response_options: question.response_options,
          has_multiple_responses: question.has_multiple_responses,
          show_as: question.show_as,
          sets: question.sets,
          is_mandatory: question.is_mandatory,
        },
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestions"],
          });
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestionsForSets"],
          });
          setOpenEditModal(false);
          toast.success("Question updated successfully");
          setQuestion();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else if (err.response.data.category_link) {
            toast.error(err.response.data.category_link[0]);
          } else if (err.response.data.keyword_link) {
            toast.error(err.response.data.keyword_link[0]);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"][0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleResponseOptionChange = (value, index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      current.response_options[index] = value;
      return current;
    });
  };

  const handleResponseOptionDelete = (index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      const options = [...current.response_options];
      current.response_options = options.filter((_, i) => i !== index);
      return current;
    });
  };

  const handleAddOption = () => {
    setQuestion((prev) => {
      const current = { ...prev };
      let options;
      if (current.response_options === null) {
        options = ["New Option"];
      } else {
        options = [...current.response_options];
        options.push("New Option");
      }

      current.response_options = options;
      return current;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setQuestion((prev) => {
          const current = { ...prev };
          const addedValues = e.target.result
            ?.split("\n")
            ?.map((item) => item.trim());
          let options;
          if (current.response_options === null) {
            options = [...addedValues];
          } else {
            options = [...current.response_options, ...addedValues];
          }
          current.response_options = options;
          return current;
        });
      };
      reader.readAsText(file);
    }
    e.target.value = "";
  };

  const isLoading = addQuestionLoading || updateQuestionLoading;

  const sets = useMemo(() => {
    return questionSets
      ?.filter((item) => setIdsForQuestions?.set_ids?.includes(item?.id))
      ?.map((item) => {
        return {
          name: item?.name,
        };
      });
  }, [questionSets, setIdsForQuestions]);

  useEffect(() => {
    if (item) {
      setSelectedCategory(item?.category);
      setSelectedKeyword(item?.keyword);
      setSelectedQuestionSets(sets);
      setQuestion((prev) => {
        const current = { ...prev };
        (current.question = item?.question),
          (current.category_link = item?.category_link);
        current.keyword_link = item?.keyword_link;
        current.response_options = item?.response_options;
        current.has_multiple_responses = item?.has_multiple_responses;
        current.show_as = item?.show_as;
        current.sets = sets;
        current.is_mandatory = item?.is_mandatory;
        return current;
      });
    }
  }, [item, sets]);

  // useOnClickOutside({
  //   ref: categoryRef,
  //   setIsOpen: setOpenSuggestionBox,
  //   prevent: isOpenDeleteCategory,
  // });
  // useOnClickOutside({
  //   ref: keywordRef,
  //   setIsOpen: setOpenKeywordSuggestionBox,
  //   prevent: isOpenDeleteKeyword,
  // });
  // useOnClickOutside({ ref: questionSetRef, setIsOpen: setOpenQuestionSetBox });

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <Modal
        popup
        // dismissible
        show={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        size={"5xl"}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative p-4 h-auto overflow-y-hidden",
            inner: "bg-white dark:bg-darkBg rounded-lg",
          },
          sizes: {
            sm: "max-w-sm",
            md: "max-w-md",
            lg: "max-w-lg",
            xl: "max-w-xl",
            "2xl": "max-w-2xl",
            "3xl": "max-w-3xl",
            "4xl": "max-w-4xl",
            "5xl": "max-w-5xl",
            "6xl": "max-w-6xl",
            "7xl": "max-w-7xl",
          },
          header: {
            base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
      >
        <Modal.Header>
          <header className="flex items-center mb-3 mt-5 px-[24px] sm:px-[35px] ">
            <h1 className="text-[24px] font-medium font-pro ">Edit Question</h1>
          </header>
        </Modal.Header>
        <Modal.Body className="px-[24px] sm:px-[35px] flex flex-col scrollbar-none lg:w-[800px] font-pro">
          <div className="h-[70vh] overflow-y-auto scrollbar-thin p-1">
            {/* <div className="my-5 space-y-3">
              <p className="text-base lg:text-[20px]">Question Set</p>
              <div className="w-full flex items-center gap-x-5">
                <div className="relative max-w-md w-full" ref={questionSetRef}>
                  <input
                    autoFocus={false}
                    value={
                      newQuestionSet
                        ? newQuestionSet
                        : selectedQuestionSets?.length > 0
                          ? selectedQuestionSets
                              ?.map((item) => item?.name)
                              ?.join(", ")
                          : ""
                    }
                    onChange={(e) => setNewQuestionSet(e.target.value)}
                    onClick={() => {
                      setOpenQuestionSetBox((prev) => !prev);
                    }}
                    autoComplete={"off"}
                    id="questionSet"
                    placeholder="Select Sets or Add New Set"
                    className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6]"
                  />
                  {openQuestionSetBox && (
                    <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white z-[60] overflow-y-auto scrollbar-thin shadow-xl border ">
                      <div
                        className="py-2 hover:text-secondary cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setQuestion((prev) => {
                            const current = { ...prev };
                            current.sets = [];
                            return current;
                          });
                          setSelectedQuestionSets([]);
                          setOpenQuestionSetBox(false);
                        }}
                      >
                        {"- No selected question set -"}
                      </div>
                      {
                        <>
                          {questionSets
                            ?.filter((item) => item?.is_default)
                            ?.map((item) => (
                              <div
                                key={item?.name}
                                className="flex items-center gap-x-3 py-2"
                                onClick={(e) => {
                                  e.preventDefault();

                                  setQuestion((prev) => {
                                    const current = { ...prev };
                                    const currentSets = current.sets?.some(
                                      (set) => set.name === item.name
                                    )
                                      ? current.sets.filter(
                                          (set) => set.name !== item.name
                                        )
                                      : [...current.sets, { name: item.name }];
                                    return { ...current, sets: currentSets };
                                  });

                                  setSelectedQuestionSets((prev) => {
                                    const currentSets = prev?.some(
                                      (set) => set.name === item.name
                                    )
                                      ? prev.filter(
                                          (set) => set.name !== item.name
                                        )
                                      : [...prev, { name: item.name }];
                                    return currentSets;
                                  });
                                }}
                              >
                                <div
                                  id={item?.name}
                                  className={`w-4 h-4 border-2 rounded cursor-pointer ${
                                    selectedQuestionSets?.some(
                                      (set) => set?.name === item?.name
                                    )
                                      ? "bg-secondary border-secondary"
                                      : "border-gray-300"
                                  }`}
                                >
                                  {selectedQuestionSets?.some(
                                    (set) => set?.name === item?.name
                                  ) && (
                                    <div className="w-full h-full bg-secondary flex items-center justify-center">
                                      <svg
                                        className="w-4 h-4 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 13l4 4L19 7"
                                        ></path>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                                <label
                                  htmlFor={item?.name}
                                  className="hover:text-secondary cursor-pointer"
                                >
                                  {item?.name}
                                </label>
                              </div>
                            ))}
                          {questionSets
                            ?.filter((item) => !item?.is_default)
                            ?.map((item) => (
                              <div
                                key={item?.name}
                                className="flex items-center gap-x-3 py-2"
                                onClick={(e) => {
                                  e.preventDefault();

                                  setQuestion((prev) => {
                                    const current = { ...prev };
                                    const currentSets = current?.sets?.some(
                                      (set) => set.name === item.name
                                    )
                                      ? current?.sets.filter(
                                          (set) => set.name !== item.name
                                        )
                                      : [...current.sets, { name: item.name }];
                                    return { ...current, sets: currentSets };
                                  });

                                  setSelectedQuestionSets((prev) => {
                                    const currentSets = prev?.some(
                                      (set) => set.name === item.name
                                    )
                                      ? prev?.filter(
                                          (set) => set.name !== item.name
                                        )
                                      : [...prev, { name: item.name }];
                                    return currentSets;
                                  });
                                }}
                              >
                                <div
                                  id={item?.name}
                                  className={`w-4 h-4 border-2 rounded cursor-pointer ${
                                    selectedQuestionSets?.some(
                                      (set) => set?.name === item?.name
                                    )
                                      ? "bg-secondary border-secondary"
                                      : "border-gray-300"
                                  }`}
                                >
                                  {selectedQuestionSets?.some(
                                    (set) => set?.name === item?.name
                                  ) && (
                                    <div className="w-full h-full bg-secondary flex items-center justify-center">
                                      <svg
                                        className="w-4 h-4 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M5 13l4 4L19 7"
                                        ></path>
                                      </svg>
                                    </div>
                                  )}
                                </div>
                                <label
                                  htmlFor={item?.name}
                                  className="hover:text-secondary cursor-pointer"
                                >
                                  {item?.name}
                                </label>
                              </div>
                            ))}
                        </>
                      }
                    </div>
                  )}
                </div>
                <button
                  className="flex items-center justify-center rounded-[7px] border border-secondary text-white text-base text-center font-medium py-2.5 px-4 bg-[#7190FF]"
                  onClick={handleAddQuestionSet}
                  disabled={selectedQuestionSets?.length > 0}
                >
                  {addQuestionSetLoading ? <LoadingButton /> : "Add"}
                </button>
              </div>
            </div> */}
            <QuestionSetDropdownForModal
              questionSets={questionSets}
              selectedQuestionSets={selectedQuestionSets}
              setSelectedQuestionSets={setSelectedQuestionSets}
              setQuestion={setQuestion}
            />
            <CategoryDropdownForModal
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setQuestion={setQuestion}
              isOpenDeleteCategory={isOpenDeleteCategory}
              setIsOpenDeleteCategory={setIsOpenDeleteCategory}
              setDeleteCategorySlug={setDeleteCategorySlug}
              categories={categories}
              refetchCategory={refetchCategory}
            />
            <KeywordDropdownForModal
              selectedKeyword={selectedKeyword}
              setSelectedKeyword={setSelectedKeyword}
              setQuestion={setQuestion}
              isOpenDeleteKeyword={setIsOpenDeleteKeyword}
              setIsOpenDeleteKeyword={setIsOpenDeleteKeyword}
              setDeleteKeywordSlug={setDeleteKeywordSlug}
              keywords={keywords}
              refetchKeyword={refetchKeyword}
            />
            {/* <div className="space-y-3">
              <p className="text-base lg:text-[20px]">Category</p>
              <div className="flex items-center gap-x-5 w-full">
                <div className="relative max-w-md w-full" ref={categoryRef}>
                  <input
                    autoFocus={false}
                    value={selectedCategory || ""}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    onClick={() => {
                      setOpenSuggestionBox((prev) => !prev);
                      setOpenKeywordSuggestionBox(false);
                    }}
                    autoComplete="off"
                    id="category"
                    placeholder="Select Category or Add New Category"
                    className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6]"
                  />
                  {openSuggestionBox && (
                    <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white z-[60] overflow-y-auto scrollbar-thin shadow-xl border ">
                      <div
                        className="py-2 hover:text-secondary cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setQuestion((prev) => {
                            const current = { ...prev };
                            current.category_link = null;
                            return current;
                          });
                          setSelectedCategory(null);
                          setOpenSuggestionBox(false);
                        }}
                      >
                        - No selected category -
                      </div>
                      {
                        <>
                          {categories?.length > 0 ? (
                            <>
                              {categories
                                ?.filter(
                                  (item) =>
                                    item.category
                                      .toLowerCase()
                                      .indexOf(
                                        selectedCategory
                                          ? selectedCategory.toLowerCase()
                                          : ""
                                      ) > -1
                                )
                                ?.map((item) => (
                                  <div
                                    key={item.id}
                                    className="flex justify-between items-center"
                                  >
                                    {openCategoryEdit ===
                                    item?.category_slug ? (
                                      <div className="flex justify-between items-center mb-3 w-full gap-x-2">
                                        <input
                                          type="text"
                                          placeholder="Edit category"
                                          className="border border-gray-300 px-3 py-2 rounded-lg w-full"
                                          value={editCategory}
                                          onChange={(e) =>
                                            setEditCategory(e.target.value)
                                          }
                                        />
                                        <button
                                          className="flex items-center justify-center rounded-[7px] border border-[#AAA] text-white text-base text-center font-medium p-2.5 bg-[#7190FF] hover:bg-white hover:text-secondary"
                                          onClick={handleUpdateCategory}
                                        >
                                          {editCategoryLoading ? (
                                            <LoadingButton />
                                          ) : (
                                            "Confirm"
                                          )}
                                        </button>
                                      </div>
                                    ) : (
                                      <div
                                        className="py-2 hover:text-secondary cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setQuestion((prev) => {
                                            const current = { ...prev };
                                            current.category_link = item?.id;
                                            return current;
                                          });
                                          setSelectedCategory(item?.category);
                                          setOpenSuggestionBox(false);
                                        }}
                                      >
                                        {item?.category}
                                      </div>
                                    )}
                                    <div className="flex justify-center items-center gap-x-2 text-gray-600">
                                      {openCategoryEdit !==
                                        item?.category_slug && (
                                        <div className="flex gap-x-2">
                                          <Pencil
                                            className="w-4 h-4 cursor-pointer"
                                            onClick={() => {
                                              setOpenCategoryEdit(
                                                item?.category_slug
                                              );
                                              setEditCategory(item?.category);
                                            }}
                                          />
                                          <X
                                            className="w-4 h-4 cursor-pointer text-red-500"
                                            onClick={() => {
                                              setIsOpenDeleteCategory(true);
                                              setDeleteCategorySlug(
                                                item?.category_slug
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </>
                          ) : (
                            <div className="py-5">No result!</div>
                          )}
                        </>
                      }
                    </div>
                  )}
                </div>
                <button
                  className="flex items-center justify-center rounded-[7px] border border-secondary text-white text-base text-center font-medium py-2.5 px-4 bg-[#7190FF]"
                  onClick={handleAddCategory}
                  disabled={addCategoryLoading}
                >
                  {addCategoryLoading ? <LoadingButton /> : "Add"}
                </button>
              </div>
            </div> */}

            {/* <div className="my-5 space-y-3">
              <p className="text-base lg:text-[20px]">Keyword</p>
              <div className="flex items-center gap-x-5 w-full">
                <div className="relative max-w-md w-full" ref={keywordRef}>
                  <input
                    autoFocus={false}
                    value={selectedKeyword || ""}
                    onChange={(e) => setSelectedKeyword(e.target.value)}
                    onClick={() => {
                      setOpenKeywordSuggestionBox((prev) => !prev);
                    }}
                    autoComplete={"off"}
                    id="keyword"
                    placeholder="Select Keyword or Add New Keyword"
                    className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6]"
                  />
                  {openKeywordSuggestionBox && (
                    <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white z-[60] overflow-y-auto scrollbar-thin shadow-xl border ">
                      <div
                        className="py-2 hover:text-secondary cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setQuestion((prev) => {
                            const current = { ...prev };
                            current.keyword_link = null;
                            return current;
                          });
                          setSelectedKeyword(null);
                          setOpenKeywordSuggestionBox(false);
                        }}
                      >
                        {"- No selected keyword -"}
                      </div>
                      {
                        <>
                          {keywords?.length > 0 ? (
                            <>
                              {keywords
                                ?.filter(
                                  (item) =>
                                    item?.keyword
                                      .toLowerCase()
                                      .indexOf(
                                        selectedKeyword
                                          ? selectedKeyword.toLowerCase()
                                          : ""
                                      ) > -1
                                )
                                ?.map((item) => (
                                  <div
                                    key={item?.id}
                                    className="flex justify-between items-center"
                                  >
                                    {openKeywordEdit === item?.keyword_slug ? (
                                      <div className="flex justify-between items-center mb-3 w-full gap-x-2">
                                        <input
                                          type="text"
                                          placeholder="Edit keyword"
                                          className="border border-gray-300 px-3 py-2 rounded-lg w-full"
                                          value={editKeyword}
                                          onChange={(e) =>
                                            setEditKeyword(e.target.value)
                                          }
                                        />
                                        <button
                                          className="flex items-center justify-center rounded-[7px] border border-[#AAA] text-white text-base text-center font-medium p-2.5 bg-[#7190FF]"
                                          onClick={handleUpdateKeyword}
                                        >
                                          {editKeywordLoading ? (
                                            <LoadingButton />
                                          ) : (
                                            "Confirm"
                                          )}
                                        </button>
                                      </div>
                                    ) : (
                                      <div
                                        className="py-2 hover:text-secondary cursor-pointer"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setQuestion((prev) => {
                                            const current = { ...prev };
                                            current.keyword_link = item?.id;
                                            return current;
                                          });
                                          setSelectedKeyword(item.keyword);
                                          setOpenKeywordSuggestionBox(false);
                                        }}
                                      >
                                        {item.keyword}
                                      </div>
                                    )}
                                    <div className="flex justify-center items-center gap-x-2 text-gray-600">
                                      {openKeywordEdit !==
                                        item?.keyword_slug && (
                                        <>
                                          <Pencil
                                            className="w-4 h-4 cursor-pointer"
                                            onClick={() => {
                                              setOpenKeywordEdit(
                                                item?.keyword_slug
                                              );
                                              setEditKeyword(item?.keyword);
                                            }}
                                          />
                                          <X
                                            className="w-4 h-4 cursor-pointer text-red-500"
                                            onClick={() => {
                                              setIsOpenDeleteKeyword(true);
                                              setDeleteKeywordSlug(
                                                item?.keyword_slug
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </>
                          ) : (
                            <div className="py-5">No result!</div>
                          )}
                        </>
                      }
                    </div>
                  )}
                </div>
                <button
                  className="flex items-center justify-center rounded-[7px] border border-secondary text-white text-base text-center font-medium py-2.5 px-4 bg-[#7190FF] "
                  onClick={handleAddKeyword}
                  disabled={addKeywordLoading}
                >
                  {addKeywordLoading ? <LoadingButton /> : "Add"}
                </button>
              </div>
            </div> */}

            {/* <div className="my-5 space-y-3">
              <div className="flex items-center gap-x-1.5">
                <p className="text-base lg:text-[20px]">Question</p>
                <Asterisk className="text-red-500 size-4" />
              </div>

              <textarea
                type="text"
                placeholder="Enter Your Question"
                className="w-full border border-[#C2C2C2] rounded-[9px] p-3 mt-3  font-pro resize-none placeholder:text-[#a6a6a6]"
                rows={3}
                value={question?.question}
                onChange={(e) =>
                  setQuestion((prev) => {
                    let current = { ...prev };
                    current.question = e.target.value;
                    return current;
                  })
                }
              />
            </div> */}

            <ResponseOptionsForModal
              question={question}
              setQuestion={setQuestion}
            />

            {/* <div className="py-2 grid grid-cols-2 lg:flex lg:flex-wrap gap-[19px]">
              {question?.response_options?.map((answer, index) => (
                <div className="relative w-full lg:w-auto" key={index}>
                  <span
                    className={`w-5 h-5 border rounded-full mr-2 bg-white border-[#c2c2c2] absolute top-5 left-3`}
                  ></span>
                  <input
                    value={answer}
                    onChange={(e) => {
                      handleResponseOptionChange(e.target.value, index);
                    }}
                    className={`flex items-center px-10 py-4 border rounded-lg w-full lg:w-auto text-[#4A4A4A]  font-pro text-base border-[#c2c2c2] focus:border-secondary focus:appearance-none focus:ring-1 focus:ring-secondary
                 `}
                  />
                  <CircleX
                    className="w-4 h-4 absolute top-1.5 right-2 cursor-pointer"
                    onClick={() => handleResponseOptionDelete(index)}
                  />
                </div>
              ))}
              <div
                className="text-black/70 flex justify-center items-center cursor-pointer"
                onClick={handleAddOption}
              >
                <CirclePlus
                  className="w-9 h-9 text-[#6e6e6e]"
                  strokeWidth="1.5"
                />
              </div>
            </div>

            <div className="mt-5 mb-5 lg:mb-10 flex items-center gap-5 flex-wrap">
              <input
                type="file"
                hidden
                ref={inputRef}
                onChange={handleFileChange}
                accept=".txt"
              />
              <Tooltip
                content="Only accepts .txt file"
                theme={{
                  target: "w-fit",
                  arrow: {
                    base: "absolute z-10 h-2 w-2 rotate-45",
                    style: {
                      dark: "bg-gray-900 dark:bg-gray-700",
                      light: "bg-white",
                      auto: "bg-white dark:bg-gray-700",
                    },
                    placement: "-4px",
                  },
                }}
              >
                <button
                  className="flex items-center justify-center rounded-[7px] border border-secondary text-white text-center py-3 px-3 bg-[#7190FF] hover:bg-white hover:text-secondary transition-all duration-200"
                  onClick={() => inputRef?.current?.click()}
                >
                  Import bulk responses
                </button>
              </Tooltip>
              <p className="text-[#888]">(Line seperated responses)</p>
            </div>

            <div className="flex items-center gap-x-3 my-5">
              <p>Show response options as:</p>
              <div className="flex items-center gap-x-3">
                <div className="flex items-center gap-x-3 ">
                  <input
                    type="radio"
                    name="show-as"
                    id="chips"
                    checked={question?.show_as === "CHIPS"}
                    onClick={() =>
                      setQuestion((prev) => {
                        let current = { ...prev };
                        current.show_as = "CHIPS";
                        return current;
                      })
                    }
                    readOnly
                    className="cursor-pointer"
                  />
                  <label htmlFor="chips" className="cursor-pointer">
                    Chips
                  </label>
                </div>
                <div className="flex items-center gap-x-3">
                  <input
                    type="radio"
                    name="show-as"
                    id="dropdown"
                    checked={question?.show_as === "DROPDOWN"}
                    onClick={() =>
                      setQuestion((prev) => {
                        let current = { ...prev };
                        current.show_as = "DROPDOWN";
                        return current;
                      })
                    }
                    readOnly
                    className="cursor-pointer"
                  />
                  <label htmlFor="dropdown" className="cursor-pointer">
                    Dropdown
                  </label>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-x-3 my-5">
              <label htmlFor="responses">Multiple Responses:</label>
              <Switch
                checked={question?.has_multiple_responses}
                onChange={() =>
                  setQuestion((prev) => {
                    let current = { ...prev };
                    if (current.has_multiple_responses) {
                      current.has_multiple_responses = false;
                    } else {
                      current.has_multiple_responses = true;
                    }
                    return current;
                  })
                }
                className={`${question?.has_multiple_responses ? "bg-[#3183FF]" : "bg-[#8E8B8B]"}
                relative h-6 w-12  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
              >
                <span className="sr-only">Toggle Multiple Responses</span>
                <span
                  aria-hidden="true"
                  className={`${question?.has_multiple_responses ? "translate-x-6" : "translate-x-0"}
                  pointer-events-none inline-block h-5 w-5  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
            </div> */}

            <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end">
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-newPrimary text-base text-center font-medium py-[10px]  px-[40px] bg-white dark:bg-darkLightBg gap-[6px] mt-[20px] w-[49.5%] lg:w-auto"
                onClick={() => {
                  setOpenEditModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-[10px]  px-[40px] bg-newPrimary hover:bg-white hover:text-newPrimary gap-[6px] mt-[20px] w-[49.5%] lg:w-auto transition-all duration-200 dark:hover:bg-darkLightBg"
                onClick={() => {
                  handlePush();
                }}
                disabled={isLoading || selectedQuestionSets?.length === 0}
              >
                {/* {isLoading ? <LoadingButton /> : "Save"}
                 */}
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteModal
        openModal={isOpenDeleteCategory}
        setOpenModal={setIsOpenDeleteCategory}
        title={`Are you sure want to delete the category "${categories?.find((item) => item?.category_slug === deleteCategorySlug)?.category}"`}
        handleDelete={handleDeleteCategory}
        isLoading={deleteCategoryLoading}
      />
      <DeleteModal
        openModal={isOpenDeleteKeyword}
        setOpenModal={setIsOpenDeleteKeyword}
        title={`Are you sure want to delete the keyword "${keywords?.find((item) => item?.keyword_slug === deleteKeywordSlug)?.keyword}"`}
        handleDelete={handleDeleteKeyword}
        isLoading={deleteKeywordLoading}
      />
    </>
  );
};

export default EditQuestionModal;
