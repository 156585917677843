import Apimanager from "./axios";

export const signInApi = async (data) => {
  const res = await Apimanager.post("/accounts/login", {
    email: data.email,
    password: data.password,
  });
  return res.data;
};

export const signUpApi = async (data) => {
  const res = await Apimanager.post("/accounts/register", data);
  return res.data;
};

export const verifyEmailApi = async (data) => {
  const res = await Apimanager.post(`/accounts/${data?.id}/verify_email`, {
    code: data?.code,
  });
  return res.data;
};

export const getUserApi = async () => {
  const res = await Apimanager.get("/accounts/");

  return res.data;
};

export const updateUserApi = async (data) => {
  const res = await Apimanager.put("/accounts/update", data);
  return res.data;
};

export const updateUserProfileApi = async (data) => {
  const res = await Apimanager.put("/accounts/update/pfp", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const createResetPasswordSession = async (data) => {
  const res = await Apimanager.post("/accounts/send_code", data);
  return res.data;
};

export const verifyCode = async (data) => {
  const res = await Apimanager.post("/accounts/verify_code", data);
  return res.data;
};

export const postResetPassword = async (data) => {
  const res = await Apimanager.post("/accounts/reset_password", data);
  return res.data;
};

export const getReferralsApi = async (params) => {
  const res = await Apimanager.get("/accounts/referrals", {
    params: params,
  });
  return res.data;
};

export const getIndustryTypes = async (params) => {
  const res = await Apimanager.get("/misc/industries", {
    params: params,
  });
  return res.data;
};

export const getSettings = async () => {
  const res = await Apimanager.get("/entity/integrations/google");
  return res.data;
};

export const putUpdateSettings = async (data) => {
  const res = await Apimanager.put("/entity/integrations/google", data);
  return res.data;
};

export const postEnableSupport = async () => {
  const res = await Apimanager.post("/entity/integrations/google/enable");
  return res.data;
};

export const postToggle = async () => {
  const res = await Apimanager.post("/entity/integrations/google/toggle");
  return res.data;
};

export const getNotifications = async () => {
  const res = await Apimanager.get("/accounts/notifications");
  return res.data;
};

export const postUpdateReferralCode = async (data) => {
  const res = await Apimanager.post("/accounts/referrals/update_code", data);
  return res.data;
};

export const postToggleStaffManagement = async () => {
  const res = await Apimanager.post(
    "/accounts/settings/toggle_staff_management"
  );
  return res.data;
};
