/* eslint-disable react/prop-types */
import { useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetEntityReviews } from "../../../query/useReview";
import { dayFormatter } from "../../../utils/formatter";
import style from "../component/slider.module.scss";
import Skeleton from "react-loading-skeleton";
import { ArrowLeft, ArrowRight, Bot, Check, Link } from "lucide-react";
import Google from "../../../assets/reviewservices/google.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import ReviewCard from "../routes/components/ReviewCard";

const Slider = ({ isMainLoading }) => {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleGoToMagiclink = (link) => {
    window.open(link);
  };

  const entityId =
    localStorage.getItem("entityUuid") && localStorage.getItem("entityUuid");

  const { data: reviews, isLoading: isReviewsLoading } = useGetEntityReviews({
    entityId: entityId,
    limit: 5,
  });

  const flattenedReviews = reviews?.pages?.flatMap((page) => page?.results);

  if (isMainLoading) {
    return <Skeleton containerClassName="flex-1 lg:mt-20 mt-5" height={300} />;
  }

  return (
    <div className="pb-3 3xl:pb-0 min-h-full">
      <Swiper
        ref={swiperRef}
        className={`mt-5 review ${style.slider}`}
        spaceBetween={16}
        slidesPerView={1}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>'; // Only the dots are rendered
          },
        }}
        modules={[Pagination]}
      >
        {flattenedReviews?.length > 0 &&
          flattenedReviews?.map((data, id) => (
            <SwiperSlide
              className={` flex flex-col mb-16 transition-all duration-300`}
              key={id}
            >
              <ReviewCard review={data} key={id} />
              {/* <div
                className="flex flex-col rounded-[12px] p-3 gap-y-5"
                key={data?.uuid}
              >
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-[35px]">
                    <div className="flex gap-4 items-center">
                      <img
                        src={data?.profile_photo_url || "/new-logo.svg"}
                        alt=""
                        className="w-[50px] h-[50px]"
                        referrerPolicy="no-referrer"
                      />
                      <div>
                        <h1 className="font-pro text-[20px] mb-1">
                          {data?.author_name || "MagicReview"}
                        </h1>
                        <p className="text-[#777] text-[11px] font-pro ">
                          {data?.time && dayFormatter(data?.time)}
                        </p>
                      </div>
                    </div>
                  </div>
                  {data?.is_posted_ai_review && (
                    <div className="p-2 rounded-full bg-secondary text-white">
                      <Check />
                    </div>
                  )}
                </div>

                <p className="text-[#777] dark:text-white text-base font-pro mt-[10px]">
                  {data?.text}
                </p>

                <div className="flex items-center gap-x-2 mt-2">
                  {data?.source === "G" && (
                    <div className="p-2 rounded-full bg-[#F6F6FF]">
                      <img src={Google} className="w-6 h-6" />
                    </div>
                  )}
                  {data?.source === "T" && (
                    <div className="p-2 rounded-full bg-[#F6F6FF]">
                      <img src={TrustPilot} className="w-6 h-6" />
                    </div>
                  )}
                  {data?.source === "Y" && (
                    <div className="p-2 rounded-full bg-[#F6F6FF]">
                      <img src={Yelp} className="w-6 h-6" />
                    </div>
                  )}
                  {data?.source === "B" && (
                    <div className="p-2 rounded-full bg-[#F6F6FF]">
                      <img src={Booking} className="w-6 h-6" />
                    </div>
                  )}
                  {data?.source === "TA" && (
                    <div className="p-2 rounded-full bg-[#F6F6FF]">
                      <img src={TripAdvisor} className="w-6 h-6" />
                    </div>
                  )}
                </div>

                <div className="flex items-center gap-x-2 mt-2">
                  {data?.staff_member_name && (
                    <div className="p-2 rounded-md text-[#615E83] bg-[#F6F6FF]">
                      {data?.staff_member_name}
                    </div>
                  )}
                  {data?.staff_member_code && (
                    <div className="p-2 rounded-md text-[#615E83] bg-[#F6F6FF]">
                      {data?.staff_member_code}
                    </div>
                  )}
                  <div
                    className="rounded-md text-white p-2 bg-secondary cursor-pointer"
                    onClick={() => handleGoToMagiclink(data?.magic_link)}
                  >
                    <Link className="w-5 h-5" />
                  </div>
                </div>
              </div> */}
            </SwiperSlide>
          ))}
      </Swiper>

      {flattenedReviews?.length > 0 ? (
        <div className="flex justify-between relative -mt-[50px] z-30">
          <div
            className="prev cursor-pointer border border-[#E4DDDD] dark:border-none dark:bg-darkLightBg w-fit p-4 rounded-[12px]"
            onClick={handlePrev}
          >
            <ArrowLeft className="text-black/45 dark:text-[#888]" />
          </div>
          <div
            className="next cursor-pointer border border-[#E4DDDD] dark:border-none dark:bg-darkLightBg w-fit p-4 rounded-[12px]"
            onClick={handleNext}
          >
            <ArrowRight className="text-black/45 dark:text-[#888]" />
          </div>
        </div>
      ) : (
        !isReviewsLoading && (
          <div className="w-full min-h-[400px] flex flex-col justify-center items-center">
            <img src="/no-post.svg" alt="" />
            <p className="font-pro text-[#888] text-sm">
              No Reviews Posted Yet!
            </p>
          </div>
        )
      )}
    </div>
  );
};

export default Slider;
