import {
  ArrowLeft,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronLeftCircle,
  ChevronRight,
  ChevronRightCircle,
  Info,
  Link,
  Pencil,
  QrCode,
  Recycle,
  RotateCcw,
  SquarePlus,
  Trash2,
  X,
} from "lucide-react";
import Layout from "../component/Layout";
import { useEffect, useRef, useState } from "react";
import AddStaffModal from "./components/AddStaffModal";
import { toast } from "sonner";
import { Modal, Table } from "flowbite-react";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";
import EditStaffModal from "./components/EditStaffModal";
import {
  useClickGetMagicQr,
  useDeleteEmployee,
  useGetActiveDefaultMagicLink,
  useGetDefaultQuestionSets,
  useGetEmployeesList,
  useGetMagicLink,
  usePostAddDefaultQuestionSets,
  usePostDefaultMagicLink,
  usePostEmployeeEnable,
  usePostResetMagicLink,
} from "../../../query/useEmployees";
import { DeleteModal } from "../component/modal";
import { Switch } from "@headlessui/react";
import {
  usePostToggleStaffManagement,
  useUser,
} from "../../../query/useAuthentication";
import { useQueryClient } from "@tanstack/react-query";
import { postDefaultMagicLink } from "../../../api/employeesApi";
import { useGetQuestionSets } from "../../../query/useEntity";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import QrContainer from "../component/QrContainer";
import IconButton from "../component/IconButton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "../component/CustomTooltipForJoyride";
import LinkModal from "./components/LinkModal";
import { LogoLoading } from "../../../components/loading/loading";

const Staffs = () => {
  const scrollRef = useRef(null);
  const inputRef = useRef(null);
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const questionSetRef = useRef(null);
  const queryClient = useQueryClient();
  const { darkMode } = useDarkMode();
  const [index, setIndex] = useState(0);
  const [isOpenAddStaffModal, setIsOpenAddStaffModal] = useState(false);
  const [isOpenEditStaffModal, setIsOpenEditStaffModal] = useState();
  const [toUpdateData, setToUpdateData] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isActiveStaffManagement, setIsActiveStaffManagement] = useState(false);
  const [file, setFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [slicedNumber, setSlicedNumber] = useState(0);
  const [employeeId, setEmployeeId] = useState();
  const [isOpenQuestionSetBox, setIsOpenQuestionSetBox] = useState(false);
  const [selectedDefaultQuestionSet, setSelectedDefaultQuestionSet] =
    useState();
  const [originalDefaultQuestionSet, setOriginalDefaultQuestionSet] =
    useState();
  const [isEditable, setIsEditable] = useState(false);
  const [seeMoreLabel, setSeeMoreLabel] = useState();
  const [seeMoreTags, setSeeMoreTags] = useState();
  const [loadingMagicLink, setLoadingMagicLink] = useState();
  const [isOpenQrContainer, setIsOpenQrContainer] = useState(false);
  const [qr, setQr] = useState();
  const [selectedSet, setSelectedSet] = useState(null);
  const [isOpenLink, setIsOpenLink] = useState(null);

  const joyRideStepsForMagiclink = [
    {
      title: "Default Magiclink",
      content:
        "Select a question set to access its MagicLink via the copy icon in the top right corner.",
      target: ".select-set",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Enable Magiclink",
      content:
        "Turn on the toggle to add magiclinks. Also, activate/ deactivate magiclinks.",
      target: ".enable-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Add Magiclink",
      content:
        "Click on “Add Magiclink” button to start adding magiclinks for your team.",
      target: ".add-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Reset",
      content:
        "Reset the MagicLink to the default question set, accessible via the copy icon in the top right corner.",
      target: ".reset-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Question Set",
      content: "View MagicLinks organized by their assigned question sets.",
      target: ".set",
      spotlightClicks: true,
    },
    {
      title: "Set Default",
      content:
        "This sets the MagicLink as default for quick access via the copy icon in the top right corner.",
      target: ".set-default",
      spotlightClicks: true,
    },
    {
      title: "Edit Magiclinks",
      content:
        "Click to edit the MagicLink's name, role, tags, question set, and more.",
      target: ".edit-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Activate",
      content:
        "Toggle to display the name and role on the MagicLink's review page.",
      target: ".activate-magiclink",
      spotlightClicks: true,
    },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForMagiclink,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "verify-email") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });

    setCsvArray(newArray);
  };

  const baseUrl = window.location.origin;
  const userInfo =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const {
    data,
    isLoading: employeeListLoading,
    refetch,
  } = useGetEmployeesList();

  const { data: active, refetch: refetchActive } =
    useGetActiveDefaultMagicLink();

  const { mutate: postToggleStaffManagement, isLoading: isToggleLoading } =
    usePostToggleStaffManagement();

  const { mutate: deleteEmployee, isLoading: deleteEmployeeLoading } =
    useDeleteEmployee();

  const { mutate: toggleEmployeeEnable, isLoading: isToggleEmployeeLoading } =
    usePostEmployeeEnable();

  const { mutate: resetMagicLink, isLoading: isResetMagicLinkLoading } =
    usePostResetMagicLink();

  const { refetch: refetchUser } = useUser({
    onSuccess: (res) => {
      setIsActiveStaffManagement(
        res.profile.user_settings_staff_management_enabled
      );
    },
  });

  const { refetch: refetchDefaultQuestionSet } = useGetDefaultQuestionSets({
    onSuccess: (res) => {
      setSelectedDefaultQuestionSet(res?.data);
      setOriginalDefaultQuestionSet(res?.data);
    },
  });

  const {
    mutate: addDefaultQuestionSets,
    isLoading: addDefaultQuestionSetsLoading,
  } = usePostAddDefaultQuestionSets();

  const { data: magicLink } = useGetMagicLink();
  const { mutate: defaultMagicLink, isLoading: defaultMagicLinkLoading } =
    usePostDefaultMagicLink();
  const { data: questionSets } = useGetQuestionSets();

  const { mutate: getMagicQr, isLoading: getMagicQrLoading } =
    useClickGetMagicQr();

  const handleCopy = (employeeCode) => {
    if (userInfo?.entity?.magic_link) {
      navigator.clipboard.writeText(
        baseUrl +
          "/" +
          userInfo?.entity?.magic_link?.split("?")?.[0] +
          `?employee=${employeeCode}`
      );
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const handleGetQr = (employeeCode) => {
    getMagicQr(employeeCode, {
      onSuccess: (res) => {
        setQr(res);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    });
    setIsOpenQrContainer(true);
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const handleDeleteEmployee = () => {
    deleteEmployee(deleteId, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        } else {
          toast.success("Link has been deleted successfully");
        }
        refetch();
        setIsOpenDeleteModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text); // plugged in here
      };

      reader.readAsText(file);
      setIsOpenPreviewModal(true);
    }
    e.target.value = "";
  };

  const toggle = () => {
    postToggleStaffManagement(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchUser();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleEmployee = (employeeId) => {
    setEmployeeId(employeeId);
    toggleEmployeeEnable(employeeId, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetch();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleResetMagicLink = () => {
    resetMagicLink(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries("getMagicLink");
          queryClient.invalidateQueries("getMagicQr");
          refetchActive();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDefaultMagicLink = (id) => {
    setLoadingMagicLink(id);
    defaultMagicLink(id, {
      onSuccess: (res) => {
        toast.success(res.message);
        queryClient.invalidateQueries("getMagicLink");
        queryClient.invalidateQueries("getMagicQr");
        refetchActive();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddDefaultQuestionSet = (set) => {
    addDefaultQuestionSets(
      {
        set: set,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchDefaultQuestionSet();
          // setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useOnClickOutside({
    ref: questionSetRef,
    setIsOpen: setIsOpenQuestionSetBox,
  });

  const defaultSet = questionSets?.find((item) => item?.is_default);
  useEffect(() => {
    setSelectedSet(defaultSet?.id);
  }, [defaultSet]);

  const scrollByAmount = (amount) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: amount, behavior: "smooth" });
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (scrollRef.current) {
      setIsOverflowing(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [questionSets]);

  if (
    defaultMagicLinkLoading ||
    isResetMagicLinkLoading ||
    isToggleLoading ||
    isToggleEmployeeLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Magiclinks"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] p-5 sm:p-[31px] min-h-[calc(100vh-120px)]">
        <div className="flex flex-wrap items-center justify-between gap-5 mb-5">
          <div className="flex flex-wrap items-center gap-5">
            {magicLink?.flag === 1 ? (
              <div className="w-4 h-4 rounded-full flex-shrink-0 bg-[#73df07]"></div>
            ) : (
              <div className="w-4 h-4 rounded-full flex-shrink-0 bg-red-500"></div>
            )}
            <p className="text-xl font-medium">Default MagicLink</p>
            <div
              className="lg:w-[200px] w-full relative text-[#615E83] dark:text-white h-10 select-set"
              ref={questionSetRef}
            >
              <button
                className="px-6 h-full rounded-full focus:outline-none flex items-center justify-between w-full bg-white dark:bg-darkBg cursor-pointer border border-white dark:border-darkBg"
                onClick={() => setIsOpenQuestionSetBox((prev) => !prev)}
                // disabled={!isEditable}
              >
                <p className="">
                  {selectedDefaultQuestionSet
                    ? questionSets?.find(
                        (item) => item?.id === selectedDefaultQuestionSet
                      )?.name
                    : "Default"}
                </p>

                {addDefaultQuestionSetsLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-t-2 border-b-2 border-[#2a49b8] rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <ChevronDown
                    className={`w-5 h-5 ${isOpenQuestionSetBox && "rotate-180"} transition-all duration-200`}
                  />
                )}
              </button>
              {isOpenQuestionSetBox && (
                <div className="w-full p-5 rounded-[7px] absolute top-12 left-0 bg-white dark:bg-darkBg dark:border-none border space-y-5 z-[60] text-left lg:h-[200px] overflow-y-auto scrollbar-thin shadow-md ">
                  {questionSets
                    ?.filter?.((item) => item?.is_default)
                    .map((item) => (
                      <div
                        key={item?.id}
                        className="flex items-center justify-between group"
                      >
                        <p
                          className="w-full cursor-pointer group-hover:text-secondary"
                          onClick={() => {
                            setIsOpenQuestionSetBox(false);
                            handleAddDefaultQuestionSet(item?.id);
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  {questionSets
                    ?.filter?.((item) => !item?.is_default)
                    .map((item) => (
                      <div
                        key={item?.id}
                        className="flex items-center justify-between group"
                      >
                        <p
                          className="w-full cursor-pointer group-hover:text-secondary"
                          onClick={() => {
                            setIsOpenQuestionSetBox(false);
                            handleAddDefaultQuestionSet(item?.id);
                          }}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                </div>
              )}
            </div>

            <div className="flex items-center gap-3 flex-wrap">
              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-full border border-newPrimary bg-newPrimary text-white dark:hover:bg-darkLightBg hover:bg-white hover:text-newPrimary transition-all duration-200 w-[150px] font-pro add-magiclink"
                onClick={() => {
                  setIsOpenAddStaffModal(true);
                  setToUpdateData();
                }}
                disabled={!isActiveStaffManagement}
              >
                <p>Add Magiclink</p>
              </button>
              <button
                className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-full border border-newPrimary bg-white text-newPrimary dark:bg-darkLightBg hover:bg-newPrimary hover:text-white transition-all duration-200  font-pro reset-magiclink"
                onClick={handleResetMagicLink}
                disabled={isResetMagicLinkLoading}
              >
                {/* {isResetMagicLinkLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : ( */}
                <>
                  <p>Reset to default</p>
                </>
                {/* )} */}
              </button>
            </div>
          </div>
          {/* <input
            type="file"
            hidden
            accept=".csv"
            ref={inputRef}
            onChange={handleFileChange}
          /> */}
          {/* <button
            className="w-fit flex items-center gap-x-3 px-5 py-3 rounded-xl border border-secondary bg-secondary text-white hover:bg-white hover:text-secondary transition-all duration-200"
            onClick={() => {
              inputRef?.current.click();
            }}
          >
            <p>Import from file</p>
            <Import />
          </button> */}
          <div className="w-fit place-content-end">
            <div className="flex items-center gap-x-3 enable-magiclink">
              <p className={`font-pro`}>Enable MagicLink</p>

              <Switch
                disabled={isToggleLoading}
                checked={isActiveStaffManagement}
                onChange={toggle}
                className={`${isActiveStaffManagement ? "bg-[#00b67a]" : "bg-[#8E8B8B]"}
          relative h-[30px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
              >
                <span className="sr-only">Active</span>
                <span
                  aria-hidden="true"
                  className={`${isActiveStaffManagement ? "translate-x-8" : "translate-x-0"} pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              {/* {isToggleLoading && (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                </div>
              )} */}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-3 justify-center mt-5">
          {isOverflowing && (
            <button
              onClick={() => scrollByAmount(-100)}
              className="hidden lg:block"
            >
              <ChevronLeftCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
            </button>
          )}
          <div
            className="flex items-center w-full gap-3  bg-white dark:bg-darkBg px-5 py-2 rounded-full overflow-x-auto scrollbar-none relative set"
            ref={scrollRef}
          >
            {questionSets
              ?.filter((item) => item?.is_default)
              .map((item, i) => (
                <>
                  <button
                    className={`px-3 py-2 rounded-full flex-shrink-0 ${selectedSet === item?.id ? "bg-newPrimary text-white" : ""}`}
                    onClick={() => setSelectedSet(item?.id)}
                    key={item?.id}
                  >
                    {item?.name}
                  </button>
                  {questionSets?.length !== i + 1 && (
                    <div className="border-l-2 h-7"></div>
                  )}
                </>
              ))}
            {questionSets
              ?.filter((item) => !item?.is_default)
              .map((item, i) => (
                <>
                  <button
                    className={`px-3 py-2 rounded-[7px] flex-shrink-0 ${selectedSet === item?.id ? "bg-secondary text-white" : ""}`}
                    onClick={() => setSelectedSet(item?.id)}
                    key={item?.id}
                  >
                    {item?.name}
                  </button>
                  {questionSets?.length !== i + 2 && (
                    <div className="border-l-2 h-7"></div>
                  )}
                </>
              ))}
          </div>
          {isOverflowing && (
            <button
              onClick={() => scrollByAmount(100)}
              className="hidden lg:block"
            >
              <ChevronRightCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
            </button>
          )}
        </div>

        <div className="overflow-x-auto mt-7 scrollbar-thin">
          {data?.filter((item) => item?.default_set === selectedSet)?.length <=
          0 ? (
            <div className="w-full min-h-[38vh] flex flex-col justify-center items-center space-y-3">
              <img src="/empty/no-magiclink.svg" alt="" />
              <p className="font-pro text-[#888] text-sm">
                Your magiclinks will appear here!
              </p>
            </div>
          ) : (
            <Table
              theme={{
                root: {
                  base: "w-full text-left text-sm lg:text-base ",
                  shadow:
                    "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
                },
                head: {
                  base: "group/head font-pro dark:text-darkGray",
                  cell: {
                    base: "bg-white dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
                  },
                },
                body: {
                  base: "group/body",
                  cell: {
                    base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
                  },
                },
              }}
            >
              <Table.Head className="">
                <Table.HeadCell className="font-medium">
                  Set Default
                </Table.HeadCell>
                <Table.HeadCell className="font-medium">Name</Table.HeadCell>
                <Table.HeadCell className="font-medium">Role</Table.HeadCell>
                <Table.HeadCell className="font-medium">Tags</Table.HeadCell>
                <Table.HeadCell className="font-medium">
                  Question Set
                </Table.HeadCell>
                <Table.HeadCell className="font-medium ">
                  <div className="flex items-center gap-1">
                    Reviews{" "}
                    <div title="No. of redirected reviews">
                      <Info className="text-[#888] size-4" />
                    </div>
                  </div>
                </Table.HeadCell>
                <Table.HeadCell className="font-medium">Link</Table.HeadCell>
                <Table.HeadCell className="font-medium">
                  Activate
                </Table.HeadCell>
                {isActiveStaffManagement && <Table.HeadCell></Table.HeadCell>}
              </Table.Head>
              <Table.Body className="divide-y">
                {employeeListLoading ? (
                  <Table.Row>
                    {new Array(8).fill("").map((_, i) => (
                      <Table.Cell key={i} className="bg-white dark:bg-darkBg">
                        <Skeleton
                          height={30}
                          baseColor={darkMode && "#262626"}
                        />
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ) : data?.length > 0 ? (
                  <>
                    {data
                      ?.filter((item) => item?.is_enable)
                      ?.filter((item) => item?.default_set === selectedSet)
                      ?.map((item, i) => (
                        <>
                          <Table.Row
                            className="bg-white dark:bg-darkBg"
                            key={item?.id}
                          >
                            <Table.Cell>
                              <div
                                className={`flex items-center gap-x-2 ${i === 0 && "set-default"}`}
                              >
                                <input
                                  type="radio"
                                  name="default"
                                  className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"}`}
                                  id={item?.id}
                                  onChange={() =>
                                    handleDefaultMagicLink(item?.id)
                                  }
                                  checked={item?.id === active?.employee_id}
                                />
                                <p>{item?.employee_code}</p>
                              </div>
                            </Table.Cell>

                            <Table.Cell className="">
                              <p>{item?.name}</p>
                            </Table.Cell>
                            <Table.Cell>
                              <div className="max-w-[120px]">
                                {item?.label?.length > 14 ? (
                                  <>
                                    {seeMoreLabel === item?.id ? (
                                      <div>
                                        <p className="break-words">
                                          {item?.label}
                                        </p>
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() => setSeeMoreLabel()}
                                        >
                                          See Less
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="line-clamp-1">
                                          {item?.label?.slice(0, 14)}...
                                        </p>
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() =>
                                            setSeeMoreLabel(item?.id)
                                          }
                                        >
                                          See More
                                        </p>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <p className="break-words">{item?.label}</p>
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div className="flex items-center gap-1 w-[150px] flex-wrap">
                                {item?.tags?.length > 3 ? (
                                  <>
                                    {seeMoreTags === item?.id ? (
                                      <>
                                        {item?.tags?.map((item) => (
                                          <div
                                            key={item}
                                            className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                          >
                                            <p>{item}</p>
                                          </div>
                                        ))}
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() => setSeeMoreTags()}
                                        >
                                          See Less
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {item?.tags
                                          ?.slice(0, 3)
                                          ?.map((item) => (
                                            <div
                                              key={item}
                                              className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                            >
                                              <p>{item}</p>
                                            </div>
                                          ))}
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() =>
                                            setSeeMoreTags(item?.id)
                                          }
                                        >
                                          See More
                                        </p>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item?.tags?.map((item) => (
                                      <div
                                        key={item}
                                        className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                      >
                                        <p>{item}</p>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              {item?.default_set
                                ? questionSets?.find(
                                    (set) => set?.id === item?.default_set
                                  )?.name
                                : "Default"}
                            </Table.Cell>
                            <Table.Cell>
                              <p>{item?.reviews_count || 0}</p>
                            </Table.Cell>

                            <Table.Cell>
                              <div className="flex items-center">
                                {/* <p className="w-24">{item?.employee_code}</p> */}
                                <IconButton>
                                  <Link
                                    className="w-5 h-5 cursor-pointer text-[#888]"
                                    onClick={() =>
                                      // handleCopy(item?.employee_code)
                                      setIsOpenLink(item?.id)
                                    }
                                  />
                                </IconButton>
                                <IconButton>
                                  <QrCode
                                    className="w-5 h-5 cursor-pointer text-[#888]"
                                    onClick={() =>
                                      handleGetQr(item?.employee_code)
                                    }
                                  />
                                </IconButton>
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div
                                className={`flex items-center gap-x-3 ${i === 0 && "activate-magiclink"}`}
                              >
                                <Switch
                                  disabled={
                                    isToggleEmployeeLoading ||
                                    !isActiveStaffManagement
                                  }
                                  checked={item?.is_enable}
                                  onChange={() => toggleEmployee(item?.id)}
                                  className={`${item?.is_enable ? "bg-[#00b67a]" : "bg-[#8E8B8B]"} relative h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                                >
                                  <span className="sr-only">Active</span>
                                  <span
                                    aria-hidden="true"
                                    className={`${item?.is_enable ? "translate-x-6" : "translate-x-0"} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                  />
                                </Switch>
                                {isToggleEmployeeLoading &&
                                  item?.id === employeeId && (
                                    <div className="flex items-center justify-center">
                                      <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                    </div>
                                  )}
                              </div>
                            </Table.Cell>
                            {isActiveStaffManagement && (
                              <Table.Cell>
                                <div className="flex justify-end items-center text-[#888]">
                                  <IconButton>
                                    <Pencil
                                      className={`w-5 h-5 cursor-pointer ${i === 0 && "edit-magiclink"}`}
                                      onClick={() => {
                                        setIsOpenEditStaffModal(item?.id);
                                        // setToUpdateData(item);
                                      }}
                                    />
                                  </IconButton>

                                  {item?.id !== active?.employee_id && (
                                    <IconButton>
                                      <Trash2
                                        className="w-5 h-5 cursor-pointer text-red-500"
                                        onClick={() => {
                                          setIsOpenDeleteModal(true);
                                          setDeleteId(item?.id);
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </div>
                              </Table.Cell>
                            )}
                          </Table.Row>
                          {item?.id === isOpenEditStaffModal && (
                            <EditStaffModal
                              openModal={item?.id === isOpenEditStaffModal}
                              setOpenModal={setIsOpenEditStaffModal}
                              refetch={refetch}
                              entityId={userInfo?.entity?.id}
                              key={item?.id}
                              data={item}
                              // setData={setToUpdateData}
                            />
                          )}
                          {item?.id === isOpenLink && (
                            <LinkModal
                              openModal={item?.id === isOpenLink}
                              setOpenModal={setIsOpenLink}
                              link={
                                baseUrl +
                                "/" +
                                userInfo?.entity?.magic_link?.split("?")?.[0] +
                                `?employee=${item?.employee_code}`
                              }
                            />
                          )}
                        </>
                      ))}
                    {data
                      ?.filter((item) => !item?.is_enable)
                      ?.filter((item) => item?.default_set === selectedSet)
                      ?.map((item) => (
                        <>
                          <Table.Row
                            className="bg-white text-[#777] dark:bg-darkBg"
                            key={item?.id}
                          >
                            <Table.Cell>
                              <div className="flex items-center gap-x-2">
                                <input
                                  type="radio"
                                  name="default"
                                  className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"}`}
                                  id={item?.id}
                                  onChange={() =>
                                    handleDefaultMagicLink(item?.id)
                                  }
                                  checked={item?.id === active?.employee_id}
                                />
                                <p>{item?.employee_code}</p>
                              </div>
                            </Table.Cell>
                            <Table.Cell className="">
                              <p>{item?.name}</p>
                            </Table.Cell>

                            <Table.Cell>
                              <div className="max-w-[120px]">
                                {item?.label?.length > 14 ? (
                                  <>
                                    {seeMoreLabel === item?.id ? (
                                      <div>
                                        <p className="break-words">
                                          {item?.label}
                                        </p>
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() => setSeeMoreLabel()}
                                        >
                                          See Less
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="line-clamp-1">
                                          {item?.label?.slice(0, 14)}...
                                        </p>
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() =>
                                            setSeeMoreLabel(item?.id)
                                          }
                                        >
                                          See More
                                        </p>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <p className="break-words">{item?.label}</p>
                                )}
                              </div>
                            </Table.Cell>

                            <Table.Cell>
                              <div className="flex items-center gap-1 w-[150px] flex-wrap">
                                {item?.tags?.length > 3 ? (
                                  <>
                                    {seeMoreTags === item?.id ? (
                                      <>
                                        {item?.tags?.map((item) => (
                                          <div
                                            key={item}
                                            className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                          >
                                            <p>{item}</p>
                                          </div>
                                        ))}
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() => setSeeMoreTags()}
                                        >
                                          See Less
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {item?.tags
                                          ?.slice(0, 3)
                                          ?.map((item) => (
                                            <div
                                              key={item}
                                              className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                            >
                                              <p>{item}</p>
                                            </div>
                                          ))}
                                        <p
                                          className="text-secondary cursor-pointer hover:underline text-sm"
                                          onClick={() =>
                                            setSeeMoreTags(item?.id)
                                          }
                                        >
                                          See More
                                        </p>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item?.tags?.map((item) => (
                                      <div
                                        key={item}
                                        className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                      >
                                        <p>{item}</p>
                                      </div>
                                    ))}
                                  </>
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              {item?.default_set
                                ? questionSets?.find(
                                    (set) => set?.id === item?.default_set
                                  )?.name
                                : "Default"}
                            </Table.Cell>
                            <Table.Cell>
                              <p>{item?.reviews_count || 0}</p>
                            </Table.Cell>

                            <Table.Cell>
                              <div className="flex items-center">
                                {/* <p className="w-24">{item?.employee_code}</p> */}
                                <IconButton>
                                  <Link
                                    className="w-5 h-5 cursor-pointer text-[#888]"
                                    onClick={() =>
                                      // handleCopy(item?.employee_code)
                                      setIsOpenLink(item?.id)
                                    }
                                  />
                                </IconButton>
                                <IconButton>
                                  <QrCode
                                    className="w-5 h-5 cursor-pointer text-[#888]"
                                    onClick={() =>
                                      handleGetQr(item?.employee_code)
                                    }
                                  />
                                </IconButton>
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div className="flex items-center gap-x-3">
                                <Switch
                                  disabled={
                                    isToggleEmployeeLoading ||
                                    !isActiveStaffManagement
                                  }
                                  checked={item?.is_enable}
                                  onChange={() => toggleEmployee(item?.id)}
                                  className={`${item?.is_enable ? "bg-[#00b67a]" : "bg-[#8E8B8B]"} relative h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                                >
                                  <span className="sr-only">Active</span>
                                  <span
                                    aria-hidden="true"
                                    className={`${item?.is_enable ? "translate-x-6" : "translate-x-0"} pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                  />
                                </Switch>
                                {isToggleEmployeeLoading &&
                                  item?.id === employeeId && (
                                    <div className="flex items-center justify-center">
                                      <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                    </div>
                                  )}
                              </div>
                            </Table.Cell>
                            {isActiveStaffManagement && (
                              <Table.Cell>
                                <div className="flex justify-end items-center text-[#888]">
                                  <IconButton>
                                    <Pencil
                                      className="w-5 h-5 cursor-pointer"
                                      onClick={() => {
                                        setIsOpenEditStaffModal(item?.id);
                                      }}
                                    />
                                  </IconButton>

                                  {item?.id !== active?.employee_id && (
                                    <IconButton>
                                      <Trash2
                                        className="w-5 h-5 cursor-pointer text-red-500"
                                        onClick={() => {
                                          setIsOpenDeleteModal(true);
                                          setDeleteId(item?.id);
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                </div>
                              </Table.Cell>
                            )}
                          </Table.Row>
                          {item?.id === isOpenEditStaffModal && (
                            <EditStaffModal
                              openModal={item?.id === isOpenEditStaffModal}
                              setOpenModal={setIsOpenEditStaffModal}
                              refetch={refetch}
                              entityId={userInfo?.entity?.id}
                              key={item?.id}
                              data={item}
                              // setData={setToUpdateData}
                            />
                          )}
                          {item?.id === isOpenLink && (
                            <LinkModal
                              openModal={item?.id === isOpenLink}
                              setOpenModal={setIsOpenLink}
                              link={
                                baseUrl +
                                "/" +
                                userInfo?.entity?.magic_link?.split("?")?.[0] +
                                `?employee=${item?.employee_code}`
                              }
                            />
                          )}
                        </>
                      ))}
                  </>
                ) : null}
              </Table.Body>
            </Table>
          )}
        </div>
        {/* <div className="w-full lg:w-[630px] flex items-center justify-between mt-7">
          {index > 0 ? (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handlePrev}
            >
              Prev
            </button>
          ) : (
            <div></div>
          )}
          {data?.total > 0 && index + 10 < data?.total && (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handleNext}
            >
              Next
            </button>
          )}
        </div> */}
      </div>
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      {isOpenAddStaffModal && (
        <AddStaffModal
          openModal={isOpenAddStaffModal}
          setOpenModal={setIsOpenAddStaffModal}
          refetch={refetch}
          entityId={userInfo?.entity?.id}
          key={toUpdateData || uuidv4()}
          data={toUpdateData}
          setData={setToUpdateData}
        />
      )}
      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={"Are you sure you want to delete this link?"}
        handleDelete={handleDeleteEmployee}
        isLoading={deleteEmployeeLoading}
      />
      <QrContainer
        openEditModal={isOpenQrContainer}
        setOpenEditModal={setIsOpenQrContainer}
        qr={qr}
        isLoading={getMagicQrLoading}
      />
    </Layout>
  );
};

export default Staffs;
