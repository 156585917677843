/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { usePostReviewQuestionsByVoice } from "../../../query/useReview";
import AudioPlayer from "./AudioPlayer";
import { Drawer } from "flowbite-react";
import { Check, Pause, Play, RotateCcw, Square } from "lucide-react";
import { useNavigate } from "react-router";
import { toast } from "sonner";

const VoiceReview = ({ entityId, setStep, keywords, employeeId, mutate }) => {
  const navigate = useNavigate();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const recorderControls = useVoiceVisualizer();

  const {
    recordedBlob,
    error,
    startRecording,
    stopRecording,
    togglePauseResume,
    clearCanvas,
    formattedDuration,
    formattedRecordedAudioCurrentTime,
    formattedRecordingTime,
    isRecordingInProgress,
    isAvailableRecordedAudio,
    isPausedRecording,
    isPausedRecordedAudio,
    recordingTime,
    duration,
  } = recorderControls;
  const [audioFile, setAudioFile] = useState();
  const [audioUrl, setAudioUrl] = useState();

  const handleVoiceSubmit = () => {
    const formData = new FormData();
    formData.append("response", audioFile);
    formData.append("business_uuid", entityId);
    mutate(formData, {
      onSuccess: (res) => {
        localStorage.setItem("reviews", JSON.stringify(res));
        localStorage.setItem("reviewId", entityId);
        navigate(`/review?employee=${employeeId}&voicereview=true`);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (err.response.data["business_uuid"]) {
          toast.error(err.response.data["business_uuid"]);
        }
      },
    });
  };

  useEffect(() => {
    if (!recordedBlob) {
      setAudioFile();
      setAudioUrl();
      return;
    }
    setAudioFile(recordedBlob);

    // const reader = new FileReader();
    // reader.addEventListener("load", () => {
    //   setAudioUrl(reader.result);
    // });
    // reader.readAsDataURL(recordedBlob);

    const audioUrl = URL.createObjectURL(recordedBlob);
    setAudioUrl(audioUrl);

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [recordedBlob, error]);

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (recordingTime > 60000) {
      stopRecording();
    }
  }, [recordingTime]);

  return (
    <div>
      <button
        className="w-full bg-white rounded-md text-center py-3 font-heebo"
        onClick={() => {
          startRecording();
          setIsOpenDrawer(true);
        }}
      >
        Start Recording
      </button>
      <Drawer
        open={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false);
          clearCanvas();
          setAudioFile();
          setAudioUrl();
        }}
        draggable={false}
        position="bottom"
        theme={{
          root: {
            base: "fixed z-40 overflow-y-auto bg-white p-8 transition-transform rounded-t-[12px]",
            backdrop: "fixed inset-0 z-30 bg-gray-900/50 ",
            edge: "bottom-16",
            position: {
              bottom: {
                on: "bottom-0 left-0 right-0 w-full transform-none",
                off: "bottom-0 left-0 right-0 w-full translate-y-full",
              },
            },
          },
          header: {
            inner: {
              closeButton:
                "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
              closeIcon: "h-6 w-6",
            },
          },
        }}
      >
        <Drawer.Header titleIcon={() => {}}></Drawer.Header>
        <Drawer.Items>
          <div className="space-y-3 mb-5">
            <p className="font-heebo font-medium lg:text-2xl">
              Things to Talk About:
            </p>
            <div className="flex items-center gap-3 flex-wrap">
              {keywords
                ?.filter((item) => item !== null)
                .map((item) => (
                  <div key={item} className="p-2 border rounded-md text-[#888]">
                    {item}
                  </div>
                ))}
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center justify-between mb-3">
              <p className="text-center font-heebo font-medium">
                {/* {!audioFile && !isRecordingInProgress && "Record Audio"} */}
                {isRecordingInProgress && "Recording..."}
                {audioFile && "Recorded Audio"}
              </p>
              <div className=" font-heebo text-[#808080] flex items-center gap-x-2">
                {/* {!audioFile && !isRecordingInProgress && "00:00"} */}
                {(isRecordingInProgress || audioFile) && (
                  <div
                    className={`flex-shrink-0 size-2 rounded-full bg-red-500 ${isRecordingInProgress && "animate-pulse"}`}
                  ></div>
                )}
                {isRecordingInProgress && <p>{formattedRecordingTime}</p>}
                {audioFile && audioFile && <p>{formattedDuration}</p>}
              </div>
            </div>

            {(isRecordingInProgress || audioFile) && (
              <VoiceVisualizer
                controls={recorderControls}
                isControlPanelShown={false}
                mainBarColor="#5B7FFA"
                secondaryBarColor="#7190FF"
                barWidth={5}
                gap={3}
                height={100}
              />
            )}
            {audioUrl && isAvailableRecordedAudio && (
              <AudioPlayer src={audioUrl} duration={duration} />
            )}
          </div>
          <div className="flex items-center justify-between gap-3 mt-5 font-heebo text-sm">
            {isRecordingInProgress ? (
              <>
                <button
                  className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-12"
                  onClick={togglePauseResume}
                >
                  {isPausedRecording ? (
                    <Play className="size-4" />
                  ) : (
                    <Pause className="size-4" />
                  )}
                  {isPausedRecording ? "Resume" : "Pause"}
                </button>
                <button
                  className="w-full bg-[#ff6464]/20 text-[#ff6464] border border-[#ff6464] rounded-md flex items-center justify-center gap-x-2 h-12"
                  onClick={stopRecording}
                >
                  <Square className="size-4" />
                  Stop
                </button>
              </>
            ) : (
              <>
                <button
                  className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-12"
                  onClick={() => {
                    // clearCanvas();
                    setAudioFile();
                    setAudioUrl();
                    startRecording();
                  }}
                >
                  <RotateCcw className="size-4" />
                  Record Again
                </button>
                {audioFile && (
                  <button
                    className="bg-[#00cc00]/20 border border-[#00cc00] text-[#00cc00] rounded-md flex items-center justify-center gap-x-2 h-12 w-full"
                    onClick={handleVoiceSubmit}
                  >
                    <Check className="size-4" />
                    Generate
                  </button>
                )}
              </>
            )}
          </div>
        </Drawer.Items>
      </Drawer>
    </div>
  );
};

export default VoiceReview;
