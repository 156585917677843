const IconPlay = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8383 2.73505C14.0533 0.975725 11.883 0.0960617 9.32751 0.0960617C6.8362 0.0960617 4.67236 0.975725 2.83598 2.73505C1.06382 4.50722 0.177734 6.67748 0.177734 9.24583C0.177734 11.8142 1.06382 13.978 2.83598 15.7374C3.72207 16.6363 4.70767 17.3169 5.79281 17.7792C6.87794 18.2415 8.05617 18.4727 9.32751 18.4727C11.9215 18.4727 14.0918 17.5609 15.8383 15.7374C17.5976 13.978 18.4773 11.8174 18.4773 9.25547C18.4773 6.69353 17.5976 4.52006 15.8383 2.73505ZM8.13322 7.16547V11.9811L12.0628 9.5733L8.13322 7.16547Z" fill="#4F75FA"/>
    <path d="M13.8594 9.33872L7.8508 12.8078L7.8508 5.86967L13.8594 9.33872Z" fill="white"/>
    </svg>
    
  );
};

export default IconPlay;
