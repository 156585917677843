/* eslint-disable react/prop-types */
import { useMemo, useRef, useState } from "react";
import {
  useGetQuestionCategories,
  usePostToggleCategory,
  usePostToggleCategoryMandatory,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { ChevronDown } from "lucide-react";

const CustomDropdownForCategoryToggle = ({ isMandatory }) => {
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [loadingId, setLoadingId] = useState();

  const { data: categories, refetch: refetchCategories } =
    useGetQuestionCategories();

  const { mutate: toggleCategory, isLoading: toggleCategoryLoading } =
    usePostToggleCategory();

  const {
    mutate: toggleCategoryMandatory,
    isLoading: toggleCategoryMandatoryLoading,
  } = usePostToggleCategoryMandatory();

  const categoriesInDropdown = useMemo(() => {
    return categories
      ?.filter((item) => item?.is_dropdown)
      ?.map((item) => item?.category);
  }, [categories]);

  const categoriesInMandatory = useMemo(() => {
    return categories
      ?.filter((item) => item?.is_mandatory)
      ?.map((item) => item?.category);
  }, [categories]);

  const handleToggle = (id) => {
    setLoadingId(id);
    if (isMandatory) {
      toggleCategoryMandatory(id, {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchCategories();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      });
    } else {
      toggleCategory(id, {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchCategories();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      });
    }
  };

  useOnClickOutside({ ref: dropdownRef, setIsOpen: setIsOpen });
  return (
    <div className="lg:w-10/12 w-full relative h-12 font-pro" ref={dropdownRef}>
      <div
        className="px-5 py-3 h-full rounded-[7px] focus:outline-none focus:border-transparent flex items-center justify-between w-full bg-white dark:bg-darkLightBg dark:border-none cursor-pointer border border-[#ebebeb] dark:text-white"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <p className="">
          {!isMandatory && (
            <>
              {categoriesInDropdown?.length > 0
                ? categoriesInDropdown?.join(", ")
                : "Select Categories"}
            </>
          )}
          {isMandatory && (
            <>
              {categoriesInMandatory?.length > 0
                ? categoriesInMandatory?.join(", ")
                : "Select Categories"}
            </>
          )}
        </p>
        <ChevronDown
          className={`w-5 h-5 ${isOpen && "rotate-180"} transition-all duration-200`}
        />
      </div>
      {isOpen && (
        <div className="w-full p-5 rounded-[7px] absolute translate-y-3 left-0 bg-white dark:bg-darkLightBg dark:border-none border space-y-5 z-[10] text-left lg:h-[200px] overflow-y-auto scrollbar-thin shadow-md">
          {categories?.map((item) => (
            <div
              key={item?.id}
              className="flex items-center justify-between gap-x-3 group dark:text-white"
            >
              {/* {isToggle && (
            <Switch
              checked={item?.is_dropdown}
              onChange={() => {
                handleToggle(item?.id);
              }}
              title={`Keywords are in the ${item?.is_dropdown ? "dropdown" : "chips"}`}
              className={`${item?.is_dropdown ? "bg-[#73DF07]" : "bg-[#8E8B8B]"} relative h-[20px] w-10  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center ${toggleCategoryLoading && "cursor-progress"}`}
              disabled={toggleCategoryLoading}
            >
              <span className="sr-only">
                Switch keywords as dropdown or chip
              </span>
              <span
                aria-hidden="true"
                className={`${item?.is_dropdown ? "translate-x-5" : "translate-x-0"} pointer-events-none inline-block h-4 w-4  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          )} */}
              <div className="flex items-center gap-x-3">
                <input
                  id={item?.id}
                  type="checkbox"
                  checked={isMandatory ? item?.is_mandatory : item?.is_dropdown}
                  onClick={() => handleToggle(item?.id)}
                  readOnly
                  className="appearance-none outline-none ring-0 checked:bg-secondary rounded-sm"
                />
                <label
                  htmlFor={item?.id}
                  className="w-full cursor-pointer group-hover:text-secondary"
                >
                  {item?.category}
                </label>
              </div>
              {(toggleCategoryLoading || toggleCategoryMandatoryLoading) &&
                item?.id === loadingId && (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                  </div>
                )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdownForCategoryToggle;
