import { useState } from "react";
import {
  useGetSettings,
  usePostEnableSupport,
  usePostToggle,
  usePutUpdateSettings,
} from "../../../query/useAuthentication";
import Layout from "../component/Layout";
import LinkedAccount from "./components/LinkedAccount";
import Google from "../../../assets/reviewservices/google.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import { toast } from "sonner";
import { Link } from "lucide-react";

const Integration = () => {
  const [isSupport, setIsSupport] = useState(false);
  const [isActiveGoogle, setIsActiveGoogle] = useState(false);
  const [isOpenGoogleModal, setIsOpenGoogleModal] = useState(false);
  const [googleLink, setGoogleLink] = useState("");
  const [isActiveTrustpilot, setIsActiveTrustpilot] = useState(false);
  const [isOpenTrustpilotModal, setIsOpenTrustpilotModal] = useState(false);
  const [trustpilotLink, setTrustpilotLink] = useState("");

  const [isActiveYelp, setIsActiveYelp] = useState(false);
  const [isOpenYelpModal, setIsOpenYelpModal] = useState(false);
  const [yelpLink, setYelpLink] = useState("");

  const [isActiveBooking, setIsActiveBooking] = useState(false);
  const [isOpenBookingModal, setIsOpenBookingModal] = useState(false);
  const [bookingLink, setBookingLink] = useState("");

  const [isActiveTrip, setIsActiveTrip] = useState(false);
  const [isOpenTripModal, setIsOpenTripModal] = useState(false);
  const [tripLink, setTripLink] = useState("");

  const [isActiveCustom, setIsActiveCustom] = useState(false);
  const [isOpenCustomModal, setIsOpenCustomModal] = useState(false);
  const [customLink, setCustomLink] = useState("");

  const { data: settings, refetch: refetchSettings } = useGetSettings({
    onSuccess: (res) => {
      setIsSupport(true);
      setIsActiveGoogle(res.active);
      setGoogleLink(res.review_url);
    },
    onError: (err) => {
      if (err.response.status === 403) {
        setIsSupport(false);
      }
    },
  });
  const { mutate: updateSettings, isLoading: updateSettingsLoading } =
    usePutUpdateSettings();
  const { mutate: enableSupport, isLoading: enableSupportLoading } =
    usePostEnableSupport();
  const { mutate: toggle, isLoading: toggleLoading } = usePostToggle();

  const handleEnableSupport = () => {
    enableSupport(
      {},
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Enabled account integration successfully");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const toggleGoogle = () => {
    toggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchSettings();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  const handleUpdateSettings = () => {
    updateSettings(
      {
        review_url: googleLink,
        place_id: null,
      },
      {
        onSuccess: (res) => {
          refetchSettings();
          toast.success("Updated google review link successfully");
          setIsOpenGoogleModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  const toggleTrustpilot = () => {
    setIsActiveTrustpilot((prev) => !prev);
    if (!isActiveGoogle) {
      setIsOpenTrustpilotModal(true);
    }
  };
  const toggleYelp = () => {
    setIsActiveYelp((prev) => !prev);
    if (!isActiveYelp) {
      setIsOpenYelpModal(true);
    }
  };
  const toggleBooking = () => {
    setIsActiveBooking((prev) => !prev);
    if (!isActiveBooking) {
      setIsOpenBookingModal(true);
    }
  };
  const toggleTrip = () => {
    setIsActiveTrip((prev) => !prev);
    if (!isActiveTrip) {
      setIsOpenTripModal(true);
    }
  };
  const toggleCustom = () => {
    setIsActiveCustom((prev) => !prev);
  };
  return (
    <Layout title="Integrations">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] p-5 lg:p-8 min-h-[calc(100vh-140px)]">
        <div className="mt-5">
          <p className="font-pro font-medium text-xl">Link Your Accounts</p>
        </div>
        <hr className="mt-6" />
        <div
          className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 lg:gap-x-10 mt-10
          "
        >
          <LinkedAccount
            name={"Google My Business"}
            image={Google}
            isActiveLink={isActiveGoogle}
            setIsActiveLink={setIsActiveGoogle}
            isOpenModal={isOpenGoogleModal}
            setIsOpenmodal={setIsOpenGoogleModal}
            link={googleLink}
            setLink={setGoogleLink}
            toggle={toggleGoogle}
            isLoading={updateSettingsLoading}
            isEnableLoading={enableSupportLoading}
            isToggleLoading={toggleLoading}
            handleClick={handleUpdateSettings}
            isComingSoon={false}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          />
          <LinkedAccount
            name={"Trustpilot"}
            image={TrustPilot}
            isActiveLink={isActiveTrustpilot}
            setIsActiveLink={setIsActiveTrustpilot}
            isOpenModal={isOpenTrustpilotModal}
            setIsOpenmodal={setIsOpenTrustpilotModal}
            link={trustpilotLink}
            setLink={setTrustpilotLink}
            toggle={toggleTrustpilot}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={true}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          />
          <LinkedAccount
            name={"Yelp"}
            image={Yelp}
            isActiveLink={isActiveYelp}
            setIsActiveLink={setIsActiveYelp}
            isOpenModal={isOpenYelpModal}
            setIsOpenmodal={setIsOpenYelpModal}
            link={yelpLink}
            setLink={setYelpLink}
            toggle={toggleYelp}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={true}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          />
          <LinkedAccount
            name={"Booking"}
            image={Booking}
            isActiveLink={isActiveBooking}
            setIsActiveLink={setIsActiveBooking}
            isOpenModal={isOpenBookingModal}
            setIsOpenmodal={setIsOpenBookingModal}
            link={bookingLink}
            setLink={setBookingLink}
            toggle={toggleBooking}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={true}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          />
          <LinkedAccount
            name={"TripAdvisor"}
            image={TripAdvisor}
            isActiveLink={isActiveTrip}
            setIsActiveLink={setIsActiveTrip}
            isOpenModal={isOpenTripModal}
            setIsOpenmodal={setIsOpenTripModal}
            link={tripLink}
            setLink={setTripLink}
            toggle={toggleTrip}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={true}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          />
          {/* <LinkedAccount
            name={"Custom Link"}
            icon={<Link className="w-5 h-5" />}
            isActiveLink={isActiveCustom}
            setIsActiveLink={setIsActiveCustom}
            isOpenModal={isOpenCustomModal}
            setIsOpenmodal={setIsOpenCustomModal}
            link={customLink}
            setLink={setCustomLink}
            toggle={toggleCustom}
            isLoading={false}
            handleClick={handleUpdateSettings}
            isComingSoon={false}
            isSupport={isSupport}
            onEnableSupport={handleEnableSupport}
          /> */}
        </div>
      </div>
    </Layout>
  );
};

export default Integration;
