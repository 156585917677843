import { Table } from "flowbite-react";
import {
  useGetCharges,
  useGetInvoiceHistory,
  usePostDownloadInvoice,
} from "../../../../query/usePayment";
import { dayFormatter, numberFormatter } from "../../../../utils/formatter";
import Skeleton from "react-loading-skeleton";
import { Download } from "lucide-react";
import { toast } from "sonner";
import { useState } from "react";
import { useDarkMode } from "../../../../context/DarkModeProvider";

const Invoice = () => {
  const { darkMode } = useDarkMode();
  const [selectedDownload, setSelectedDownload] = useState("");
  const { data: charges, isLoading } = useGetCharges();
  const { mutate: downloadInvoice, isLoading: invoiceLoading } =
    usePostDownloadInvoice();

  const handleDownload = (id, date) => {
    setSelectedDownload(id);
    downloadInvoice(id, {
      onSuccess: (res) => {
        const blob = new Blob([res.data], { type: res.data.type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoice ${date && dayFormatter(date)}.pdf`;
        link.click();
        toast.success("Invoice downloaded successfully");
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  return (
    <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] p-5 sm:p-[31px] min-h-[calc(100vh-120px)]">
      <div className="overflow-x-auto">
        {charges?.results?.length <= 0 ? (
          <div className="w-full min-h-[400px] flex flex-col justify-center items-center space-y-3">
            <img src="/empty/no-invoice.svg" alt="" />
            <p className="font-pro text-[#888] text-sm">
              Your invoices will appear here!
            </p>
          </div>
        ) : (
          <Table
            theme={{
              root: {
                base: "w-full text-left text-sm lg:text-base ",
                shadow:
                  "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
              },
              head: {
                base: "group/head font-pro dark:text-darkGray",
                cell: {
                  base: "bg-white dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
                },
              },
              body: {
                base: "group/body",
                cell: {
                  base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
                },
              },
            }}
          >
            <Table.Head className="">
              <Table.HeadCell className="font-medium">Date</Table.HeadCell>
              <Table.HeadCell className="font-medium">Amount</Table.HeadCell>
              <Table.HeadCell className="font-medium">Status</Table.HeadCell>
              <Table.HeadCell className="font-medium">Download</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {isLoading ? (
                <Table.Row>
                  {new Array(4).fill("").map((_, i) => (
                    <Table.Cell key={i} className="bg-white dark:bg-darkBg">
                      <Skeleton height={30} baseColor={darkMode && "#262626"} />
                    </Table.Cell>
                  ))}
                </Table.Row>
              ) : charges?.results?.length > 0 ? (
                charges?.results?.map((charge, i) => (
                  <Table.Row key={i} className="bg-white dark:bg-darkBg">
                    <Table.Cell className="">
                      {charge?.created_at && dayFormatter(charge?.created_at)}
                    </Table.Cell>
                    <Table.Cell>
                      {charge?.currency}{" "}
                      {charge?.unit_amount / charge?.currency_unit_multiplier}{" "}
                      {"("}
                      {charge?.plan_text}
                      {")"}
                    </Table.Cell>
                    <Table.Cell className="capitalize">
                      {charge?.status}
                    </Table.Cell>
                    <Table.Cell>
                      {invoiceLoading && selectedDownload === charge?.uuid ? (
                        <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
                      ) : (
                        <Download
                          className="text-[#a6a6a6] cursor-pointer"
                          onClick={() => {
                            handleDownload(charge?.uuid, charge?.created_at);
                          }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : null}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  );
};

export default Invoice;
