import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import PasswordInput from "../../components/input/passwordinput";
import { useGetIndustryTypes, useSignUp } from "../../query/useAuthentication";
import { useGetEntitySuggestions } from "../../query/useEntity";
import { emailRegex } from "../contact/contact";
import useDebounce from "../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { LoadingButton, LogoLoading } from "../../components/loading/loading";
import { Asterisk, ChevronDown } from "lucide-react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import TagsInput from "react-tagsinput";
export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const SignUp = () => {
  const industryRef = useRef(null);
  const [params] = useSearchParams();
  const referer = params.get("referer");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("hotel");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [website, setWebsite] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [place, setPlace] = useState("");
  const [placeId, setPlaceId] = useState("");
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [openSelectIndustry, setOpenSelectIndustry] = useState(false);
  const [seoKeywords, setSeoKeywords] = useState([]);
  const [mustHaveSeoKeywords, setMustHaveSeoKeywords] = useState([]);
  const [reviewSamples, setReviewSamples] = useState([]);

  const { mutate: signup, isLoading: isSignUpLoading } = useSignUp();
  const { data: industryTypes } = useGetIndustryTypes();

  // const debouncedPlace = useDebounce(place, 500);

  // const { data: placesuggestions, isLoading: placesuggestionLoading } =
  //   useGetEntitySuggestions(
  //     {
  //       placename: debouncedPlace,
  //     },
  //     {
  //       enabled: Boolean(debouncedPlace),
  //     }
  //   );

  const handleSubmit = async () => {
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      setIsLoading(false);
      return;
    }

    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      toast.error("Invalid password");
      setIsLoading(false);
      return;
    } else {
      setIsPasswordValid(true);
    }

    if (password !== confirmPassword) {
      toast.error("Password does not match");
      setIsLoading(false);
      return;
    }

    let data = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName || "",
      entity: {
        name: companyName,
        place_id: null,
        industry: industry,
        company_link: null,
        google_review_link: null,
        seo_keywords: seoKeywords || [],
        review_samples: reviewSamples || [],
        must_have_seo_keywords: mustHaveSeoKeywords || [],
      },
      referral_code: referralCode || null,
    };

    signup(data, {
      onSuccess: (data) => {
        // localStorage.setItem("token", data.token);
        toast.success(data.message);
        navigate(`/verify-email?id=${data.verificiation_id}`);
        // navigate("/dashboard/payment?savedCard=false");
        // navigate("/dashboard");
      },
      onError: (error) => {
        if (error?.response?.data?.entity?.google_review_link) {
          toast.error(
            error.response.data.entity.google_review_link[0].split(".")[0] +
              " for google review link."
          );
        } else if (error?.response?.data?.entity?.company_link) {
          toast.error(
            error.response.data.entity.company_link[0].split(".")[0] +
              " for website link."
          );
        } else if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${error.response.status}`
          );
        }
      },
    });
  };

  useOnClickOutside({ ref: industryRef, setIsOpen: setOpenSelectIndustry });

  if (isSignUpLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full min-h-screen container flex lg:items-center lg:justify-center py-10 2xl:my-0 bg-[#F7F7F7]">
      <Helmet>
        <link rel="canonical" href="https://www.magicreview.ai/sign-up" />
      </Helmet>
      <div className="w-full flex flex-col md:justify-center md:items-center lg:px-52">
        <div className="flex flex-col items-center justify-center w-full mt-10 mb-5">
          <Link to="/">
            <img
              src="/new-logo-with-sidetext.svg"
              alt="logo"
              className=" w-auto"
            />
          </Link>
          <h1 className="text-xl lg:text-[32px] leading-8 md:-tracking-[0.5px] font-bold text-[#323131] mt-[20px] font-opensans">
            Create your Account
          </h1>
          {/* <p className="text-[#10182899] text-center text-sm mt-3 font-montserrat">
            Enter the fields below to get started
          </p> */}
        </div>
        <div className="w-full mt-[30px]">
          <form className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="firstname"
                className="text-sm text-[#585866] font-montserrat flex items-center gap-x-1"
              >
                First Name <Asterisk className="size-3.5 text-red-500" />
              </label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                id="firstname"
                placeholder="e.g. john"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="lastname"
                className="text-sm text-[#585866] font-montserrat flex items-center gap-x-1"
              >
                Last Name <Asterisk className="size-3.5 text-red-500" />
              </label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                id="lastname"
                placeholder="e.g. doe"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="email"
                className="text-sm text-[#585866] font-montserrat flex items-center gap-x-1"
              >
                Email <Asterisk className="size-3.5 text-red-500" />
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                placeholder="e.g. mail@domain.com"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="company"
                className="text-sm text-[#585866] font-montserrat flex items-center gap-x-1"
              >
                Company / Business Name{" "}
                <Asterisk className="size-3.5 text-red-500" />
              </label>
              <input
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  localStorage.setItem("companyName", e.target.value);
                }}
                id="company"
                placeholder="e.g. xyz"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="industry"
                className="text-sm text-[#585866] font-montserrat flex items-center gap-x-1"
              >
                Industry <Asterisk className="size-3.5 text-red-500" />
              </label>
              <div className="w-full relative" ref={industryRef}>
                <div
                  className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans flex items-center justify-between w-full bg-white"
                  onClick={() => setOpenSelectIndustry((prev) => !prev)}
                >
                  <p className="text-sm">
                    {
                      industryTypes?.find(
                        (item) => item?.industry_identifier === industry
                      )?.display_text
                    }
                  </p>
                  <ChevronDown
                    className={`w-5 h-5 ${openSelectIndustry && "rotate-180"} transition-all duration-200`}
                  />
                </div>
                {openSelectIndustry && (
                  <div className="w-full px-5 py-3 rounded-lg absolute top-12 left-0 bg-[#f6f6ff] border text-[#615e83] font-poppins space-y-3 z-[60] text-sm text-left lg:h-[200px] overflow-y-auto scrollbar-thin">
                    {industryTypes?.map((item) => (
                      <p
                        className="w-full cursor-pointer"
                        onClick={() => {
                          setOpenSelectIndustry(false);
                          setIndustry(item?.industry_identifier);
                        }}
                        key={item?.id}
                      >
                        {item?.display_text}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              {/* <select
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                id="industry"
                className="border border-gray-300 px-3 py-[7px] rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              >
                {industryTypes?.map((item) => (
                  <option value={item?.industry_identifier} key={item?.id}>
                    {item?.display_text}
                  </option>
                ))}
              </select> */}
            </div>
            {/* <div className="flex flex-col gap-1">
              <label
                htmlFor="website"
                className="text-sm text-[#585866] font-montserrat"
              >
                Website Link
              </label>
              <input
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                id="website"
                placeholder="e.g. www.company.in"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div> */}

            {/* <div className="flex flex-col gap-1">
              <label
                htmlFor="google"
                className="text-sm text-[#585866] font-montserrat"
              >
                Google Review Link
              </label>
              <input
                value={googleLink}
                onChange={(e) => setGoogleLink(e.target.value)}
                id="google"
                placeholder="e.g. www.company/googlereview.in"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div> */}
            {/* <div className="flex flex-col gap-1 relative">
              <label
                htmlFor="place"
                className="text-sm text-[#585866] font-montserrat"
              >
                Place
              </label>
              <input
                value={place}
                onChange={(e) => setPlace(e.target.value)}
                id="place"
                placeholder="e.g. Dubai"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
              {/* <input
                value={place}
                onChange={(e) => setPlace(e.target.value)}
                onFocus={() => {
                  setOpenSuggestionBox(true);
                }}
                id="place"
                placeholder="e.g. Dubai"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
              {openSuggestionBox && place && (
                <div className="w-full 2xl:h-[300px] lg:h-[200px] p-5 rounded-lg absolute top-16 bg-white z-[60] overflow-y-scroll scrollbar-none">
                  <p className="pb-3">Get suggestions</p>
                  {placesuggestionLoading ? (
                    <div className="py-5">Loading...</div>
                  ) : (
                    <>
                      {placesuggestions?.suggestions?.length > 0 ? (
                        <>
                          {placesuggestions?.suggestions?.map(
                            (suggestion, i) => (
                              <div
                                className="py-2 hover:text-secondary cursor-pointer"
                                key={i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setPlaceId(suggestion?.place_id);
                                  setPlace(suggestion?.formatted_address);
                                  setOpenSuggestionBox(false);
                                }}
                              >
                                {suggestion?.formatted_address}
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <div className="py-5">No result!</div>
                      )}
                    </>
                  )}
                </div>
              )} 
            </div> */}
            <div className="flex flex-col gap-1 relative">
              <label
                htmlFor="referral_code"
                className="text-sm text-[#585866] font-montserrat"
              >
                Referral Code
              </label>
              <input
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                id="referral_code"
                placeholder="e.g. d5xe3PH2m6"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans"
              />
            </div>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label={"New Password"}
              isPasswordValid={isPasswordValid}
            />
            <PasswordInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label={"Confirm Password"}
            />
            {/* <div className="flex flex-col gap-1 relative">
              <label
                htmlFor="referral_code"
                className="text-sm text-[#585866] font-montserrat"
              >
                Seo Keywords
              </label>
              <TagsInput
                value={seoKeywords}
                onChange={(tags) => setSeoKeywords(tags)}
                className="w-full border font-pro !border-[#e7e4e4] rounded-lg px-3 pt-1 bg-white"
                renderInput={({ onChange, value, ...other }) => (
                  <input
                    type="text"
                    onChange={onChange}
                    value={value}
                    {...other}
                    placeholder=""
                    className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 "
                  />
                )}
                renderTag={({
                  tag,
                  key,
                  disabled,
                  onRemove,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                }) => (
                  <p
                    key={key}
                    {...other}
                    className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black"
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && (
                      <a
                        className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
                        onClick={(e) => onRemove(key)}
                      />
                      // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
                    )}
                  </p>
                )}
              />
            </div> */}

            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={
                isSignUpLoading ||
                !email.trim() ||
                !password.trim() ||
                !confirmPassword.trim() ||
                !firstName.trim() ||
                !lastName.trim() ||
                !companyName.trim() ||
                !industry.trim()
              }
              className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-secondary active:scale-95 lg:col-span-2 group"
            >
              {/* {isSignUpLoading ? <LoadingButton /> : "Create Account"}
               */}
              Create Account
            </button>
          </form>
          {/* <div className="flex items-center justify-center w-full mt-[50.57px]">
            <button
              className="w-full flex justify-center items-center gap-3 border border-[#C2C2C2] py-2.5 rounded-lg active:scale-95"
              onClick={handleGoogleLogin}
              disabled
            >
              <img loading="lazy"src="./google.svg" alt="" />
              <p className="font-montserrat text-[15px] text-[#2424247D]">
                Continue with Google
              </p>
            </button>
          </div> */}
        </div>
        <p className="text-gray-600 text-center lg:text-base text-sm mt-5 font-montserrat">
          Already have an account?{" "}
          <Link
            to="/sign-in"
            className="text-[#4F46BA] hover:text-blue-600 font-semibold"
          >
            Sign in
          </Link>
        </p>
      </div>
      {/* <div className="w-full md:w-1/2 md:block hidden pl-10">
        <div className="max-w-[430px] mx-auto w-full rounded-tl-[100px] pt-[39px] px-[10px] relative bg-[#6B61E1] flex flex-col items-center justify-center">
          <img
            src="/reviews.svg"
            alt="logo"
            className="left-[320px] top-[190px] absolute w-auto xl:block hidden"
          />
          <h1 className="text-[32px] font-bold font-opensans text-white mb-[30px]">
            Very good works are <WordBreak /> waiting for you.
            <WordBreak />
            Sign up Now!
          </h1>
          <LazyLoadImage
            src="/signup.png"
            className="max-w-[320px] w-full mx-auto object-contain"
          />
        </div>
      </div> */}
    </div>
  );
};

export default SignUp;
