import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
// import style from "../component/slider.module.scss";
import "swiper/css";
import "swiper/css/pagination";
// eslint-disable-next-line react-refresh/only-export-components
export const HomeSliderData = [
  {
    name: "Sarah Thompson",
    role: "Founder of Summit Solutions",
    description:
      "I Love How MagicReview Provides A Personalized Dashboard To Track My Google Review Card-Generated Reviews. It's So Easy To Monitor Progress And Customize Forms To Suit My Business Needs. Thanks To MagicReview, I'm Always Informed And In Control Of My Review Collection Efforts.",
    image: "/sarah.png",
  },
  {
    name: "Russ Hanneman",
    role: "Founder of Tres Commas",
    description:
      "MagicReview's AI-Generated Reviews Are Incredible! I Was Amazed At How Well-Structured And Engaging They Were, All Based On The User Input. It's Like Having A Personal Assistant For Review Writing! Thanks To MagicReview, Sharing Feedback Has Never Been Easier.",
    image: "/happywoman.png",
  },

  {
    name: "Mark Rodriguez",
    role: "CEO of  Global Dynamics",
    description:
      "MagicReview's Google Review Cards Made Leaving Feedback Easy! With A Tap, The Customer Effortlessly Shared Their Thoughts. The Process Was So Simple And Convenient With The Help Of Rapid AI Review Tool.",
    image: "/manmark.png",
  },
];

const HomeSlider = () => {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handlePaginationClick = () => {};
  return (
    <div className="w-full flex justify-between items-center max-w-[1100px] mx-auto">
      <div
        className="prev-button cursor-pointer border bg-[#F1F4FF] w-fit rounded-[4px] sm:rounded-[12px] sm:px-[14px] py-[14px] sm:py-[32px] hidden xxs:flex"
        onClick={handlePrev}
      >
        <img
          loading="lazy"
          className="w-[9px] xxs:w-fit"
          src="/homeleftarr.svg"
          alt="homeleftarr"
        />
      </div>
      <Swiper
        ref={swiperRef}
        className="max-w-[300px] sm:max-w-[849px] w-full mx-auto mb-[40px] "
        // className={`mt-6 ${style.slider}`}
        spaceBetween={16}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".next-button",
          prevEl: ".prev-button",
        }}
        // pagination={{
        //   clickable: true,
        //   renderBullet: function (index, className) {
        //     return '<span class="' + className + '"></span>'; // Only the dots are rendered
        //   },
        // }}
        modules={[Pagination]}
        onSwiper={(swiper) => {
          // Add event listener for pagination click
          swiper.on("paginationClick", handlePaginationClick);
        }}
      >
        {HomeSliderData.map((item, index) => (
          <SwiperSlide
            key={index}
            className="w-full bg-[#4F75FA] rounded-[5.8px] sm:rounded-[20px] flex flex-col px-[15px] md:px-[75px] py-[22px] md:py-[52px] font-poppins relative flex-grow h-[300px] sm:h-auto justify-between"
          >
            <div>
              <img
                src="/leftquote.svg"
                alt="leftquote"
                className="w-[28px] md:w-[72px] mb-[10px] sm:mb-[28px]"
              />
              <p className="text-[12px] md:text-xl text-white w-full font-normal mb-[19px] sm:mb-[50px] font-poppins">
                {item.description}
              </p>
            </div>

            <div className="flex gap-3 md:gap-7 items-center">
              <img
                src={item.image}
                alt="happywoman"
                className="w-[40px] h-[40px] md:w-[50px] md:h-[50px]"
              />
              <div className="flex flex-col">
                <p className="text-white text-[12px] xxs:text-base md:text-xl font-semibold">
                  {item.name}
                </p>
                <p className="text-white text-[10px] xxs:text-sm md:text-lg font-normal">
                  {item.role}
                </p>
              </div>
            </div>
            {/* <img
              src="/slideprism.png"
              alt="prism"
              className="absolute -bottom-[16px] left-[50%] transform -translate-x-[50%]"
            /> */}

            {/* <img
              src="/rightquote.svg"
              alt="rightquote"
              className="absolute w-[28px] h-[29px] md:w-[71px] md:h-[71px] bottom-[125px] xxs:bottom-[100px] md:bottom-[125px] right-6"
            /> */}
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="next-button cursor-pointer border bg-[#F1F4FF] w-fit rounded-[4px] sm:rounded-[12px] sm:px-[14px] py-[14px] sm:py-[32px] hidden xxs:flex"
        onClick={handleNext}
      >
        <img
          loading="lazy"
          className="w-[9px] xxs:w-fit"
          src="/homerightarr.svg"
          alt="homerightarr"
        />
      </div>
    </div>
  );
};

export default HomeSlider;
