import { useQuery, useMutation } from "@tanstack/react-query";
import { contactApi, feedbackApi } from "../api/contactApi";

export const usePostContact = (config) => {
  return useMutation({
    mutationKey: ["postContact"],
    mutationFn: (data) => contactApi(data),
    ...config,
  });
};

export const usePostFeedback = (config) => {
  return useMutation({
    mutationKey: ["postFeedback"],
    mutationFn: (data) => feedbackApi(data),
    ...config,
  });
}
