/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { useGetStatsGraph } from "../../../../query/useEntity";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Skeleton from "react-loading-skeleton";
import { dayFormatter } from "../../../../utils/formatter";
import { ChevronDown, Recycle, RefreshCw } from "lucide-react";
import { Modal } from "flowbite-react";
import { DateRange } from "react-date-range";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { useDarkMode } from "../../../../context/DarkModeProvider";

const timePeriods = [
  { display: "Last 24 hours", value: "daily" },
  { display: "Last 7 days", value: "weekly" },
  { display: "Last 30 days", value: "monthly" },
  { display: "Last 3 months", value: "quarterly" },
  { display: "Last 1 year", value: "yearly" },
];

const CustomToolTip = ({ payload }) => {
  return (
    <div className="bg-[#1E1B39] rounded-lg text-center px-2 py-1 text-white min-w-[50px] customTooltip">
      {payload?.[0]?.payload.review}
    </div>
  );
};

const AiReviewPosted = () => {
  const periodRef = useRef(null);
  const { darkMode } = useDarkMode();
  const [period, setPeriod] = useState("yearly");
  const [openSelectPeriod, setOpenSelectPeriod] = useState(false);
  const [focusBar, setFocusBar] = useState(null);
  const [bargraphHeight, setBargraphHeight] = useState(0);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [confirmDate, setConfirmDate] = useState();
  const {
    data: aiReviewPostedAggregation,
    isLoading: isAiReviewPostedAggregationLoading,
  } = useGetStatsGraph(period);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const isLargerScreen = windowSize.width >= 1920;

  let data;

  if (period === "yearly") {
    // Change the structure for yearly period
    data = [
      {
        name: "Jan",
        review: aiReviewPostedAggregation?.data?.Jan,
      },
      {
        name: "Feb",
        review: aiReviewPostedAggregation?.data?.Feb,
      },
      {
        name: "Mar",
        review: aiReviewPostedAggregation?.data?.Mar,
      },
      {
        name: "Apr",
        review: aiReviewPostedAggregation?.data?.Apr,
      },
      {
        name: "May",
        review: aiReviewPostedAggregation?.data?.May,
      },
      {
        name: "Jun",
        review: aiReviewPostedAggregation?.data?.Jun,
      },
      {
        name: "Jul",
        review: aiReviewPostedAggregation?.data?.Jul,
      },
      {
        name: "Aug",
        review: aiReviewPostedAggregation?.data?.Aug,
      },
      {
        name: "Sep",
        review: aiReviewPostedAggregation?.data?.Sep,
      },
      {
        name: "Oct",
        review: aiReviewPostedAggregation?.data?.Oct,
      },
      {
        name: "Nov",
        review: aiReviewPostedAggregation?.data?.Nov,
      },

      {
        name: "Dec",
        review: aiReviewPostedAggregation?.data?.Dec,
      },
      // Add other months as needed
    ];
  } else if (period === "daily") {
    data = [
      {
        name: "12 AM",
        review: aiReviewPostedAggregation?.data?.["00"],
      },
      {
        name: "2 AM",
        review: aiReviewPostedAggregation?.data?.["02"],
      },
      {
        name: "4 AM",
        review: aiReviewPostedAggregation?.data?.["04"],
      },
      {
        name: "6 AM",
        review: aiReviewPostedAggregation?.data?.["06"],
      },
      {
        name: "8 AM",
        review: aiReviewPostedAggregation?.data?.["08"],
      },
      {
        name: "10 AM",
        review: aiReviewPostedAggregation?.data?.["10"],
      },
      {
        name: "12 PM",
        review: aiReviewPostedAggregation?.data?.["12"],
      },
      {
        name: "2 PM",
        review: aiReviewPostedAggregation?.data?.["14"],
      },
      {
        name: "4 PM",
        review: aiReviewPostedAggregation?.data?.["16"],
      },
      {
        name: "6 PM",
        review: aiReviewPostedAggregation?.data?.["18"],
      },
      {
        name: "8 PM",
        review: aiReviewPostedAggregation?.data?.["20"],
      },

      {
        name: "10 PM",
        review: aiReviewPostedAggregation?.data?.["22"],
      },
      // Add other months as needed
    ];
  } else {
    data = aiReviewPostedAggregation?.data
      ? Object.keys(aiReviewPostedAggregation?.data).map((month) => ({
          name: month,
          review: aiReviewPostedAggregation?.data?.[month],
        }))
      : [];
  }

  const handleSelectPeriod = (value) => {
    setPeriod(value);
    setOpenSelectPeriod(false);
  };

  useOnClickOutside({ ref: periodRef, setIsOpen: setOpenSelectPeriod });

  return (
    <div className="flex flex-col w-full p-5 lg:p-8 3xl:p-12 rounded-[12px] bg-white dark:bg-darkBg min-h-max shadow-sm">
      <header className="flex justify-between items-center mb-9">
        {isAiReviewPostedAggregationLoading ? (
          <Skeleton
            containerClassName="flex-1"
            baseColor={darkMode && "#262626"}
          />
        ) : (
          <>
            <h1 className="font-pro text-[20px] 3xl:text-3xl">
              AI Reviews Redirected
            </h1>
            <div
              className={`relative ${period ? "w-40" : "w-fit"}`}
              ref={periodRef}
            >
              <button
                className={`w-full h-10 bg-[#f6f6ff] dark:bg-darkLightBg flex justify-between items-center px-5 text-[#615e83] dark:text-secondary ${openSelectPeriod ? "rounded-t-lg" : "rounded-lg"}`}
                onClick={() => setOpenSelectPeriod((prev) => !prev)}
              >
                <p className="font-poppins text-sm">
                  {period ? (
                    timePeriods?.find((item) => item.value === period)?.display
                  ) : (
                    <span>
                      {confirmDate?.[0]?.startDate &&
                        dayFormatter(confirmDate?.[0]?.startDate)}{" "}
                      {"-"}{" "}
                      {confirmDate?.[0]?.endDate &&
                        dayFormatter(confirmDate?.[0]?.endDate)}
                    </span>
                  )}
                </p>
                <ChevronDown
                  className={`w-5 h-5 ${openSelectPeriod && "rotate-180"} transition-all duration-200`}
                />
              </button>
              {openSelectPeriod && (
                <>
                  <div className="w-full px-5 pt-1 pb-3 rounded-b-lg absolute top-10 left-0 bg-[#f6f6ff] dark:bg-darkLightBg text-[#615e83] dark:text-secondary font-poppins space-y-3 z-50 text-sm text-left">
                    {timePeriods
                      .filter((item) => item.value !== period)
                      .map((item, i) => (
                        <p
                          className="w-full cursor-pointer hover:text-secondary"
                          onClick={() => handleSelectPeriod(item.value)}
                          key={item.value}
                        >
                          {item.display}
                        </p>
                      ))}
                    {/* <hr className="w-full h-1" />
                <p
                  className="w-full cursor-pointer"
                  onClick={() => {
                    setOpenDateRange(true);
                    //   setPeriod("");
                  }}
                >
                  Custom
                </p> */}
                  </div>
                  {
                    <Modal
                      dismissible
                      show={openDateRange}
                      onClose={() => {
                        setOpenDateRange(false);
                        setOpenSelectPeriod(false);
                      }}
                      className="z-[1000]"
                      size={"md"}
                      theme={{
                        content: {
                          base: "relative w-full p-4 h-auto !ring-0 !outline-none !shadow-none !border-none !focus:ring-0 !focus:outline-none !focus:border-none !focus:shadow-none",
                        },
                      }}
                    >
                      <Modal.Body className="flex flex-col justify-center items-center">
                        <DateRange
                          editableDateInputs={false}
                          onChange={(item) => setDate([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={date}
                        />
                        <div className="flex flex-wrap gap-2 sm:justify-end">
                          <button
                            className="flex items-center rounded-[7px] border border-secondary text-white text-base text-center font-medium py-[10px]  px-[40px] bg-secondary gap-[6px] mt-[20px] "
                            onClick={() => {
                              setConfirmDate(date);
                              setOpenDateRange(false);
                              setPeriod();
                            }}
                          >
                            Confirm
                          </button>

                          <button
                            className="flex items-center rounded-[7px] border border-secondary text-secondary text-base text-center font-medium py-[10px]  px-[40px] bg-white gap-[6px] mt-[20px] "
                            onClick={() => {
                              setOpenDateRange(false);
                              setConfirmDate();
                              setDate([
                                {
                                  startDate: new Date(),
                                  endDate: null,
                                  key: "selection",
                                },
                              ]);
                              if (!period) {
                                setPeriod("last_3_hours");
                              }
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  }
                </>
              )}
            </div>
          </>
        )}
      </header>
      {isAiReviewPostedAggregationLoading ? (
        <Skeleton
          height={isMobile ? 240 : isLargerScreen ? 350 : 300}
          baseColor={darkMode && "#262626"}
        />
      ) : (
        <ResponsiveContainer
          height={isMobile ? 240 : isLargerScreen ? 350 : 300}
          className="w-full my-auto relative"
        >
          <BarChart
            className="w-full h-full text-sm"
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
            barSize={isLargerScreen ? 50 : isMobile ? 27 : 35}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
              } else {
                setFocusBar(null);
              }
            }}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{
                left: isLargerScreen ? 25 : 17,
                right: isLargerScreen ? 25 : 17,
              }}
              tick={{ fontSize: isLargerScreen ? "15px" : "10px" }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              width={30}
              tick={{ fontSize: isLargerScreen ? "15px" : "10px" }}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip
              content={<CustomToolTip />}
              allowEscapeViewBox={{
                x: true,
                y: true,
              }}
              cursor={{ fill: "transparent" }}
              offset={-28}
              position={{
                // x: bargraphHeight.x,
                y: bargraphHeight.y - 40,
              }}
            />
            <CartesianGrid strokeDasharray="5 5" vertical={false} />
            <Bar
              dataKey="review"
              fill="#7190FF"
              radius={[8, 8, 0, 0]}
              onMouseOver={(data) => {
                setBargraphHeight(data);
              }}
            >
              {data?.map((entry, index) => (
                <Cell
                  fill={
                    focusBar === index
                      ? "#7190FF"
                      : darkMode
                        ? "#213478"
                        : "#F0E5FC"
                  }
                  key={index}
                />
              ))}
            </Bar>
          </BarChart>
          {data?.every((item) => !item?.review) && (
            <div className="flex items-center justify-center w-full gap-x-2 absolute left-1/2 -translate-x-1/2 top-[41%] -translate-y-1/2  z-50">
              <RefreshCw className="text-secondary" />
              <p className="text-sm text-[#888] font-pro">No data found!</p>
            </div>
          )}
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AiReviewPosted;
