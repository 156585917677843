/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  usePostAddQuestionSet,
  usePutUpdateQuestionSet,
} from "../../../query/useEntity";
import { toast } from "sonner";

const AddOrEditQuestionSet = ({
  isOpenModal,
  setIsOpenmodal,
  data,
  setData,
  refetchQuestionSets,
}) => {
  const [name, setName] = useState("");
  const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
    usePostAddQuestionSet();
  const { mutate: updateQuestionSet, isLoading: updateQuestionSetLoading } =
    usePutUpdateQuestionSet();

  const handleClick = () => {
    if (data) {
      updateQuestionSet(
        {
          id: data?.id,
          name: name,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.message);
            setIsOpenmodal(false);
            refetchQuestionSets();
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      addQuestionSet(
        {
          name: name,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.message);
            setIsOpenmodal(false);
            refetchQuestionSets();
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    if (data) {
      setName(data?.name);
    }
  }, []);

  return (
    <Modal
      dismissible
      show={isOpenModal}
      onClose={() => {
        setIsOpenmodal(false);
        setData();
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative w-full p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
    >
      <Modal.Header>
        <p className="font-pro font-medium text-2xl px-5">Question Set</p>
      </Modal.Header>
      <Modal.Body className="h-auto">
        <div className="pb-5 px-5">
          <p className="font-pro font-medium text-xl dark:text-white">Name</p>
          <input
            type="text"
            className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-5 dark:bg-darkLightBg dark:border-none dark:text-white"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="flex justify-center items-center gap-x-3 mt-7">
            <button
              className="bg-secondary w-[121px] py-3 rounded-[7px] text-white border border-secondary "
              onClick={handleClick}
            >
              {addQuestionSetLoading || updateQuestionSetLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Save"
              )}
            </button>
            <button
              className="bg-white w-[121px] py-3 rounded-[7px] text-secondary border border-secondary dark:bg-darkLightBg"
              onClick={() => {
                setIsOpenmodal(false);
                setData();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddOrEditQuestionSet;
