/* eslint-disable react/prop-types */
import { Switch } from "@headlessui/react";
import { Tooltip } from "flowbite-react";
import { ChevronDown, CirclePlus, CircleX, Search } from "lucide-react";
import { useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";

const ResponseOptionsForModal = ({ question, setQuestion }) => {
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState("");

  const handleResponseOptionChange = (value, index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      current.response_options[index] = value;
      return current;
    });
  };

  const handleResponseOptionDelete = (index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      const options = [...current.response_options];
      current.response_options = options.filter((_, i) => i !== index);
      return current;
    });
  };

  const handleAddOption = () => {
    setQuestion((prev) => {
      const current = { ...prev };
      let options;
      if (current.response_options === null) {
        options = ["New Option"];
      } else {
        options = [...current.response_options];
        options.push("New Option");
      }

      current.response_options = options;
      return current;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setQuestion((prev) => {
          const current = { ...prev };
          const addedValues = e.target.result
            ?.split("\n")
            ?.map((item) => item.trim());
          let options;
          if (current.response_options === null) {
            options = [...addedValues];
          } else {
            options = [...current.response_options, ...addedValues];
          }
          current.response_options = options;
          return current;
        });
      };
      reader.readAsText(file);
    }
    e.target.value = "";
  };

  useOnClickOutside({ ref: dropdownRef, setIsOpen: setIsOpenDropdown });
  return (
    <>
      <div className="my-5 space-y-3 add-question">
        <div className="flex items-center gap-x-1.5">
          <p className="text-base lg:text-[20px] dark:text-white">Question</p>
        </div>

        <textarea
          type="text"
          placeholder="Enter Your Question"
          className="w-full border border-[#C2C2C2] rounded-[9px] p-3 mt-3 resize-none placeholder:text-[#a6a6a6] dark:bg-darkLightBg dark:text-white dark:border-none"
          rows={3}
          value={question?.question}
          onChange={(e) =>
            setQuestion((prev) => {
              let current = { ...prev };
              current.question = e.target.value;
              return current;
            })
          }
        />

        <div className="flex items-center gap-x-3 my-5 dark:text-white">
          <input
            id="mandatory"
            type="checkbox"
            checked={question?.is_mandatory}
            onClick={() =>
              setQuestion((prev) => {
                let current = { ...prev };
                current.is_mandatory = !current?.is_mandatory;
                return current;
              })
            }
            className="cursor-pointer checked:bg-newPrimary appearance-none outline-none ring-0 border border-[#c2c2c2] focus:ring-0 rounded-[2px] size-6"
            readOnly
          />
          <label className="text-[#4a4a4a] dark:text-white" htmlFor="mandatory">
            (This question should be mandatory on review page)
          </label>
        </div>
      </div>
      {question?.show_as === "DROPDOWN" ? (
        <div className="flex justify-between items-center gap-x-5 add-responses">
          <div className="w-full relative" ref={dropdownRef}>
            <button
              className="w-full h-14 bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo text-lg border border-[#c2c2c2] dark:bg-darkLightBg dark:text-white dark:border-none"
              onClick={() => setIsOpenDropdown((prev) => !prev)}
            >
              <p>Select response</p>
              <ChevronDown
                className={`w-5 h-5 ${isOpenDropdown && "rotate-180"} transition-all duration-200`}
              />
            </button>
            {isOpenDropdown && (
              <>
                <div className="w-full p-5 rounded-lg absolute top-16 left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow-xl bg-white border border-[#c2c2c2] dark:bg-darkLightBg dark:text-white dark:border-none">
                  <div className="relative w-full">
                    <input
                      type="text"
                      value={searchDropdown}
                      onChange={(e) => setSearchDropdown(e.target.value)}
                      className="w-full border font-pro border-[#e7e4e4] rounded-lg dark:text-white dark:bg-darkBg dark:border-none"
                      placeholder="Search"
                    />
                    <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2 " />
                  </div>

                  {question?.response_options
                    ?.filter(
                      (item) =>
                        item
                          ?.toLowerCase()
                          ?.indexOf(searchDropdown?.toLowerCase()) > -1
                    )
                    ?.map((answer, index) => (
                      <div key={index} className="w-full relative">
                        <input
                          value={answer}
                          onChange={(e) => {
                            handleResponseOptionChange(e.target.value, index);
                          }}
                          className={`flex items-center px-5 py-2 border rounded-lg w-full text-[#4A4A4A]  font-pro text-base border-[#c2c2c2] focus:border-secondary focus:appearance-none focus:ring-1 focus:ring-secondary dark:text-white dark:bg-darkBg dark:border-none`}
                        />
                        <CircleX
                          className="w-4 h-4 absolute top-1.5 right-2 cursor-pointer"
                          onClick={() => handleResponseOptionDelete(index)}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
          <div
            className="text-black/70 flex justify-center items-center cursor-pointer"
            onClick={handleAddOption}
          >
            <CirclePlus
              className="w-9 h-9 text-[#6e6e6e] dark:text-white"
              strokeWidth="1.5"
            />
          </div>
        </div>
      ) : (
        <div className="py-2 grid grid-cols-2 lg:flex lg:flex-wrap gap-[19px] add-responses">
          {question?.response_options?.map((answer, index) => (
            <div className="relative w-full lg:w-auto" key={index}>
              <span
                className={`w-5 h-5 border rounded-full mr-2 bg-white dark:bg-darkLightBg border-[#c2c2c2] absolute top-5 left-3`}
              ></span>
              <input
                value={answer}
                onChange={(e) => {
                  handleResponseOptionChange(e.target.value, index);
                }}
                className={`flex items-center px-10 py-4 border rounded-lg w-full lg:w-auto text-[#4A4A4A]  font-pro text-base border-[#c2c2c2] focus:border-secondary focus:appearance-none focus:ring-1 focus:ring-secondary dark:bg-darkLightBg dark:text-white`}
              />
              <CircleX
                className="w-4 h-4 absolute top-1.5 right-2 cursor-pointer dark:text-white"
                onClick={() => handleResponseOptionDelete(index)}
              />
            </div>
          ))}
          <div
            className="text-black/70 dark:text-white flex justify-center items-center cursor-pointer"
            onClick={handleAddOption}
          >
            <CirclePlus className="w-9 h-9 text-[#6e6e6e]" strokeWidth="1.5" />
          </div>
        </div>
      )}

      <div className="mt-5 mb-5 lg:mb-10 flex items-center gap-5 flex-wrap bulk-responses">
        <input
          type="file"
          hidden
          ref={inputRef}
          onChange={handleFileChange}
          accept=".txt"
        />
        <Tooltip
          content="Only accepts .txt file"
          theme={{
            target: "w-fit",
            arrow: {
              base: "absolute z-10 h-2 w-2 rotate-45",
              style: {
                dark: "bg-gray-900 dark:bg-gray-700",
                light: "bg-white",
                auto: "bg-white dark:bg-gray-700",
              },
              placement: "-4px",
            },
          }}
        >
          <button
            className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-center py-3 px-3 bg-newPrimary hover:bg-white hover:text-newPrimary transition-all duration-200 dark:hover:bg-darkLightBg"
            onClick={() => inputRef?.current?.click()}
          >
            Import bulk responses
          </button>
        </Tooltip>
        <p className="text-[#888]">(Line seperated responses)</p>
      </div>

      <div className="display-responses">
        <div className="flex items-center gap-x-3 my-5 dark:text-white ">
          <p>Show response options as:</p>
          <div className="flex items-center gap-x-3">
            <div className="flex items-center gap-x-3">
              <input
                type="radio"
                name="show-as"
                id="chips"
                checked={question?.show_as === "CHIPS"}
                onClick={() =>
                  setQuestion((prev) => {
                    let current = { ...prev };
                    current.show_as = "CHIPS";
                    return current;
                  })
                }
                className="cursor-pointer checked:bg-newPrimary"
                readOnly
              />
              <label htmlFor="chips" className="cursor-pointer">
                Chips
              </label>
            </div>
            <div className="flex items-center gap-x-3">
              <input
                type="radio"
                name="show-as"
                id="dropdown"
                checked={question?.show_as === "DROPDOWN"}
                className="cursor-pointer checked:bg-newPrimary"
                onClick={() =>
                  setQuestion((prev) => {
                    let current = { ...prev };
                    current.show_as = "DROPDOWN";
                    return current;
                  })
                }
                readOnly
              />
              <label htmlFor="dropdown" className="cursor-pointer">
                Dropdown
              </label>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-x-3 my-5 dark:text-white">
          <label htmlFor="responses">Multiple Responses:</label>
          <Switch
            checked={question?.has_multiple_responses}
            onChange={() =>
              setQuestion((prev) => {
                let current = { ...prev };
                if (current.has_multiple_responses) {
                  current.has_multiple_responses = false;
                } else {
                  current.has_multiple_responses = true;
                }
                return current;
              })
            }
            className={`${question?.has_multiple_responses ? "bg-newPrimary" : "bg-[#8E8B8B]"} relative h-6 w-12  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
          >
            <span className="sr-only">Toggle Multiple Responses</span>
            <span
              aria-hidden="true"
              className={`${question?.has_multiple_responses ? "translate-x-6" : "translate-x-0"} pointer-events-none inline-block h-5 w-5  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default ResponseOptionsForModal;
