/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const RemainingSubscriptionWithoutMemo = () => {
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState();
  const [activeSubscription, setActiveSubscription] = useState(true);

  //   const leftDays = useMemo(() => {
  //     if (endDate) {
  //       return Math.round((endDate - new Date().getTime() / 1000) / (3600 * 24));
  //     }
  //   }, [endDate]);
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      // setUserInfo(parsedUser);
      setActiveSubscription(parsedUser.profile?.has_active_subscription);
    }
  }, [localStorage.getItem("user")]);

  const handleGoToPayment = () => {
    navigate("/dashboard/payment?savedCard=false");
  };

  return (
    <div className="flex flex-col items-center  justify-center mt-5 lg:mt-7/ rounded-[10px] min-h-[120px] mr-5 lg:mr-0">
      {!activeSubscription && (
        <p className="text-[#4A4A4A] dark:text-white text-center font-pro text-[15px]">
          You have no subscription.
        </p>
      )}
      {
        <button
          className="bg-newPrimary text-white font-pro text-[15px] rounded-[55px] px-[30px] py-[10px] mt-[20px] hover:text-newPrimary hover:bg-white transition-all duration-200 border border-newPrimary dark:bg-darkLightBg"
          onClick={handleGoToPayment}
        >
          {activeSubscription ? "View Plan" : "Buy Plan"}
        </button>
      }
    </div>
  );
};

{
  /* <h1 className="text-base text-[#3D3D3D] font-pro">
                    <span className="text-secondary text-center font-medium mb-[11px]">
                      {leftDays}
                    </span>
                    /{totalDuration} Days
                  </h1>
                  <p className="text-[#4A4A4A] text-center font-pro text-[15px]">
                    {leftDays < 3 && (
                      <span>
                        Your Plan will be expired <WordBreak /> In few Days
                      </span>
                    )}
                  </p> */
}

const RemainingSubscription = React.memo(RemainingSubscriptionWithoutMemo);

export default RemainingSubscription;
