/* eslint-disable react/prop-types */
import { useRef, useState } from "react";
import { LoadingButton } from "../../../components/loading/loading";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { toast } from "sonner";
import { usePostAddQuestionSet } from "../../../query/useEntity";
import { Asterisk, Info } from "lucide-react";

const QuestionSetDropdownForModal = ({
  selectedQuestionSets,
  setSelectedQuestionSets,
  setQuestion,
  questionSets,
}) => {
  const questionSetRef = useRef(null);
  const [openQuestionSetBox, setOpenQuestionSetBox] = useState(false);
  const [newQuestionSet, setNewQuestionSet] = useState("");
  const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
    usePostAddQuestionSet();
  const handleAddQuestionSet = () => {
    addQuestionSet(
      {
        name: newQuestionSet,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setNewQuestionSet("");
          setSelectedQuestionSets((prev) => [
            ...prev,
            { name: res?.Set?.name },
          ]);
          setQuestion((prev) => {
            const current = { ...prev };
            const currentSets = [...current.sets, { name: res?.Set?.name }];
            return { ...current, sets: currentSets };
          });
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  useOnClickOutside({ ref: questionSetRef, setIsOpen: setOpenQuestionSetBox });
  return (
    <div className="my-5 space-y-3 question-set">
      <div className="flex items-center gap-x-1.5">
        <p className="text-base lg:text-[20px] dark:text-white">Question Set</p>
        <Asterisk className="text-red-500 size-4" />
      </div>
      <div className="w-full flex items-center gap-x-5">
        <div className="relative max-w-md w-full" ref={questionSetRef}>
          <input
            autoFocus={false}
            value={
              newQuestionSet
                ? newQuestionSet
                : selectedQuestionSets?.length > 0
                  ? selectedQuestionSets?.map((item) => item?.name)?.join(", ")
                  : ""
            }
            onChange={(e) => setNewQuestionSet(e.target.value)}
            onClick={() => {
              setOpenQuestionSetBox((prev) => !prev);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddQuestionSet();
              }
            }}
            autoComplete={"off"}
            id="questionSet"
            placeholder="Select Sets or Add New Set"
            className={`border px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg ${selectedQuestionSets?.length === 0 ? "border-red-500" : "border-gray-300"}`}
          />
          {openQuestionSetBox && (
            <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white dark:bg-darkLightBg z-[60] overflow-y-auto scrollbar-thin shadow-xl border dark:border-none">
              <div
                className="py-2 hover:text-secondary cursor-pointer dark:text-white"
                onClick={(e) => {
                  e.preventDefault();
                  setQuestion((prev) => {
                    const current = { ...prev };
                    current.sets = [];
                    return current;
                  });
                  setSelectedQuestionSets([]);
                  setOpenQuestionSetBox(false);
                }}
              >
                {"- No selected question set -"}
              </div>
              {
                <>
                  {questionSets
                    ?.filter((item) => item?.is_default)
                    ?.map((item) => (
                      <div
                        key={item?.name}
                        className="flex items-center gap-x-3 py-2 dark:text-white"
                        onClick={(e) => {
                          e.preventDefault();

                          setQuestion((prev) => {
                            const current = { ...prev };
                            const currentSets = current.sets?.some(
                              (set) => set.name === item.name
                            )
                              ? current.sets.filter(
                                  (set) => set.name !== item.name
                                )
                              : [...current.sets, { name: item.name }];
                            return { ...current, sets: currentSets };
                          });

                          setSelectedQuestionSets((prev) => {
                            const currentSets = prev?.some(
                              (set) => set.name === item.name
                            )
                              ? prev.filter((set) => set.name !== item.name)
                              : [...prev, { name: item.name }];
                            return currentSets;
                          });
                        }}
                      >
                        <div
                          id={item?.name}
                          className={`w-4 h-4 border-2 rounded cursor-pointer ${
                            selectedQuestionSets?.some(
                              (set) => set?.name === item?.name
                            )
                              ? "bg-secondary border-secondary"
                              : "border-gray-300"
                          }`}
                        >
                          {selectedQuestionSets?.some(
                            (set) => set?.name === item?.name
                          ) && (
                            <div className="w-full h-full bg-secondary flex items-center justify-center">
                              <svg
                                className="w-4 h-4 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                            </div>
                          )}
                        </div>
                        <label
                          htmlFor={item?.name}
                          className="hover:text-secondary cursor-pointer"
                        >
                          {item?.name}
                        </label>
                      </div>
                    ))}
                  {questionSets
                    ?.filter((item) => !item?.is_default)
                    ?.map((item) => (
                      <div
                        key={item?.name}
                        className="flex items-center gap-x-3 py-2 dark:text-white"
                        onClick={(e) => {
                          e.preventDefault();

                          setQuestion((prev) => {
                            const current = { ...prev };
                            const currentSets = current?.sets?.some(
                              (set) => set.name === item.name
                            )
                              ? current?.sets.filter(
                                  (set) => set.name !== item.name
                                )
                              : [...current.sets, { name: item.name }];
                            return { ...current, sets: currentSets };
                          });

                          setSelectedQuestionSets((prev) => {
                            const currentSets = prev?.some(
                              (set) => set.name === item.name
                            )
                              ? prev?.filter((set) => set.name !== item.name)
                              : [...prev, { name: item.name }];
                            return currentSets;
                          });
                        }}
                      >
                        <div
                          id={item?.name}
                          className={`w-4 h-4 border-2 rounded cursor-pointer ${
                            selectedQuestionSets?.some(
                              (set) => set?.name === item?.name
                            )
                              ? "bg-secondary border-secondary"
                              : "border-gray-300"
                          }`}
                        >
                          {selectedQuestionSets?.some(
                            (set) => set?.name === item?.name
                          ) && (
                            <div className="w-full h-full bg-secondary flex items-center justify-center">
                              <svg
                                className="w-4 h-4 text-white"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                ></path>
                              </svg>
                            </div>
                          )}
                        </div>
                        <label
                          htmlFor={item?.name}
                          className="hover:text-secondary cursor-pointer"
                        >
                          {item?.name}
                        </label>
                      </div>
                    ))}
                </>
              }
            </div>
          )}
        </div>
        <button
          className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-2.5 px-4 bg-newPrimary"
          onClick={handleAddQuestionSet}
          disabled={selectedQuestionSets?.length > 0}
        >
          {addQuestionSetLoading ? <LoadingButton /> : "Add"}
        </button>
      </div>
      {selectedQuestionSets?.length === 0 && (
        <div className="flex items-center gap-x-1.5 text-xs text-red-500">
          <Info className="size-4" />
          Question set is required
        </div>
      )}
    </div>
  );
};

export default QuestionSetDropdownForModal;
