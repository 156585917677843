import { useEffect, useMemo, useState } from "react";
import ReviewCard from "./ReviewCard";

/* eslint-disable react/prop-types */
const CardStack = ({ review, index }) => {
  return (
    <div className="relative w-full flex flex-col justify-center items-center">
      <div className=" w-full h-full">
        <div
          key={index}
          className={`transform transition-all dark:bg-darkBg duration-500 bg-white border border-gray-300 dark:border-none rounded-lg shadow-md flex justify-center items-center cursor-pointer p-5 lg:p-9 `}
        >
          <ReviewCard review={review} />
        </div>
      </div>
    </div>
  );
};

export default CardStack;
