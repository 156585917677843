/* eslint-disable react/prop-types */
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Drawer } from "flowbite-react";
import {
  AlignJustify,
  Bell,
  Command,
  Copy,
  LogOut,
  MessageSquareText,
  Moon,
  QrCode,
  Rocket,
  Settings,
  Sparkles,
  SquarePen,
  Sun,
  CirclePlay,
  UsersRound,
} from "lucide-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { STATUS } from "react-joyride";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { useDarkMode } from "../../../context/DarkModeProvider";
import useWindowSize from "../../../hooks/useWindowSize";
import { useGetNotifications } from "../../../query/useAuthentication";
import { useGetMagicLink, useGetMagicQr } from "../../../query/useEmployees";
import { timeAgo } from "../../../utils/formatter";
import CustomTooltipForJoyride from "./CustomTooltipForJoyride";
import IconButton from "./IconButton";
import QrContainer from "./QrContainer";
import RemainingSubscription from "./RemainingSubscription";
import SideBar, { LinkComponent } from "./SideBar";
import Joyride from "react-joyride";

const Layout = ({ children, title }) => {
  const baseUrl = window.location.origin;
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const referer = params.get("referer");
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const dropdownRef = useRef(null);
  const notifactaionRef = useRef(null);
  const webSocketApiUrl = import.meta.env.VITE_WEBSOCKET_API_URL;
  const token = localStorage.getItem("token");
  const [magicLink, setMagicLink] = useState("");
  const [isOpenQrContainer, setIsOpenQrContainer] = useState(false);
  const { darkMode, setDarkMode } = useDarkMode();

  const joyRideSteps = [
    {
      icon: <Sparkles />,
      title: "AI Generated Review",
      content: "See the number of AI-generated reviews at a glance.",
      target: ".generated",
      disableBeacon: true,
    },
    {
      icon: <SquarePen />,
      title: "AI Review Redirected",
      content: "Track the number of AI-generated reviews posted by users.",
      target: ".redirected",
    },
    {
      icon: <MessageSquareText />,
      title: "Reviews",
      content:
        "View all generated AI reviews here. Easily download reviews for offline analysis.",
      target: ".reviews",
      placement: "right",
    },
    {
      icon: <Sparkles />,
      title: "Questions",
      content: "Track the number of AI-generated reviews posted by users.",
      target: ".questions",
      placement: "right",
    },
    {
      icon: <Command />,
      title: "SEO Keywords",
      content:
        "Add SEO keywords to ensure your reviews highlighted for better search visibility.",
      target: ".seo",
      placement: "right",
    },
    {
      icon: <Link />,
      title: "Magiclinks",
      content:
        "Enable and add MagicLinks to track the no. of reviews generated from each link.",
      target: ".magiclinks",
      placement: "right",
    },
    {
      icon: <UsersRound />,
      title: "Referral",
      content: "Copy your referral code and track who has used it.",
      target: ".referral",
      placement: "right",
    },
    {
      icon: <Settings />,
      title: "Settings",
      content:
        "Edit your profile details and integrate with different platforms.",
      target: ".settings",
      placement: "right",
      referrerFunc: function () {
        navigate("/dashboard/questions?referrer=verify-email");
      },
      referrer: "Go to question section",
    },
  ];

  const joyRideMobileSteps = [
    {
      icon: <Sparkles />,
      title: "AI Generated Review",
      content: "See the number of AI-generated reviews at a glance.",
      target: ".generated",
      disableBeacon: true,
    },
    {
      icon: <SquarePen />,
      title: "AI Review Redirected",
      content: "Track the number of AI-generated reviews posted by users.",
      target: ".redirected",
      referrerFunc: function () {
        navigate("/dashboard/questions?referrer=verify-email");
      },
      referrer: "Go to question section",
    },
  ];

  const [step, setStep] = useState({
    run: false,
    steps: isMobile ? joyRideMobileSteps : joyRideSteps,
    stepIndex: 0,
  });

  const { refetch } = useGetNotifications({
    onSuccess: (res) => {
      setNotifications(res.pages.flatMap((page) => page.results));
    },
  });

  useGetMagicLink({
    onSuccess: (res) => {
      setMagicLink(res?.magic_link);
    },
  });

  const { data: qr } = useGetMagicQr();

  useEffect(() => {
    const socket = new WebSocket(
      `${webSocketApiUrl}/notification/?token=${token}`
    );

    socket.addEventListener("open", () => {});

    socket.addEventListener("message", (event) => {
      const newNoti = JSON.parse(event.data);
      setNotifications((prev) => [newNoti, ...prev]);
      setIsAlert(true);
      refetch();
    });

    socket.addEventListener("error", () => {});

    socket.addEventListener("close", () => {});

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUserInfo(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/sign-in";
  };

  const handleCopy = () => {
    if (magicLink) {
      navigator.clipboard.writeText(baseUrl + "/" + magicLink);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the Platform dropdown if clicked outside
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      // Close the Solution dropdown if clicked outside
      if (
        notifactaionRef.current &&
        !notifactaionRef.current.contains(event.target)
      ) {
        setIsNotificationsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (referer === "verify-email") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, [referer]);

  return (
    <div className="lg:container w-full min-h-[100vh] relative lg:flex !px-0 bg-[#f0f5ff] dark:bg-darkBg dark:text-white">
      <aside
        id="default-sidebar"
        className={`z-[60] w-full lg:max-w-[270px] h-screen transition-transform lg:bg-none hidden lg:block sticky top-0 left-0
        `}
        aria-label="Sidebar"
      >
        <div className="h-full pt-5 pb-5 lg:pt-8 lg:pb-6 pl-5 lg:pl-7 w-4/5 lg:w-full bg-white dark:bg-darkBg">
          {/* cancel mark */}
          <div className="overflow-y-auto scrollbar-thin h-full lg:pr-5 lg:scrollbar-none">
            <div className={`flex items-center max-w-[150px] -mt-3 `}>
              <img src="/magiclogo.svg" alt="" className="w-[55px]" />
              <p
                className={`text-[18px] -tracking-[3%] leading-[54px] dark:text-white text-[#434343]
                font-avertaBold text-center -mt-2`}
              >
                magicreview
              </p>
            </div>

            <SideBar />

            <div
              className="flex gap-[22px] items-center py-2.5 px-3 rounded-[7px] hover:bg-gray-200/70 dark:hover:text-secondary dark:hover:bg-darkLightBg text-[#3D3D3D] dark:text-[#979A9B] font-pro cursor-pointer mt-3 font-medium"
              onClick={() => {
                navigate("/dashboard?referer=verify-email");
              }}
            >
              <CirclePlay className="" />
              <p>Getting Started</p>
            </div>

            <RemainingSubscription />
          </div>
        </div>
      </aside>
      <div className={`lg:w-[80%] lg:ml-5`}>
        <div className="sticky top-0 w-full z-50 pt-5 pb-5 lg:pt-9 lg:pb-4 lg:pr-4 space-y-3 shadow-md lg:shadow-none bg-[#f0f5ff] dark:bg-darkBg">
          <header className="flex justify-between w-full gap-4 items-center flex-wrap px-5 lg:px-0">
            <div className="flex items-center gap-x-3">
              <AlignJustify
                className="w-8 h-8 lg:hidden mobile-menu"
                onClick={() => setIsDrawerOpen(true)}
              />

              <h1 className="text-[#333] dark:text-white text-[19px] md:text-[24px] 3xl:text-4xl font-semibold font-poppins">
                {title ? title : "Overview"}
              </h1>
            </div>

            <div className="items-center gap-3 ml-auto flex">
              <button
                onClick={() => {
                  setDarkMode((prev) => !prev);
                  localStorage.setItem("darkMode", !darkMode);
                }}
                className="size-9 lg:flex items-center justify-center bg-lightpurple dark:bg-darkLightBg rounded-md text-[#2a49b8] dark:text-white hidden"
              >
                {darkMode ? (
                  <Sun className="size-5" />
                ) : (
                  <Moon className="size-5" />
                )}
              </button>
              <div className="relative lg:w-[150px] font-montserrat text-sm  text-[#2a49b8] hidden lg:block">
                <input
                  type="text"
                  placeholder="MagicReview Link"
                  className="w-full rounded-[8px] border border-[#f6f6ff] bg-[#f6f6ff]  text-sm placeholder:text-sm pl-3 lg:pr-5 py-2"
                  disabled
                  value={"Magic Link"}
                />
                <QrCode
                  className="absolute top-1/2 -translate-y-1/2 right-9 cursor-pointer w-4 h-4"
                  onClick={() => setIsOpenQrContainer(true)}
                />
                <Copy
                  className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-4 h-4"
                  onClick={handleCopy}
                />
              </div>
              {/* notification dropdown  */}
              <Menu as="div" className="relative" ref={notifactaionRef}>
                {isAlert && (
                  <div className="w-1.5 h-1.5 rounded-full bg-red-600 absolute right-0 top-0 z-20"></div>
                )}
                <Menu.Button
                  className="text-[#888] dark:text-white flex items-center justify-center"
                  onClick={() => {
                    setIsNotificationsOpen(!isNotificationsOpen);
                    setIsAlert(false);
                  }}
                >
                  <IconButton>
                    <Bell />
                  </IconButton>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-10 mt-[24px] w-[280px] xxs:w-[345px] md:w-[373px]  rounded-[12px] shadow-lg bg-white dark:bg-darkLightBg ring-1 ring-black ring-opacity-5 focus:outline-none -ml-[210px] border border-[#EBE2E2] p-0 dark:border-none">
                    <div className="flex flex-col w-full py-5">
                      {/* These are the dropdown links, add or change as needed */}
                      <div className="flex justify-between items-center w-full px-7 pb-3">
                        <p className="font-pro text-[20px] font-medium">
                          Your Notifications
                        </p>
                      </div>
                      {notifications?.length > 0 ? (
                        <div className="divide-y overflow-x-hidden overflow-y-auto scrollbar-thin max-h-[230px] px-7">
                          {notifications?.map((noti) => (
                            <div
                              key={noti.message}
                              className="py-3 flex items-center gap-x-3"
                            >
                              <img
                                src="/new-logo.svg"
                                alt=""
                                className="w-10 h-10 rounded-full flex-shrink-0"
                              />

                              <div>
                                <p className="font-opensans text-[15px]">
                                  {noti.message}
                                </p>
                                <p className="text-sm text-[#838383]">
                                  {noti.timestamp && timeAgo(noti.timestamp)}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-center py-5 lg:py-10">
                          You have no notification!
                        </p>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Profile dropdown */}

              <Menu as="div" className="relative" ref={dropdownRef}>
                <Menu.Button
                  className="flex items-center text-[#888] dark:text-white"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <img
                    className="h-8 w-8 rounded-full lg:mr-[9px] mr-[3px]"
                    src={
                      userInfo?.profile?.profile_picture
                        ? userInfo?.profile?.profile_picture
                        : "/new-logo.svg"
                    }
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                  <p className="w-[30px] md:w-auto line-clamp-1">
                    {userInfo?.first_name}
                  </p>
                  <ChevronDownIcon
                    className={`ml-[9px] h-5 w-5 transform transition-transform ${
                      isDropdownOpen ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-10 mt-[24px] w-[280px] xxs:w-[300px] md:w-[300px]  rounded-[12px] shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none -ml-[190px] lg:-ml-[190px] border border-[#EBE2E2] p-0 dark:bg-darkLightBg dark:border-none">
                    <div className="flex flex-col w-full py-3">
                      {/* These are the dropdown links, add or change as needed */}

                      <div className="flex items-center border-b border-[#E9E9E9] w-full px-7 pb-3">
                        <img
                          className="h-8 w-8 rounded-full mr-[10px]"
                          src={
                            userInfo?.profile?.profile_picture ||
                            "/new-logo.svg"
                          }
                          alt=""
                        />
                        <div className="w-[200px]">
                          <h1 className="font-pro text-[20px] break-words">
                            {userInfo?.first_name} {userInfo?.last_name}
                          </h1>
                          <p className="text-[#888] text-base break-words">
                            {userInfo?.email}
                          </p>
                        </div>
                      </div>

                      {/* <Link
                        to="/dashboard/account"
                        className="flex items-center w-full px-7 gap-3 pb-3 mt-[14px] hover:text-secondary transition-colors text-[#3D3D3D]"
                      >
                        <User />
                        <p className=" text-base font-pro ">Account</p>
                      </Link> */}

                      <div
                        className="flex items-center w-full px-7 gap-3 pb-3 mt-[14px] cursor-pointer hover:text-secondary transition-colors text-[#3D3D3D] dark:text-white dark:hover:text-secondary"
                        onClick={logout}
                      >
                        <LogOut />
                        <p className=" text-base font-pro">Logout</p>
                      </div>

                      {/* ... more items here ... */}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </header>
          <div className="relative w-full font-montserrat text-sm hidden">
            <input
              type="text"
              placeholder="MagicReview Link"
              className="w-full rounded-[8px] border border-secondary bg-secondary text-white placeholder:text-sm pl-3 lg:pr-5 py-3"
              disabled
              value={"Your MagicReview Link"}
            />
            <Copy
              className="absolute top-1/2 -translate-y-1/2 right-3 text-white cursor-pointer"
              onClick={handleCopy}
            />
          </div>
        </div>

        <div className="px-2.5 lg:px-0">{children}</div>
      </div>

      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        theme={{
          root: {
            base: "fixed z-[60] overflow-y-auto bg-white dark:bg-darkLightBg p-4 transition-transform",
            backdrop: "fixed inset-0 z-50 bg-gray-900/50 dark:bg-gray-900/80",
            position: {
              left: {
                on: "left-0 top-0 h-screen w-80 transform-none",
                off: "left-0 top-0 h-screen w-80 -translate-x-full",
              },
            },
          },
          header: {
            inner: {
              closeButton:
                "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
              closeIcon: "h-4 w-4",
              titleText:
                "mb-4 inline-flex items-center text-base font-medium !font-pro lg:text-lg",
            },
          },
        }}
        // className="lg:w-[350px]"
      >
        <Drawer.Header title="" titleIcon={() => <></>}></Drawer.Header>
        <Drawer.Items>
          <div className="h-full w-[300px] bg-white dark:bg-darkLightBg">
            <div className="overflow-y-auto scrollbar-thin h-full lg:pr-5 lg:scrollbar-track-[#f1f1f1] lg:scrollbar-thumb-[#888]">
              <div className={`flex items-center max-w-[150px] -mt-3 `}>
                <img src="/magiclogo.svg" alt="" className="w-[55px]" />
                <p
                  className={`text-[18px] -tracking-[3%] leading-[54px] dark:text-white text-[#434343]
                font-avertaBold text-center -mt-2`}
                >
                  magicreview
                </p>
              </div>
              <SideBar isDrawerOpen={isDrawerOpen} />
              <div
                className="flex gap-[22px] items-center py-2.5 px-3 rounded-[7px] hover:bg-gray-200/70 dark:hover:text-secondary dark:hover:bg-darkLightBg text-[#3D3D3D] dark:text-[#979A9B] font-pro cursor-pointer mt-3 font-medium"
                onClick={() => {
                  setIsDrawerOpen(false);
                  navigate("/dashboard?referer=verify-email");
                }}
              >
                <CirclePlay className="w-5 h-5" />
                <p>Getting Started</p>
              </div>
              <div className=" mr-5 mt-7">
                <button
                  onClick={() => {
                    setDarkMode((prev) => !prev);
                    localStorage.setItem("darkMode", !darkMode);
                  }}
                  className="flex gap-[22px] items-center py-3 px-3 rounded-[7px] bg-lightpurple dark:bg-darkBg text-[#2a49b8] dark:text-white lg:hidden w-full"
                >
                  {darkMode ? (
                    <>
                      <Sun className="" />
                      <p>Light mode</p>
                    </>
                  ) : (
                    <>
                      <Moon className="" />
                      <p>Dark mode</p>
                    </>
                  )}
                </button>
              </div>
              <div className="relative font-montserrat text-sm lg:hidden text-[#2a49b8] mr-5 mt-7">
                <input
                  type="text"
                  placeholder="MagicReview Link"
                  className="w-full rounded-[8px] border border-[#f6f6ff] bg-[#f6f6ff]  text-sm placeholder:text-sm pl-5 lg:pr-5 py-3"
                  disabled
                  value={"Magic Link"}
                  readOnly
                />
                <QrCode
                  className="absolute top-1/2 -translate-y-1/2 right-9 cursor-pointer w-4 h-4"
                  onClick={() => setIsOpenQrContainer(true)}
                />
                <Copy
                  className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-4 h-4"
                  onClick={handleCopy}
                />
              </div>

              <RemainingSubscription />
            </div>

            {/* <div className="flex items-center justify-between mt-5 lg:mt-7 bg-[#F6F6FF] rounded-[10px] p-5 mr-5 lg:mr-0 w-full">
            <div className="flex items-center gap-x-2">
              <img
                className="h-8 w-8 rounded-full mr-[9px]"
                src={
                  userInfo?.profile?.profile_picture
                    ? userInfo?.profile?.profile_picture
                    : "/new-logo.svg"
                }
                alt=""
                referrerPolicy="no-referrer"
              />
              <div className="w-[120px] break-words">
                <p className="font-pro font-medium">{userInfo?.first_name}</p>
                <p className="font-pro text-xs text-[#888] ">
                  {userInfo?.email}
                </p>
              </div>
            </div>
            <LogOut
              onClick={logout}
              className="cursor-pointer text-[#3d3d3d]"
            />
          </div> */}
          </div>
        </Drawer.Items>
      </Drawer>

      {isOpenQrContainer && (
        <QrContainer
          openEditModal={isOpenQrContainer}
          setOpenEditModal={setIsOpenQrContainer}
          qr={qr}
        />
      )}

      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
    </div>
  );
};

export default Layout;
