import Questions from "../../onboarding/components/questions";
import Layout from "../component/Layout";

const DashboardQuestions = () => {
  return (
    <Layout title={"Questions"}>
      <Questions />
    </Layout>
  );
};

export default DashboardQuestions;
