/* eslint-disable react/prop-types */
import { motion } from "framer-motion";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";
import IconDownload from "../../assets/icons/IconDownload";
import IconPlayOne from "../../assets/icons/IconPlayOne";

const ContactPerson = () => {
  const { pathname } = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsappNm, setWhatsappNm] = useState("");

  const CardData = [
    {
      img: "/pitchdeck.png",
      title: "Document",
      desc: <span>Explore Our Pitch Deck</span>,
    },
    {
      img: "/demo.png",
      vid: "https://www.youtube.com/watch?v=V-ZplHf441E",
      title: "Video",
      desc: <span>Watch MagicReview Demo</span>,
    },
  ];

  return (
    <section className="relative bg-[#4F75FA]">
      <div className="container py-6 pb-8  font-poppins space-y-4">
        <div className="flex justify-center gap-2 items-center">
          <img
            src={"/new-logo-with-sidetext-white.svg"}
            height=""
            width=""
            alt="logo"
            className="scale-125"
          />
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          className={`bg-[#4F75FA] mx-auto rounded-3xl flex justify-center relative`}
        >
          <img
            src={pathname.includes("saheen") ? "/shaheen.png" : "/sameeh.png"}
            className="w-full h-[80%]"
            height=""
            width=""
            alt="userimg"
          />
          <Link
            href={"#"}
            className="absolute z-50 shadow-cardOne bg-gradient-to-b from-[#4F75FA] to-[#3461FD]  rounded-full w-[78px] h-[78px] flex justify-center items-center bottom-0 right-7 translate-y-1/2 active:scale-95 transition-all duration-300"
          >
            <img
              src={"/BookingIcon.svg"}
              className=""
              height={40}
              width={40}
              alt="book icon"
            />
          </Link>
        </motion.div>
        <motion.h2
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.1, ease: "easeInOut" }}
          className="text-white font-bold text-[40px] font-poppins -tracking-[2px] leading-[45px] mt-1"
        >
          Hi There! <br /> I&apos;m{" "}
          {pathname.includes("saheen") ? "Shaheen" : "Sameeh"}
        </motion.h2>
        <motion.p
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, delay: 0.2, ease: "easeInOut" }}
          className="text-white font-medium text-[22px] font-poppins"
        >
          {"Business Development"}
        </motion.p>
        {/* <motion.div
          className="flex gap-3 items-center mb-4 "
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, delay: 0.3, ease: "easeInOut" }}
        >
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href={data?.whatsapp}
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={Social2}
              width={27}
              height={27}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href={data?.instagram}
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={Social1}
              width={27}
              height={27}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href={data?.linkedIn}
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={Social3}
              width={30}
              height={27}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href={data?.twitter}
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={Social4}
              width={27}
              height={27}
              alt="Social Media"
            />
          </motion.a>
        </motion.div> */}
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, delay: 0.4, ease: "easeInOut" }}
          className="space-y-5 pt-6"
        >
          <Link
            href={"#"}
            className="bg-white block py-3.5 rounded-[6px] text-center hover:bg-[#4F75FA] hover:text-white hover:border hover:border-white text-[#4F75FA] font-semibold h-[55px] font-poppins text-base"
          >
            Get V Card
          </Link>
          {/* <Link
            href="/chooseproduct"
            className="block py-3.5 rounded-[6px] text-center bg-primary hover:bg-white text-white border border-white hover:text-primary font-semibold h-[55px] font-montserrat text-base"
          >
            Choose Your Product
          </Link> */}
        </motion.div>
      </div>
      <div className="container py-10 ">
        <motion.div
          className="space-y-3"
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <motion.input
            // initial={{ opacity: 0, y: 30 }}
            // whileInView={{
            //   opacity: 1,
            //   y: 0,
            // }}
            // transition={{ duration: 0.5 }}
            className="w-full font-poppins bg-[#f9f9fb] py-3 px-4 rounded-[4px] outline-primary border border-secondary h-[58px] text-[#595959] placeholder:text-[#595959]"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <motion.input
            className="w-full font-poppins bg-[#f9f9fb] py-3 px-4 rounded-[4px] outline-primary border border-secondary h-[58px] text-[#595959] placeholder:text-[#595959]"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PhoneInput
            className="w-full font-poppins bg-[#f9f9fb] py-3 rounded-[4px] outline-primary border border-secondary h-[58px] text-[#595959] placeholder:text-[#595959]"
            inputStyle={{
              width: "100%",
              height: "100%",
              backgroundColor: "#f9f9fb",
              border: "none",
            }}
            placeholder="Whatsapp Number"
            value={whatsappNm}
            country="us"
            onChange={(value) => setWhatsappNm(value)}
          />
          <motion.select
            className="w-full font-poppins bg-[#f9f9fb] py-3 px-4 rounded-[4px] outline-primary border border-secondary h-[58px] text-[#595959] placeholder:text-[#595959]"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          >
            <option value="">Select Category</option>
          </motion.select>
        </motion.div>
        <motion.button
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, delay: 0.1, ease: "easeInOut" }}
          className="block py-3.5 w-full rounded-[6px] mt-6 text-center hover:bg-white bg-[#7190FF] text-white border border-[#7190FF] hover:text-[#7190FF] font-semibold h-[55px] font-poppins text-base disabled:cursor-not-allowed"
          //   onClick={handleSendIntro}
          disabled={!(name && email)}
        >
          {/* {isPending ? <div className="loader"></div> : "Get Details"} */}
          Send Details
        </motion.button>
      </div>
      <div className="container pb-8 font-poppins">
        <motion.p
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="font-semibold text-[24px] text-center mb-6 text-white"
        >
          Explore Magicreview
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <PostsContainer CardData={CardData} />
        </div>
      </div>
      <div className="flex font-montserrat flex-col items-center space-y-3 py-6">
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <img loading="lazy"src={"/new-logo.svg"} height={88} width={88} alt="logo" />
        </motion.div>
        <motion.h1
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="font-avertaBold text-[44px] leading-[55px] text-white"
        >
          magicreview
        </motion.h1>
        <motion.p
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
          className="font-montserrat text-base text-white"
        >
          Review Made Easy!
        </motion.p>
        <motion.div
          className="flex gap-3 items-center mb-4 "
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{
            once: true,
          }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <motion.a
            // initial={{ opacity: 0, x: -20 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href="https://www.magicreview.ai"
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={"/webSvg.svg"}
              width={40}
              height={40}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href="https://www.linkedin.com/company/magicreviewai/"
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={"/LinkedIn.svg"}
              width={40}
              height={40}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href="https://www.instagram.com/magicreview.ai?igsh=MW9pMXpsZmd3N2h5dA=="
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={"/Instagram.svg"}
              width={40}
              height={40}
              alt="Social Media"
            />
          </motion.a>
          <motion.a
            // initial={{ opacity: 0, x: -50 }}
            // whileInView={{ x: 0, opacity: 1 }}
            // transition={{ duration: 0.5, delay: 0.3 }}
            href="https://twitter.com/MagicReviewAi"
            target="_blank"
          >
            <img
              className="cursor-pointer"
              src={"/Twitter.svg"}
              width={40}
              height={40}
              alt="Social Media"
            />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

const PostsContainer = ({ CardData }) => {
  return CardData?.map((item, i) => {
    return (
      <div key={i}>
        <HOCPost item={item} />
      </div>
    );
  });
};

const HOCPost = ({ item }) => {
  const [playVid, setPlayVid] = useState(false);

  return item.href ? (
    <Link href={item.href} key={item.href}>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{
          once: true,
        }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        className={`bg-[#4F75FA] p-6 group rounded-[13px]`}
      >
        <div className="w-full overflow-hidden rounded-sm">
          {item.img && item.vid ? (
            playVid ? (
              <video controls className="w-full h-[250px]">
                <source src={item.vid} />
              </video>
            ) : (
              <img
                onClick={() => setPlayVid(true)}
                src={item?.img}
                className="w-full group-hover:scale-110 transition-all duration-300 h-full max-h-[15rem]"
                height=""
                width=""
                alt="img"
              />
            )
          ) : item.img ? (
            <img
              src={item?.img}
              className="w-full group-hover:scale-110 transition-all duration-300 h-full max-h-[15rem]"
              height=""
              width=""
              alt="img"
            />
          ) : item.iframe ? (
            <iframe
              className="w-full max-h-[15rem]"
              height={500}
              src={item.iframe}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ) : (
            <video controls className="w-full h-[250px]">
              <source src={item.vid} />
            </video>
          )}
        </div>

        <p className="text-base leading-[38px] mt-1.5">{item?.title}</p>
        {item.href ? (
          <p className="font-semibold text-lg flex items-center justify-between leading-[26px] -tracking-[1px]">
            {item?.desc}
            {item?.title != "Video" && (
              //   <FaArrowRight className="text-[#c6c6c7] group-hover:relative group-hover:ml-4" />
              <IconDownload />
            )}
          </p>
        ) : (
          <p className="font-semibold text-lg flex items-center justify-between leading-[26px] -tracking-[1px]">
            {item?.desc}
            {item?.title != "Video" && (
              //   <FaArrowRight className="text-[#c6c6c7] group-hover:relative group-hover:ml-4" />
              <IconDownload />
            )}
          </p>
        )}
      </motion.div>
    </Link>
  ) : (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{
        once: true,
      }}
      transition={{ duration: 0.4, ease: "easeInOut" }}
      className={`bg-white p-6 group rounded-[13px] max-w-[450px]`}
    >
      <div className="w-full overflow-hidden rounded-sm">
        {item.img && item.vid ? (
          playVid ? (
            <video controls autoPlay className="w-full h-[250px]">
              <source src={item.vid} />
            </video>
          ) : (
            <img
              onClick={() => setPlayVid(true)}
              src={item?.img}
              className={`w-full ${item.vid && "cursor-pointer"} group-hover:scale-110 transition-all duration-300 h-full max-h-[15rem]`}
              height=""
              width=""
              alt="img"
            />
          )
        ) : item.img ? (
          <img
            src={item?.img}
            className="w-full group-hover:scale-110 transition-all duration-300 h-full max-h-[15rem]"
            height=""
            width=""
            alt="img"
          />
        ) : item.iframe ? (
          <iframe
            className="w-full max-h-[15rem]"
            height={500}
            src={item.iframe}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <video controls className="w-full h-[250px]">
            <source src={item.vid} />
          </video>
        )}
      </div>

      <p className="text-base leading-[38px] mt-1.5">{item?.title}</p>
      {item.href ? (
        <p className="font-semibold text-lg flex items-center justify-between leading-[26px] -tracking-[1px]">
          {item?.desc}
          {item?.title != "Video" && (
            // <FaArrowRight className="text-[#c6c6c7] group-hover:relative group-hover:ml-4" />
            <IconDownload />
          )}
        </p>
      ) : (
        <p className="font-semibold text-lg flex items-center justify-between leading-[26px] -tracking-[1px]">
          {item?.desc}
          {item?.title != "Video" ? (
            // <FaArrowRight className="text-[#c6c6c7] group-hover:relative group-hover:ml-4" />
            <IconDownload />
          ) : (
            <IconPlayOne />
          )}
        </p>
      )}
    </motion.div>
  );
};

export default ContactPerson;
