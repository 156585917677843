import Apimanager from "./axios";

export const getEmployeesListApi = async () => {
  const res = await Apimanager.get(`/entity/employees`);
  return res.data;
};

export const postAddEmployeeApi = async (data) => {
  const res = await Apimanager.post(`/entity/employees`, data);
  return res.data;
};

export const putUpdateEmployeeApi = async (data) => {
  const res = await Apimanager.put(`/entity/employees/${data.id}`, {
    name: data.name,
    tags: data.tags,
    label: data.label,
    default_set: data.default_set,
    show: data.show,
    voice_review: data.voice_review,
  });
  return res.data;
};

export const deleteEmployeeApi = async (data) => {
  const res = await Apimanager.delete(`/entity/employees/${data}`);
  return res.data;
};

export const postEmployeeNameApi = async (data) => {
  const res = await Apimanager.post(`/entity/get_review_staff`, data);

  return res.data;
};

export const getEmployeeNameApi = async (data) => {
  const res = await Apimanager.get(`/entity/get_review_staff`, {
    params: data,
  });

  return res.data;
};

export const postEnableEmployeeApi = async (id) => {
  const res = await Apimanager.post(
    `/entity/employees/${id}/toggle_employee_is_enable`
  );

  return res.data;
};

export const getMagicLink = async () => {
  const res = await Apimanager.get("/entity/get_magic_link");
  return res.data;
};

export const postResetMagicLink = async () => {
  const res = await Apimanager.post("/entity/reset_magic_link");
  return res.data;
};

export const postDefaultMagicLink = async (id) => {
  const res = await Apimanager.post(`/entity/${id}/set_magic_link`);
  return res.data;
};

export const getActiveDefaultMagicLink = async () => {
  const res = await Apimanager.get(`/entity/get_active_default_employee`);
  return res.data;
};

export const getDefaultQuestionSets = async () => {
  const res = await Apimanager.get(`/entity/get_set_default_question_sets`);
  return res.data;
};

export const postAddDefaultQuestionSets = async (data) => {
  const res = await Apimanager.post(
    `/entity/get_set_default_question_sets`,
    data
  );
  return res.data;
};

export const getMagicQr = async (params) => {
  const res = await Apimanager.get(
    `/entity/get_qrcode${params ? "/" + params : ""}`,
    {
      responseType: "blob",
    }
  );
  return res.data;
};
