import { useState } from "react";
import { ChevronDown } from "lucide-react";

export default function ReviewsDropdown({ className, selectedOption, setSelectedOption }) {
const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <button
        className="px-6 py-3 h-full rounded-[8px] focus:outline-none flex items-center justify-between w-full bg-white dark:bg-darkBg cursor-pointer border border-[#EBEBEB] dark:border-darkBg gap-x-2"
        onClick={toggleDropdown}
      >
        <p className="text-[#777777] dark:text-white">{selectedOption}</p>
        <ChevronDown
          className={`w-5 h-5 transition-all duration-200 ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-full bg-white dark:bg-darkBg border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg z-20">
          <div
            className="px-4 py-2 hover:text-primary hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
            onClick={() => handleOptionClick("All Reviews")}
          >
            All Reviews
          </div>
          <div
            className="px-4 py-2 hover:text-primary hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
            onClick={() => handleOptionClick("Redirected Reviews")}
          >
            Redirected Reviews
          </div>

        </div>
      )}
    </div>
  );
}
