import { useEffect, useState } from "react";
import { toast } from "sonner";

import {
  useGetReviewPlatforms,
  usePostReviewQuestions,
  useReviewCopied,
} from "../../query/useReview";
import style from "./note.module.scss";
import { RefreshCcw } from "lucide-react";
import MainLogo from "../../components/mainlogo";
import Google from "../../assets/reviewservices/google.svg";
import { useSearchParams } from "react-router-dom";
import CopyModal from "./components/CopyModal";

const Note = () => {
  const [notes, setNotes] = useState([]);
  const [response, setResponse] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const { mutate: regenerateReviews } = usePostReviewQuestions({
    refetchOnMount: false,
  });
  const { mutate: copyReview, isSuccess: copyReviewSuccess } =
    useReviewCopied();

  const { data: reviewPlatforms } = useGetReviewPlatforms(reviewId, {
    enabled: Boolean(reviewId),
  });

  const [params] = useSearchParams();
  const employeeId = params.get("employee");
  const voiceReview = params.get("voicereview");
  const staffName =
    localStorage.getItem("staffName") && localStorage.getItem("staffName");

  useEffect(() => {
    setNotes(JSON.parse(localStorage.getItem("reviews")));
    setReviewId(localStorage.getItem("reviewId"));
    setResponse(JSON.parse(localStorage.getItem("response")));
  }, []);

  const handlePostFeedback = ({ id, review, reviewUrl }) => {
    navigator.clipboard.writeText(review);
    setIsOpenCopyModal(true);
    copyReview(
      {
        business_uuid: reviewId,
        review_uuid: id,
      },
      {
        onSuccess: (res) => {
          if (reviewUrl) {
            setTimeout(() => {
              window.location.href = reviewUrl;
            }, 4000);
          }
        },
        onError: (err) => {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        },
      }
    );
  };

  const handleRegenerate = () => {
    setLoading(true);
    setResponse(JSON.parse(localStorage.getItem("response")));
    regenerateReviews(
      {
        business_uuid: reviewId,
        response: response,
        employee_code:
          employeeId !== "null" && employeeId && staffName !== "undefined"
            ? employeeId
            : null,
        staff_name: staffName !== "undefined" && staffName ? staffName : null,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setNotes(data);
        },
        onError: (err) => {
          setLoading(false);
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  return (
    <div
      className={`relative bg-white max-w-screen-2xl mx-auto h-auto py-5 lg:py-10 ${style.Note}`}
    >
      <MainLogo className={"justify-center"} />

      {!voiceReview && (
        <div
          className="flex items-center justify-center gap-1 cursor-pointer hover:text-secondary text-[#353535] focus:shadow-outline w-fit mx-auto mb-3 group"
          onClick={handleRegenerate}
        >
          <p>Regenerate new reviews</p>
          <RefreshCcw className="group-active:rotate-90 transition-all duration-200" />
        </div>
      )}

      {loading ? (
        <div className="w-full h-[calc(100vh-340px)] flex flex-col justify-center items-center gap-5">
          <img
            loading="lazy"
            src="/mr-logo.gif"
            alt="Loading Logo"
            className="w-20 h-20"
          />
          <p className="animate-pulse">Hold on while we get your reviews ...</p>
        </div>
      ) : (
        <div className="w-[90%] mx-auto maincont flex flex-row flex-wrap gap-5 justify-center mb-10">
          {notes
            ?.sort((a, b) => a.review.length - b.review.length)
            ?.map((note, id) => {
              return (
                <>
                  <div
                    className={`${style.comment} flex flex-col justify-between`}
                    key={id}
                  >
                    <p className="mb-3 select-none">{note?.review}</p>
                    <div className="flex justify-end">
                      {reviewPlatforms?.platforms?.platform === "google" && (
                        <div
                          className="cursor-pointer  focus:shadow-outline bg-white border border-secondary rounded-[7px] px-3 py-2 text-center flex items-center gap-x-3"
                          onClick={() =>
                            handlePostFeedback({
                              id: note?.uuid,
                              review: note?.review,
                              reviewUrl: reviewPlatforms?.platforms?.review_url,
                            })
                          }
                        >
                          <img src={Google} alt="" />
                          <p className="font-heebo text-secondary">
                            Copy & Post
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}

      {reviewPlatforms?.platforms?.review_url && (
        <div className="flex justify-center mb-5">
          <a
            href={reviewPlatforms?.platforms?.review_url}
            // target="_blank"
            rel="noreferrer"
            className={`text-secondary items-center justify-center cursor-pointer underline`}
          >
            Post your own reviews
          </a>
        </div>
      )}

      {isOpenCopyModal && (
        <CopyModal
          isOpenModal={isOpenCopyModal}
          setIsOpenmodal={setIsOpenCopyModal}
        />
      )}
    </div>
  );
};

export default Note;
