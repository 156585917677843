/* eslint-disable no-constant-condition */
import { toast } from "sonner";
import {
  useGetCancleSubsciption,
  useGetCheckCoupon,
  usePostToggleAutoRenew,
} from "../../../../query/usePayment";
import { useEffect, useRef, useState } from "react";
import { DeleteModal } from "../../component/modal";
import { useUser } from "../../../../query/useAuthentication";
import {
  useGetPlans,
  usePostSubscribe,
} from "../../../../query/useSubscription";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/bundle";
import { Modal } from "flowbite-react";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CircleX,
} from "lucide-react";
import Loading, { LoadingButton } from "../../../../components/loading/loading";
import { Switch } from "@headlessui/react";
import { dayFormatter } from "../../../../utils/formatter";

const Plan = () => {
  const [openModal, setOpenModal] = useState(false);
  const [planId, setPlanId] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState({});
  const [openCouponModal, setCouponModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [selectedChangePlan, setSelectedChangePlan] = useState("");

  const data = [
    "Instant tap to get AI generated reviews",
    "Real-Time Tracking of Customer Reviews",
    "Hassle-Free Review Collection under 30 seconds",
    "Faster Reviews Without Manual Typing",
  ];

  const { data: userInfo, refetch, isLoading: userInfoLoading } = useUser();
  const { data: plans } = useGetPlans();
  const { mutate: subscribe, isLoading: subscribeLoading } = usePostSubscribe();
  const { mutate: cancleSubscription, isLoading: cancleSubscriptionLoading } =
    useGetCancleSubsciption();
  const { mutate: toggleAutoRenew, isLoading: toggleLoading } =
    usePostToggleAutoRenew();

  const { isLoading: couponLoading } = useGetCheckCoupon(
    {
      coupon: coupon,
      plan_uuid: planId,
    },
    {
      onSuccess: (res) => {
        setCouponMessage(res);
      },
      onError: (err) => {
        setCouponMessage(err.response.data);
      },
    }
  );

  const handleSubscribe = () => {
    subscribe(
      {
        plan: planId,
        coupon: couponMessage?.status === "success" ? coupon : null,
      },
      {
        onSuccess: (res) => {
          toast.success("Redirecting to checkout url");
          window.location.href = res.url;
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Cannot redirect to checkout url!");
          }
        },
      }
    );
  };

  const handleOpenCouponModal = (id) => {
    setCouponModal(true);
    setPlanId(id);
  };

  const handleCancleSubscription = () => {
    setOpenModal(true);
  };

  const handleCancleRenewal = () => {
    cancleSubscription(
      {},
      {
        onSuccess: (res) => {
          refetch();
          toast.success("Auto-renewel has been cancelled.");
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }

          setOpenModal(false);
        },
      }
    );
  };

  const [isActive, setIsActive] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const toggle = () => {
    // setIsActive((prevState) => !prevState);
    if (isActive) {
      handleCancleSubscription();
    } else {
      toggleAutoRenew(
        {
          auto_renew: true,
        },
        {
          onSuccess: (res) => {
            refetch();
            toast.success("Auto-renewel has been activated.");
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  const handleOpenChangePlanModal = () => {
    setOpenChangePlanModal(true);
  };

  const cardPrice = (planCurrency) => {
    switch (planCurrency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  useEffect(() => {
    setIsActive(userInfo?.profile?.subscription_auto_renew);
  }, [userInfo?.profile?.subscription_auto_renew]);

  useEffect(() => {
    setSelectedChangePlan(userInfo?.profile?.current_plan?.uuid);
  }, [openChangePlanModal]);

  if (userInfoLoading) {
    return <Loading />;
  }

  return (
    <div className="my-5 w-full min-h-[calc(100vh-140px)] dark:bg-darkLightBg rounded-[16px] p-5 lg:p-8">
      <div className="flex items-center flex-col lg:flex-row gap-y-5 w-full">
        <p className="font-pro font-medium text-xl lg:w-[60%]">
          Subscription Plan:&nbsp;&nbsp;
          <span className="text-[#2A49B8]">
            {userInfo?.profile?.current_plan?.short_text ||
              "No active subscription"}
          </span>
        </p>
        {userInfo?.profile?.current_plan && (
          <div className="lg:w-[38%]">
            <button
              className="py-2.5 px-3 rounded-[7px] text-[#615e83] dark:text-secondary border border-[#DCDBE5] font-montserrat text-sm hover:underline dark:border-none dark:bg-darkBg"
              onClick={handleOpenChangePlanModal}
            >
              {false ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "View Plans"
              )}
            </button>
          </div>
        )}
      </div>
      <hr className="my-6" />
      {userInfo?.profile?.current_plan ? (
        <>
          <div className="flex items-center flex-col lg:flex-row gap-y-5">
            <div className="lg:w-[60%]">
              <p className="font-pro font-semibold lg:text-2xl ">
                {userInfo?.profile?.current_plan?.short_text} Plan:{" "}
                {userInfo?.profile?.current_plan?.price?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: userInfo?.profile?.current_plan?.currency,
                  }
                )}
              </p>
              <p className="font-pro lg:text-lg ">
                Your next payment is{" "}
                {userInfo?.profile?.current_plan?.price?.toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: userInfo?.profile?.current_plan?.currency,
                  }
                )}
                , to be charged on{" "}
                {userInfo?.profile?.subscription_expiration_date &&
                  dayFormatter(
                    userInfo?.profile?.subscription_expiration_date * 1000
                  )}
              </p>
            </div>

            <div className="lg:w-[38%]">
              <div className="max-w-[120px] flex flex-col justify-center items-center gap-y-2">
                <Switch
                  checked={isActive}
                  onChange={toggle}
                  className={`${isActive ? "bg-[#3183FF]" : "bg-[#8E8B8B]"}
              relative h-[30px] w-16  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                >
                  <span className="sr-only">Renew Subscription</span>
                  <span
                    aria-hidden="true"
                    className={`${isActive ? "translate-x-9" : "translate-x-0"}
                pointer-events-none inline-block h-6 w-6  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
                <p className="font-pro text-sm text-[#4f4f4f]">Auto Renew</p>
              </div>
            </div>
          </div>
          <div className="mt-7">
            {data.map((item, index) => (
              <div
                key={index}
                className={` flex font-pro text-[#4f4f4f] dark:text-white lg:items-center items-start text-start gap-[10px] text-sm w-full mb-2.5 pb-2`}
              >
                <Check className="w-[10px] h-[10px] text-[#2A49B8] dark:text-secondary" />
                {item}
              </div>
            ))}
          </div>
          {/* <hr className="my-6" />
          <div>
            <p className="font-pro font-medium text-xl">Payment Method:</p>
            <div className="mt-5">
              <div className="bg-secondary text-white py-3 px-5 lg:py-5 lg:px-8 flex items-center justify-between max-w-[450px] rounded-xl font-pro">
                <div>
                  <p className="text-xl font-semibold">Visa ending 4232</p>
                  <p>Expiry 07/2024</p>
                </div>
                <div>
                  <p className="text-sm">Primary Card</p>
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <section className="w-full relative font-poppins container text-[#333333] space-y-6 z-10 pb-10 lg:mt-10">
          <div className="bg-[#f6f6ff] dark:bg-darkBg rounded-[19px] max-w-[716px] w-full px-5 space-y-6 py-5 lg:py-[70px] lg:m-auto">
            <div className="lg:w-[80%] mx-auto flex flex-col justify-center items-center">
              <div className=" bg-white dark:bg-darkLightBg w-fit px-2 py-2 justify-center rounded-[16px] flex flex-wrap gap-1 md:gap-2 items-center text-xs sm:text-sm md:text-base lg:text-lg text-nowrap">
                {plans?.data?.map((item, id) => {
                  return (
                    <>
                      <span
                        key={id}
                        className={`rounded-[9px] flex justify-center items-center px-3 py-2 sm:py-3 dark:text-white ${selectedPlan == id && "bg-newPrimary text-white"} cursor-pointer transition-all w-fit text-center duration-500`}
                        onClick={() => setSelectedPlan(id)}
                      >
                        {item?.short_text}
                      </span>
                    </>
                  );
                })}
              </div>

              <div className="px-3 sm:px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[4px] sm:rounded-[18px] mt-4 sm:mt-[44px] font-poppins text-[12px] sm:text-base mb-[13px] sm:mb-[15px]">
                {plans?.data?.[selectedPlan]?.secondary_text}
              </div>

              <h2 className="text-[#333333] dark:text-white font-poppins text-[32px] sm:text-[40px] lg:text-[67px] font-extrabold text-center sm:mt-3">
                {(plans?.data?.[selectedPlan]?.discount !== 0
                  ? plans?.data?.[selectedPlan]?.price_after_discount
                  : plans?.data?.[selectedPlan]?.price
                )?.toLocaleString("en-US", {
                  style: "currency",
                  currency: plans?.data?.[selectedPlan]?.currency,
                })}
              </h2>
              {plans?.data?.[selectedPlan]?.discount !== 0 ? (
                plans?.data?.[selectedPlan]?.is_relative_discount_price ? (
                  <div className="flex gap-2 sm:gap-[31px] items-center font-poppins mb-[27px]">
                    <h2 className="text-base sm:text-[32px] text-[#333333] dark:text-white line-through">
                      {plans?.data?.[selectedPlan]?.price?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: plans?.data?.[selectedPlan]?.currency,
                        }
                      )}
                    </h2>
                    <button className="bg-[#73DF07] rounded-[3px] sm:rounded-[9px] px-2 sm:px-5 py-1 text-white text-[10px] sm:text-[20px] ">
                      {plans?.data?.[selectedPlan]?.discount}% OFF{" "}
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                    <h2 className="text-[32px] text-[#333333] line-through">
                      {plans?.data?.[selectedPlan]?.price?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: plans?.data?.[selectedPlan]?.currency,
                        }
                      )}
                    </h2>
                    <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-[20px] ">
                      {/* {plans?.data?.[selectedPlan]?.discount?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: plans?.data?.[selectedPlan]?.currency,
                        }
                      )}{" "} */}
                      {plans?.data?.[selectedPlan]?.discount_percent}% OFF{" "}
                    </button>
                  </div>
                )
              ) : null}
            </div>

            <div className="flex gap-x-3 justify-center items-center lg:text-lg text-xs">
              <p className="text-[#FF0000]">NOTE:</p>
              <p className="text-[#1F1E1E] dark:text-white font-medium">
                MagicReview Card:{" "}
                {cardPrice(plans?.data?.[selectedPlan]?.currency)}/ Card
              </p>
            </div>

            <div className="max-w-[476.79px] mx-auto w-full">
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`${index === data.length - 1 ? "border-none" : "border-b border-[#EAEAEA]"} flex font-poppins text-[#1F1E1E] dark:text-white lg:items-center items-start text-start gap-[13px] lg:text-lg text-xs w-full mb-3 pb-2`}
                >
                  <img
                    src="/check.svg"
                    alt="check"
                    className="w-[24px] h-[24px]"
                  />
                  {item}
                </div>
              ))}

              <button
                className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
                onClick={() =>
                  handleOpenCouponModal(plans?.data?.[selectedPlan]?.uuid)
                }
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </section>
      )}
      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleDelete={handleCancleRenewal}
        title={"Are you sure you want to cancel auto-renewal?"}
        deleteConfirmButtonText={"Yes"}
        deleteCancelButtonText={"No"}
        isLoading={cancleSubscriptionLoading}
      />

      {/* coupon modal */}
      <Modal
        dismissible
        show={openCouponModal}
        onClose={() => {
          setCouponModal(false);
          setPlanId("");
        }}
        className="z-[1000]"
        size={"sm"}
        theme={{
          content: {
            base: "relative w-full p-4 h-auto",
            inner: "dark:bg-darkBg bg-white rounded-lg",
          },
        }}
      >
        <Modal.Body className="h-auto">
          <header className="flex items-center">
            <h1 className="text-[20px] font-montserrat mb-[31px] dark:text-white">
              {"Do you have a coupon code?"}
            </h1>
          </header>
          <div className="space-y-5">
            {coupon && couponMessage?.status === "success" ? (
              <div className="flex flex-col justify-center items-center gap-y-3">
                <p className="text-[20px] font-semibold text-center">
                  {!couponMessage?.is_relative_discount &&
                    couponMessage?.currency}{" "}
                  {couponMessage?.discount}{" "}
                  {couponMessage?.is_relative_discount && "%"} Discount
                </p>
                <div className="flex justify-center items-center gap-x-2">
                  <CircleCheck className="text-[#73DF07] " />
                  <p>{couponMessage?.message}</p>
                </div>
              </div>
            ) : null}
            <div>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  className="w-full rounded-lg pr-5 dark:bg-darkLightBg dark:text-white"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                />
                {coupon && couponLoading && (
                  <div className="flex items-center justify-center absolute top-1/2 -translate-y-1/2 right-5">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
                  </div>
                )}
              </div>
              {coupon && couponMessage?.status === "error" && (
                <p className="text-red-500 text-[10px] mt-0.5">
                  *{couponMessage?.message}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <button
                className="text-center w-full bg-secondary text-white border border-secondary px-3 py-2 rounded-lg hover:text-secondary hover:bg-white transition-all duration-200 dark:hover:bg-darkLightBg"
                onClick={handleSubscribe}
              >
                {subscribeLoading ? <LoadingButton /> : "Continue subscription"}
              </button>
              <button
                className="text-center w-full hover:bg-secondary hover:text-white border border-secondary px-3 py-2 rounded-lg text-secondary bg-white dark:bg-darkLightBg transition-all duration-200"
                onClick={() => {
                  setCouponModal(false);
                  setPlanId("");
                }}
              >
                Cancel subscription
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* change plan modal */}
      <Modal
        popup
        dismissible
        show={openChangePlanModal}
        onClose={() => {
          setOpenChangePlanModal(false);
        }}
        className="z-[1000]"
        size={"4xl"}
        theme={{
          content: {
            base: "relative w-full p-4 h-[90vh] lg:h-auto",
            inner: "dark:bg-darkBg bg-white rounded-lg",
          },
          header: {
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
      >
        <Modal.Header>
          <div></div>
        </Modal.Header>
        <Modal.Body className="lg:pb-10 lg:px-10">
          <div className="flex flex-wrap justify-between">
            <div className="flex flex-col justify-between">
              <div>
                <p className="font-pro font-medium text-[22px] mb-10 dark:text-white">
                  Features
                </p>
                {data.map((item, index) => (
                  <div
                    key={index}
                    className={` flex font-pro text-[#4f4f4f] dark:text-white lg:items-center items-start text-start gap-[10px] text-sm w-full mb-2 pb-2`}
                  >
                    <Check className="w-[10px] h-[10px] text-[#2A49B8] dark:text-secondary" />
                    {item}
                  </div>
                ))}
              </div>
              {/* <div className="w-full hidden lg:flex items-center justify-between gap-x-3">
                <button className="text-center w-full bg-secondary text-white border border-secondary px-3 py-2.5 rounded-lg hover:text-secondary hover:bg-white transition-all duration-200">
                  Continue
                </button>
                <button
                  className="text-center w-full hover:bg-secondary hover:text-white border border-secondary px-3 py-2.5 rounded-lg text-secondary bg-white transition-all duration-200"
                  onClick={() => setOpenChangePlanModal(false)}
                >
                  Cancel
                </button>
              </div> */}
            </div>
            <div className="w-full lg:max-w-[365px]  space-y-5">
              {plans?.data?.map((item) => (
                <div
                  key={item?.uuid}
                  className={` p-5 flex justify-between items-center w-full h-[110px] rounded-xl  cursor-pointer ${item?.uuid === selectedChangePlan ? "bg-newPrimary text-white" : "bg-[#f6f6ff] dark:bg-darkLightBg dark:text-white"}`}
                  // onClick={() => setSelectedChangePlan(item?.uuid)}
                >
                  <div className="space-y-3">
                    <p className="font-semibold font-pro text-lg">
                      {item?.short_text}
                    </p>
                    <div className="bg-white dark:bg-darkBg px-2.5 py-1 rounded-md font-pro text-[#615e83] dark:text-secondary text-sm text-center">
                      {item?.secondary_text}
                    </div>
                  </div>
                  <div className="space-y-3">
                    <p className="font-pro text-2xl font-bold text-center sm:mt-3">
                      {(item?.discount !== 0
                        ? item?.price_after_discount
                        : item?.price
                      )?.toLocaleString("en-US", {
                        style: "currency",
                        currency: item?.currency,
                      })}
                    </p>
                    {item?.discount !== 0 ? (
                      item?.is_relative_discount_price ? (
                        <div className="flex gap-2 items-center font-poppins">
                          <div className="bg-[#73DF07] px-2 py-1 text-white text-[10px] rounded-[4px]">
                            {item?.discount}% OFF{" "}
                          </div>
                          <p className="text-base line-through">
                            {item?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: item?.currency,
                            })}
                          </p>
                        </div>
                      ) : (
                        <div className="flex gap-2 items-center font-poppins">
                          <button className="bg-[#73DF07] px-2 py-1 text-white text-[10px] rounded-[4px]">
                            {item?.discount_percent}% OFF{" "}
                          </button>
                          <h2 className="text-base line-through">
                            {item?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: item?.currency,
                            })}
                          </h2>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full lg:hidden space-y-3 mt-5">
              {/* <button className="text-center w-full bg-secondary text-white border border-secondary px-3 py-2.5 rounded-lg hover:text-secondary hover:bg-white transition-all duration-200">
                Continue
              </button> */}
              {/* <button
                className="text-center w-full hover:bg-secondary hover:text-white border border-secondary px-3 py-2.5 rounded-lg text-secondary bg-white transition-all duration-200"
                onClick={() => setOpenChangePlanModal(false)}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Plan;
