import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteMustSeoKeyword,
  deleteSeoKeyword,
  getMustSeoKeywords,
  getSeoKeywords,
  postAddMustSeoKeyword,
  postAddSeoKeyword,
} from "../api/keywordsApi";

export const useGetSeoKeywords = (config) => {
  return useQuery({
    queryKey: ["getSeoKeywords"],
    queryFn: () => getSeoKeywords(),
    ...config,
  });
};

export const usePostAddSeoKeyword = () => {
  return useMutation({
    mutationKey: ["postAddSeoKeyword"],
    mutationFn: (data) => postAddSeoKeyword(data),
  });
};

export const useDeleteSeoKeyword = () => {
  return useMutation({
    mutationKey: ["deleteSeoKeyword"],
    mutationFn: (data) => deleteSeoKeyword(data),
  });
};

export const useGetMustSeoKeywords = (config) => {
  return useQuery({
    queryKey: ["getMustSeoKeywords"],
    queryFn: () => getMustSeoKeywords(),
    ...config,
  });
};

export const usePostAddMustSeoKeyword = () => {
  return useMutation({
    mutationKey: ["postAddMustSeoKeyword"],
    mutationFn: (data) => postAddMustSeoKeyword(data),
  });
};

export const useDeleteMustSeoKeyword = () => {
  return useMutation({
    mutationKey: ["deleteMustSeoKeyword"],
    mutationFn: (data) => deleteMustSeoKeyword(data),
  });
};
