import Apimanager from "./axios";

export const contactApi = async (data) => {
  const res = await Apimanager.post("/misc/contact_us/", data);
  return res.data;
};


export const  feedbackApi = async (data) => {
  const res = await Apimanager.post("/misc/feedback/", data,{
    headers: {
      "Content-Type": "form-data",
    },
  });
  return res.data;
}