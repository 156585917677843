/* eslint-disable no-unused-vars */
import { useQuery, useMutation, useInfiniteQuery } from "@tanstack/react-query";
import {
  createResetPasswordSession,
  getIndustryTypes,
  getNotifications,
  getReferralsApi,
  getSettings,
  getUserApi,
  postEnableSupport,
  postResetPassword,
  postToggle,
  postToggleStaffManagement,
  postUpdateReferralCode,
  putUpdateSettings,
  signInApi,
  signUpApi,
  updateUserApi,
  updateUserProfileApi,
  verifyCode,
  verifyEmailApi,
} from "../api/auth";

export const useSignIn = (config) => {
  return useMutation({
    mutationKey: ["signIn"],
    mutationFn: (data) => signInApi(data),
    ...config,
  });
};

export const useSignUp = (config) => {
  return useMutation({
    mutationKey: ["signUp"],
    mutationFn: (data) => signUpApi(data),
    ...config,
  });
};

export const useVerifyEmail = () => {
  return useMutation({
    mutationKey: ["verifyEmail"],
    mutationFn: (data) => verifyEmailApi(data),
  });
};

export const useUser = (config) => {
  return useQuery({
    queryKey: ["user"],
    queryFn: () => getUserApi(),
    ...config,
  });
};

export const useUpdateUser = (config) => {
  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (data) => updateUserApi(data),
    ...config,
  });
};

export const useUpdateUserProfile = (config) => {
  return useMutation({
    mutationKey: ["updateUserProfile"],
    mutationFn: (data) => updateUserProfileApi(data),
    ...config,
  });
};

export const useCreatResetPasswordSession = (config) => {
  return useMutation({
    mutationKey: ["sendCode"],
    mutationFn: (data) => createResetPasswordSession(data),
    ...config,
  });
};

export const useVerifyCode = (config) => {
  return useMutation({
    mutationKey: ["verifyCode"],
    mutationFn: (data) => verifyCode(data),
    ...config,
  });
};

export const usePostResetPassword = (config) => {
  return useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: (data) => postResetPassword(data),
    ...config,
  });
};

export const useGetReferrals = (params, config) => {
  return useQuery({
    queryKey: ["getReferrals", params],
    queryFn: () => getReferralsApi(params),
    ...config,
  });
};

export const useGetIndustryTypes = (params, config) => {
  return useQuery({
    queryKey: ["getIndustryTypes", params],
    queryFn: () => getIndustryTypes(params),
    ...config,
  });
};

export const useGetSettings = (config) => {
  return useQuery({
    queryKey: ["getSettings"],
    queryFn: () => getSettings(),
    ...config,
  });
};

export const usePutUpdateSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateSettings"],
    mutationFn: (data) => putUpdateSettings(data),
  });
};

export const usePostEnableSupport = () => {
  return useMutation({
    mutationKey: ["postEnableSupport"],
    mutationFn: () => postEnableSupport(),
  });
};

export const usePostToggle = () => {
  return useMutation({
    mutationKey: ["postToggle"],
    mutationFn: () => postToggle(),
  });
};

export const useGetNotifications = (config) => {
  return useInfiniteQuery({
    queryKey: ["getNotifications"],
    queryFn: () => getNotifications(),
    ...config,
  });
};

export const usePostUpdateReferralCode = () => {
  return useMutation({
    mutationKey: ["postUpdateReferralCode"],
    mutationFn: (data) => postUpdateReferralCode(data),
  });
};

export const usePostToggleStaffManagement = () => {
  return useMutation({
    mutationKey: ["postToggeStaffManagement"],
    mutationFn: () => postToggleStaffManagement(),
  });
};
