/* eslint-disable react/prop-types */
import { Pause, Play } from "lucide-react";
import { useState, useRef, useEffect } from "react";

const AudioPlayer = ({ src, duration }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  // const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  // const [duration, setDuration] = useState(0);
  // const [volume, setVolume] = useState(1);

  const togglePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // const toggleMute = () => {
  //   const audio = audioRef.current;
  //   audio.muted = !isMuted;
  //   setIsMuted(!isMuted);
  // };

  const handleSeek = (e) => {
    const audio = audioRef.current;
    audio.currentTime = e.target.value;
    setCurrentTime(audio.currentTime);
  };

  // const handleVolumeChange = (e) => {
  //   const audio = audioRef.current;
  //   audio.volume = e.target.value;
  //   setVolume(audio.volume);
  // };

  const formatTime = (seconds) => {
    if (seconds === Infinity || isNaN(seconds)) {
      return "0:00";
    }
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    const audio = audioRef.current;

    // const handleMetadataLoaded = () => {
    //   setDuration(audio.duration);
    // };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    // audio.addEventListener("durationchange", handleMetadataLoaded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      // audio.removeEventListener("durationchange", handleMetadataLoaded);
    };
  }, []);

  useEffect(() => {
    if (Math.round(duration) === Math.round(currentTime)) {
      setIsPlaying(false);
    }
  }, [currentTime, duration]);

  return (
    <div className="flex flex-col items-center my-5">
      <audio ref={audioRef}>
        <source src={src} />
      </audio>
      <div className="flex items-start mb-4">
        <button
          onClick={togglePlayPause}
          className="mr-2 text-secondary mt-0.5"
        >
          {isPlaying ? (
            <Pause className="fill-secondary" />
          ) : (
            <Play className="fill-secondary" />
          )}
        </button>
        <div className="w-[200px] relative">
          <input
            type="range"
            value={currentTime}
            max={duration}
            onChange={handleSeek}
            className={`w-full audioplayer h-1`}
            style={{
              background: `linear-gradient(to right, #7190ff 0%, #7190ff ${(currentTime / duration) * 100}%, #ccc ${(currentTime / duration) * 100}%, #ccc 100%)`,
              WebkitAppearance: "none",
              MozAppearance: "none",
              appearance: "none",
            }}
          />
          <span className="absolute top-7 left-0 font-heebo text-xs text-[#808080]">
            {formatTime(currentTime)}
          </span>
          <span className="absolute top-7 right-0 font-heebo text-xs text-[#808080]">
            {formatTime(duration)}
          </span>
        </div>
      </div>
      {/* <div className="flex items-center">
        <button
          onClick={toggleMute}
          className={`px-4 py-2 mr-2 rounded ${isMuted ? "bg-red-500 hover:bg-red-700" : "bg-blue-500 hover:bg-blue-700"} text-white`}
        >
          {isMuted ? "Unmute" : "Mute"}
        </button>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
          className="w-full"
        />
      </div> */}
    </div>
  );
};

export default AudioPlayer;
