import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ClientSlider = () => {
  // const clientsImage = [
  //   "/client1.svg",
  //   "/client2.svg",
  //   "/client3.svg",
  //   "/client4.svg",
  //   "/client5.svg",
  //   "/client6.svg",
  //   "/client7.svg",
  //   "/client8.svg",
  //   "/client9.svg",
  //   "/client10.svg",
  //   "/client11.svg",
  //   "/client12.svg",
  //   "/client13.svg",
  //   "/client14.svg",
  //   "/client15.svg",
  //   "/client16.svg",
  //   "/client17.svg",
  // ];

  const clientsImage = [
    "/client11.png",
    "/client12.png",
    "/client10.png",
    "/client9.png",
    "/client13.png",
    "/client15.png",
    "/client14.png",
    "/client16.png",
    "/client17.png",
    "/client1.png",
    "/client2.png",
    "/client3.png",
    "/client4.png",
    "/client5.png",
    "/client6.png",
    "/client7.png",
    "/client8.png",
  ]

  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
    console.log(swiper.realIndex);
  };

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
    swiperRef.current.slideTo(index);
  };

  return (
    <div className="container">
      <Swiper
        ref={swiperRef}
        className="w-full md:w-[80%] mx-auto pb-[50px] flex"
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}"></span>`;
          },
        }}
        breakpoints={{
          320: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          1024: {
            slidesPerView: 6,
            slidesPerGroup: 6,
          },
        }}
        modules={[Pagination, Navigation]}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChangeTransitionEnd={handleSlideChange} // Changed from onSlideChange
      >
        {clientsImage.map((client, index) => (
          <SwiperSlide key={index} className="">
            <img
              src={client}
              alt="client"
              className="w-full md:w-[140px] h-auto md:h-[110px] object-contain mx-auto"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div className="custom-indicators mt-4 justify-center flex gap-5">
        {[0, 6, 12].map((slideIndex, index) => (
          <button
            key={index}
            className={`indicator rounded-full w-[12px] h-[12px] ${activeIndex === slideIndex ? "bg-[#4F75FA]" : "bg-[#D9D9D9]"}`}
            onClick={() => handleIndicatorClick(slideIndex)}
          ></button>
        ))}
      </div> */}
    </div>
  );
};

export default ClientSlider;
