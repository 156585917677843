import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteEmployeeApi,
  getActiveDefaultMagicLink,
  getDefaultQuestionSets,
  getEmployeeNameApi,
  getEmployeesListApi,
  getMagicLink,
  getMagicQr,
  postAddDefaultQuestionSets,
  postAddEmployeeApi,
  postDefaultMagicLink,
  postEmployeeNameApi,
  postEnableEmployeeApi,
  postResetMagicLink,
  putUpdateEmployeeApi,
} from "../api/employeesApi";

export const useGetEmployeesList = (config) => {
  return useQuery({
    queryKey: ["getEmployeesList"],
    queryFn: () => getEmployeesListApi(),
    ...config,
  });
};

export const usePostAddEmployee = () => {
  return useMutation({
    mutationKey: ["postAddEmployee"],
    mutationFn: (data) => postAddEmployeeApi(data),
  });
};

export const usePutUpdateEmployee = () => {
  return useMutation({
    mutationKey: ["putUpdateEmployee"],
    mutationFn: (data) => putUpdateEmployeeApi(data),
  });
};

export const useDeleteEmployee = () => {
  return useMutation({
    mutationKey: ["deleteEmployee"],
    mutationFn: (data) => deleteEmployeeApi(data),
  });
};

export const usePostEmployeeName = () => {
  return useMutation({
    mutationKey: ["postEmployeeName"],
    mutationFn: (data) => postEmployeeNameApi(data),
  });
};

export const useGetEmployeeName = (data, config) => {
  return useQuery({
    queryKey: ["getEmployeeName"],
    queryFn: () => getEmployeeNameApi(data),
    ...config,
  });
};

export const usePostEmployeeEnable = () => {
  return useMutation({
    mutationKey: ["postEmployeeEnable"],
    mutationFn: (id) => postEnableEmployeeApi(id),
  });
};

export const useGetMagicLink = (config) => {
  return useQuery({
    queryKey: ["getMagicLink"],
    queryFn: () => getMagicLink(),
    ...config,
  });
};

export const usePostResetMagicLink = () => {
  return useMutation({
    mutationKey: ["postResetMagicLink"],
    mutationFn: () => postResetMagicLink(),
  });
};

export const usePostDefaultMagicLink = () => {
  return useMutation({
    mutationKey: ["postResetMagicLink"],
    mutationFn: (id) => postDefaultMagicLink(id),
  });
};

export const useGetActiveDefaultMagicLink = () => {
  return useQuery({
    queryKey: ["getActiveDefaultMagicLink"],
    queryFn: () => getActiveDefaultMagicLink(),
  });
};

export const useGetDefaultQuestionSets = (config) => {
  return useQuery({
    queryKey: ["getDefaultQuestionSets"],
    queryFn: () => getDefaultQuestionSets(),
    ...config,
  });
};

export const usePostAddDefaultQuestionSets = () => {
  return useMutation({
    mutationKey: ["postAddDefaultQuestionSets"],
    mutationFn: (data) => postAddDefaultQuestionSets(data),
  });
};

export const useGetMagicQr = () => {
  return useQuery({
    queryKey: ["getMagicQr"],
    queryFn: () => getMagicQr(),
  });
};

export const useClickGetMagicQr = () => {
  return useMutation({
    mutationKey: ["getClickMagicQr"],
    mutationFn: (code) => getMagicQr(code),
  });
};
