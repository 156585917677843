import Layout from "../component/Layout";
import { useLocation } from "react-router";
import { useState } from "react";
import { Link } from "react-router-dom";
import Plan from "./components/plan";
import { DeleteModal } from "../component/modal";
import Invoice from "./components/Invoice";
import { useUser } from "../../../query/useAuthentication";
import { useAuth } from "../../../context/authContext";

const Payment = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [openModal, setOpenModal] = useState(false);
  // const { userInfo, setUserInfo } = useAuth();
  // const savedCard = queryParams.get("savedCard") === "true";
  const invoice = queryParams.get("invoice") === "true";

  const choosePlanScreen = !invoice;

  // useUser({
  //   onSuccess: (res) => {
  //     setUserInfo(res);
  //     localStorage.setItem("user", JSON.stringify(res));
  //     localStorage.setItem("entityUuid", res?.entity?.uuid);
  //   },
  // });
  return (
    <Layout title={choosePlanScreen ? "Plans" : "Invoice History"}>
      <div className="w-full">
        {/* {userInfo?.profile?.current_plan && (
          <div className="w-full lg:w-[40%] h-full flex flex-col gap-[14px] lg:sticky top-24 overflow-y-auto">
            <Link
              to="/dashboard/payment?savedCard=false"
              className={`w-full rounded-[16px]  p-[30px]  cursor-pointer border
          ${
            !choosePlanScreen
              ? "bg-[#F6F6FF] border-[#F6F6FF]"
              : "border-secondary bg-secondary"
          }
          `}
            >
              <h3
                className={`font-montserrat text-base mb-[19px]  ${
                  !choosePlanScreen ? "text-[#080808]" : "text-white"
                }`}
              >
                Your Plan
              </h3>
              {userInfo?.profile?.current_plan ? (
                <>
                  <h1
                    className={`text-[26px] sm:text-[36px] font-bold mb-[17px] ${
                      !choosePlanScreen ? "text-secondary" : "text-white"
                    }`}
                  >
                    {userInfo?.profile?.current_plan?.short_text}
                  </h1>
                  <div className="flex gap-[14px] items-center mb-[14px]">
                    <h1
                      className={`text-[30px] sm:text-[40px] font-bold ${
                        !choosePlanScreen ? "text-[#080808]" : "text-white"
                      }`}
                    >
                      {userInfo?.profile?.current_plan?.currency}{" "}
                      {userInfo?.profile?.current_plan?.price}
                    </h1>
                    <div
                      className={`text-[13px] sm:text-base ${
                        !choosePlanScreen ? "text-[#080808]" : "text-white"
                      }`}
                    >
                      <p> /Month</p>
                    </div>
                  </div>
                </>
              ) : (
                <h1
                  className={`text-[26px] sm:text-[36px] font-bold mb-[17px] ${
                    !choosePlanScreen ? "text-secondary" : "text-white"
                  }`}
                >
                  You don&apos;t have an active subscription!
                </h1>
              )}
            </Link>
            <Link
              to="/dashboard/payment?invoice=true"
              className={`w-full  rounded-[16px] p-[30px] 
          ${invoice ? "border border-secondary bg-secondary" : "bg-[#F6F6FF] "}
          flex items-center justify-between`}
            >
              <h3
                className={`font-montserrat text-base
            ${invoice ? "text-white " : "text-[#080808]"}
            `}
              >
                Invoice History
              </h3>
            </Link>
          </div>
        )} */}
        <div className={`w-full`}>
          {choosePlanScreen && <Plan />}
          {invoice && <Invoice />}
        </div>
      </div>
      <DeleteModal openModal={openModal} setOpenModal={setOpenModal} />
    </Layout>
  );
};

export default Payment;
