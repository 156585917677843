/* eslint-disable react/prop-types */
import { Switch } from "@headlessui/react";
import { Modal } from "flowbite-react";
import { Settings } from "lucide-react";
import IconButton from "../../component/IconButton";
import { LogoLoading } from "../../../../components/loading/loading";

const LinkedAccount = ({
  isActiveLink,
  setIsActiveLink,
  isOpenModal,
  setIsOpenmodal,
  toggle,
  link,
  setLink,
  handleClick,
  isLoading,
  isEnableLoading,
  isToggleLoading,
  image,
  icon,
  name,
  isComingSoon,
  isSupport,
  onEnableSupport,
  onUpdateSettings,
}) => {
  if (isLoading) {
    return <LogoLoading />;
  }
  return (
    <div className="bg-white border border-[#e7e4e4] dark:border-[#454545] dark:bg-darkLightBg rounded-xl h-20 flex justify-between items-center px-5">
      <div className="flex items-center gap-x-2">
        {image ? <img src={image} className="w-6 h-6" /> : <>{icon}</>}
        <p className="font-pro font-medium lg:text-xl">{name}</p>
      </div>
      {isComingSoon ? (
        <p>Coming Soon</p>
      ) : isSupport ? (
        <div className="flex items-center gap-x-3">
          <p
            className={`font-pro text-sm ${isActiveLink ? "text-[#0DCF0D]" : "text-red-500"}`}
          >
            {isActiveLink ? "Active" : "Inactive"}
          </p>
          <div className="w-[1px] h-[30px] bg-[#e6e6e6]"></div>
          <Switch
            disabled={isToggleLoading}
            checked={isActiveLink}
            onChange={toggle}
            className={`${isActiveLink ? "bg-[#3183FF]" : "bg-[#8E8B8B]"}
          relative h-[30px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
          >
            <span className="sr-only">Active {name}</span>
            <span
              aria-hidden="true"
              className={`${isActiveLink ? "translate-x-8" : "translate-x-0"} pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
          {isToggleLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
            </div>
          ) : (
            <IconButton>
              <Settings
                className="text-[#a6a6a6] cursor-pointer"
                onClick={() => setIsOpenmodal(true)}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <button
          className="bg-secondary rounded-md text-white px-2 py-1.5 flex justify-center items-center"
          onClick={onEnableSupport}
        >
          {isEnableLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
            </div>
          ) : (
            "Enable"
          )}
        </button>
      )}
      <Modal
        popup
        dismissible
        show={isOpenModal}
        onClose={() => {
          setIsOpenmodal(false);
        }}
        className="z-[1000]"
        theme={{
          content: {
            base: "relative w-full p-4 h-auto",
            inner: "dark:bg-darkBg bg-white rounded-lg",
          },
          header: {
            base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
            close: {
              base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
            },
          },
        }}
      >
        <Modal.Header>
          <p className="font-pro font-medium text-xl px-5 pt-1">Settings</p>
        </Modal.Header>
        <Modal.Body className="h-auto">
          <div className="pb-5 px-5">
            <p className="font-pro font-medium text-xl dark:text-white">
              Review URL
            </p>
            <input
              type="text"
              className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-5 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
              placeholder="Review URL"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <div className="flex justify-center items-center gap-x-3 mt-7">
              <button
                className="bg-newPrimary w-[121px] py-3 rounded-[7px] text-white border border-newPrimary "
                onClick={handleClick}
              >
                {/* {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : (
                  "Save"
                )} */}
                Save
              </button>
              <button
                className="bg-white dark:bg-darkLightBg w-[121px] py-3 rounded-[7px] text-newPrimary border border-newPrimary"
                onClick={() => {
                  setIsOpenmodal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LinkedAccount;
