/* eslint-disable react/prop-types */
import { ChevronDown, GripVertical } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Switch } from "@headlessui/react";
import {
  usePostSetCategoryOrder,
  usePostToggleCategory,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "flowbite-react";
import { Reorder, useDragControls } from "framer-motion";

const Item = ({ item, setIsOpen, setValue, isToggle, isSort, isReorder }) => {
  const controls = useDragControls();
  return (
    <Reorder.Item
      key={item?.id}
      value={item}
      dragListener={false}
      dragControls={controls}
      className="flex items-center justify-between gap-x-3 group"
    >
      {isReorder && (
        <GripVertical
          onPointerDown={(e) => controls.start(e)}
          className="active:cursor-grabbing"
        />
      )}
      <p
        className="w-full cursor-pointer group-hover:text-secondary"
        onClick={() => {
          setIsOpen(false);
          setValue(
            isToggle ? item?.category : isSort ? item?.value : item?.keyword
          );
        }}
      >
        {isToggle ? item?.category : isSort ? item?.name : item?.keyword}
      </p>
    </Reorder.Item>
  );
};

const CustomDropdown = ({
  value,
  setValue,
  values,
  isToggle,
  isSort,
  setId,
}) => {
  const dropdownRef = useRef(null);
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isReorder, setIsReorder] = useState(false);
  const [orderValues, setOrderValues] = useState([]);
  const [originalValues, setOriginalValues] = useState([]);

  const { mutate: categoryOrder, isLoading: categoryOrderLoading } =
    usePostSetCategoryOrder();

  const handleReorder = () => {
    const orderObj = {};
    orderValues.forEach((item, i) => {
      orderObj[i] = item?.id;
    });
    categoryOrder(
      {
        set: setId,
        order: orderObj,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setIsReorder(false);
          queryClient.invalidateQueries(["getDefaultQuestions"]);
          queryClient.invalidateQueries(["getDefaultQuestionsForSets"]);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    setOrderValues(values);
    setOriginalValues(values);
  }, [values]);
  useOnClickOutside({ ref: dropdownRef, setIsOpen: setIsOpen });
  return (
    <div
      className="lg:w-[300px] w-full relative text-[#615E83] dark:text-secondary h-12 font-pro"
      ref={dropdownRef}
    >
      <div
        className="px-6 h-full rounded-full focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent flex items-center justify-between w-full bg-white dark:bg-darkBg dark:border-none cursor-pointer border border-white"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <p className="">
          {isSort ? values?.find((item) => item?.value === value).name : value}
        </p>
        <ChevronDown
          className={`w-5 h-5 ${isOpen && "rotate-180"} transition-all duration-200`}
        />
      </div>
      {isOpen && (
        <div className="w-full p-5 rounded-[7px] absolute top-14 left-0 bg-white dark:bg-darkBg dark:border-none border space-y-5 z-[10] text-left lg:h-[200px] overflow-y-auto scrollbar-thin shadow-md">
          {isToggle ? (
            <>
              {isReorder ? (
                <div className="flex items-center justify-between gap-3">
                  <button
                    className="rounded-[7px]  text-white bg-secondary border border-secondary text-base text-center px-3 py-2 w-full dark:hover:bg-darkBg hover:bg-white hover:text-secondary transition-all duration-200"
                    onClick={handleReorder}
                  >
                    {categoryOrderLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      "Confirm"
                    )}
                  </button>
                  <button
                    className="rounded-[7px]  text-secondary border border-secondary text-base text-center px-3 py-2 w-full  transition-all duration-200 dark:bg-darkLightBg"
                    onClick={() => {
                      setIsReorder(false);
                      setOrderValues(originalValues);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="rounded-[7px]  text-white bg-secondary border border-secondary text-base text-center px-3 py-2 w-full dark:hover:bg-darkBg hover:bg-white hover:text-secondary transition-all duration-200"
                  onClick={() => setIsReorder(true)}
                >
                  Reorder
                </button>
              )}
            </>
          ) : null}
          <Reorder.Group
            className="space-y-5"
            values={orderValues}
            onReorder={setOrderValues}
          >
            {orderValues?.map((item) => (
              <Item
                item={item}
                isSort={isSort}
                isToggle={isToggle}
                setIsOpen={setIsOpen}
                setValue={setValue}
                key={item?.id}
                isReorder={isReorder}
              />
              // <div
              //   key={item?.id}
              //   className="flex items-center justify-between gap-x-3 group"
              // >

              //   <p
              //     className="w-full cursor-pointer group-hover:text-secondary"
              //     onClick={() => {
              //       setIsOpen(false);
              //       setValue(
              //         isToggle
              //           ? item?.category
              //           : isSort
              //             ? item?.value
              //             : item?.keyword
              //       );
              //     }}
              //   >
              //     {isToggle
              //       ? item?.category
              //       : isSort
              //         ? item?.name
              //         : item?.keyword}
              //   </p>
              // </div>
            ))}
          </Reorder.Group>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
