import { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { toast } from "sonner";
import {
  useGetReferrals,
  usePostUpdateReferralCode,
  useUser,
} from "../../../query/useAuthentication";
import { dayFormatter } from "../../../utils/formatter";
import Skeleton from "react-loading-skeleton";
import { Check, Copy, Pencil, X } from "lucide-react";
import { Table } from "flowbite-react";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { LogoLoading } from "../../../components/loading/loading";
const Referral = () => {
  const baseUrl = import.meta.env.VITE_API_URL;
  const [originalReferralCode, setOriginalReferralCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [index, setIndex] = useState(0);
  const { darkMode } = useDarkMode();

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    toast.success("Copied to clipboard");
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const { data, isLoading } = useGetReferrals({
    index: index,
  });

  const { refetch } = useUser({
    onSuccess: (res) => {
      setReferralCode(res?.profile?.referral_code);
      setOriginalReferralCode(res?.profile?.referral_code);
    },
  });

  const { mutate: updateReferralCode, isLoading: updateLoading } =
    usePostUpdateReferralCode();

  const handleUpdateReferralCode = () => {
    updateReferralCode(
      {
        new_code: referralCode,
      },
      {
        onSuccess: (res) => {
          setIsEditable(false);
          toast.success("Referral code updated successfully");
          refetch();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (updateLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Referral"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] p-5 sm:p-[31px] min-h-[calc(100vh-120px)]">
        <div className="flex items-center gap-x-5">
          <p className="text-xl font-medium">Referral Code:</p>

          <div className="relative lg:w-[160px] font-montserrat text-sm  text-[#2a49b8]">
            <input
              type="text"
              placeholder="MagicReview Link"
              className="w-full rounded-[8px] border border-[#f6f6ff] bg-white dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary  text-sm placeholder:text-sm pl-3 lg:pr-5 py-2"
              disabled={!isEditable}
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            {!isEditable && (
              <Copy
                className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-4 h-4 dark:text-secondary"
                onClick={handleCopy}
              />
            )}
          </div>
          {isEditable ? (
            <div className="flex items-center gap-x-2">
              <div
                className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary dark:border text-[#2a49b8]"
                onClick={handleUpdateReferralCode}
                role="button"
              >
                {/* {updateLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-t-2 border-b-2 border-[#2a49b8] rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <Check className="w-4 h-4 " />
                )} */}
                <Check className="w-4 h-4 " />
              </div>
              <div
                className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545]  dark:border"
                onClick={() => {
                  setIsEditable(false);
                  setReferralCode(originalReferralCode);
                }}
              >
                <X className="w-4 h-4 text-red-500" />
              </div>
            </div>
          ) : (
            <div
              className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary text-[#2a49b8] dark:border"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              <Pencil className="w-4 h-4 " />
            </div>
          )}
        </div>

        <div className="overflow-x-auto mt-7">
          {data?.referrals?.length <= 0 ? (
            <div
              className="w-full min-h-[400px] flex flex-col justify-center items-center space-y-3
            "
            >
              <img src="/empty/no-referral.svg" alt="" />
              <p className="font-pro text-[#888] text-sm">
                Your referrals will appear here!
              </p>
            </div>
          ) : (
            <Table
              theme={{
                root: {
                  base: "w-full text-left text-sm lg:text-base ",
                  shadow:
                    "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
                },
                head: {
                  base: "group/head font-pro dark:text-darkGray",
                  cell: {
                    base: "bg-white dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
                  },
                },
                body: {
                  base: "group/body",
                  cell: {
                    base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
                  },
                },
              }}
            >
              <Table.Head className="">
                <Table.HeadCell className="font-medium">Account</Table.HeadCell>
                <Table.HeadCell className="font-medium">Date</Table.HeadCell>
                <Table.HeadCell className="font-medium">Plan</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {isLoading ? (
                  <Table.Row>
                    {new Array(3).fill("").map((_, i) => (
                      <Table.Cell key={i} className="bg-white dark:bg-darkBg">
                        <Skeleton
                          height={30}
                          baseColor={darkMode && "#262626"}
                        />
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ) : data?.referrals?.length > 0 ? (
                  data?.referrals?.map((referral, i) => (
                    <Table.Row key={i} className="bg-white dark:bg-darkBg">
                      <Table.Cell className="">
                        <div className="flex justify-start items-center gap-x-2">
                          <img
                            src={
                              referral.profile_url
                                ? baseUrl + referral.profile_url
                                : "/new-logo.svg"
                            }
                            alt=""
                            className="w-10 h-10 rounded-full"
                            referrerPolicy="no-referrer"
                          />
                          <p className="break-words">{referral.first_name}</p>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {referral.date && dayFormatter(referral.date)}
                      </Table.Cell>
                      <Table.Cell>{referral.current_plan}</Table.Cell>
                    </Table.Row>
                  ))
                ) : null}
              </Table.Body>
            </Table>
          )}
        </div>
        <div className="w-full lg:w-[630px] flex items-center justify-between mt-7">
          {index > 0 ? (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handlePrev}
            >
              Prev
            </button>
          ) : (
            <div></div>
          )}
          {data?.total > 0 && index + 10 < data?.total && (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handleNext}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Referral;
