/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { toast } from "sonner";

const LinkModal = ({ openModal, setOpenModal, link }) => {
  const handleCopy = (employeeCode) => {
    if (link) {
      navigator.clipboard.writeText(link);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };
  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => {
        setOpenModal();
        // setData();
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
      popup
    >
      <Modal.Header>
        <p className="px-5 pt-1 font-pro text-lg">MagicLink</p>
      </Modal.Header>
      <Modal.Body className="h-auto lg:w-[500px]">
        <input
          type="text"
          value={link}
          className="w-full border-x-0 border-t-0 appearance-none font-pro text-sm"
        />
        <div className="flex justify-end items-center gap-x-3 mt-7">
          <button
            className="bg-white w-[121px] py-2 rounded-[7px] text-newPrimary  dark:bg-darkLightBg border border-newPrimary"
            onClick={() => {
              setOpenModal(null);
            }}
          >
            Cancel
          </button>
          <button
            className="bg-newPrimary w-[121px] py-2 rounded-[7px] text-white border border-newPrimary "
            onClick={handleCopy}
          >
            Copy
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LinkModal;
