/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint, ReactToPrint } from "react-to-print";
import { LoadingButton } from "../../../components/loading/loading";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";

const QrContainer = ({ openEditModal, setOpenEditModal, qr, isLoading }) => {
  const printRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `@media print {
      @page {
        size: 393px 830px;
        margin: 10px;
      }
    }`,
  });

  const handleSaveAsPng = async () => {
    if (printRef.current === null) {
      return;
    }

    const canvas = await html2canvas(printRef.current, {
      useCORS: true,
      allowTaint: true,
    });
    const dataUrl = canvas.toDataURL("image/png", 1.0);
    if (dataUrl) {
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "qr.png";
      link.click();
    }
  };

  useEffect(() => {
    // Create a URL for the Blob
    if (qr) {
      const url = URL.createObjectURL(qr);

      // Set the URL as the image source
      setImageSrc(url);

      // Clean up the URL object when the component unmounts
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [qr]);

  return (
    <Modal
      popup
      dismissible
      show={openEditModal}
      onClose={() => {
        setOpenEditModal(false);
      }}
      size={"lg"}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1 text-sm text-[#252323] hover:bg-gray-200 hover:text-[#252323]",
          },
        },
      }}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body className="lg:pb-5 lg:px-0 font-poppins flex flex-col items-center">
        {/* what will be printed */}
        <div
          className="hidden lg:scale-75 lg:border lg:-mt-24 print:block print:scale-100"
          ref={printRef}
        >
          <div className="w-[393px] mx-auto h-[293px] bg-[#4869df] rounded-br-[100px] relative">
            <div className="absolute bottom-0 w-full">
              <p className="text-center font-poppins font-semibold text-lg text-white mb-[100px]">
                Scan QR Code
              </p>
              <div className="w-[333px] mx-auto bg-white rounded-t-[20px] flex flex-col items-center border border-[#F2F2F2] border-b-none">
                <img
                  src={user?.profile?.profile_picture}
                  alt=""
                  className="w-[90px] h-[90px] rounded-full -translate-y-1/2 z-30"
                  referrerPolicy="no-referrer"
                />
                <p className="-translate-y-[30px] font-poppins font-semibold text-lg">
                  {user?.entity?.name}
                </p>
              </div>
            </div>
          </div>

          <div className="aspect-square w-[333px] border border-[#F2F2F2] rounded-b-[20px] border-t-none mx-auto overflow-hidden flex items-center justify-center shadow">
            {isLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
              </div>
            ) : (
              <img src={imageSrc} alt="" className="size-[250px]" />
            )}
          </div>

          <div className="w-[333px] mx-auto mt-10">
            <div className="flex items-center gap-x-5">
              <div className="w-full h-0.5 border-t border-t-[#ebebeb]"></div>
              <p className="flex-shrink-0 text-[#888] text-sm">Powered by</p>
            </div>
            <div className="flex justify-end">
              <div className="flex items-center max-w-[150px] -mt-3">
                <img
                  src="/magiclogo.svg"
                  alt=""
                  className="w-[50px]"
                  crossOrigin="anonymous"
                />
                <p className="text-[18.64px] -tracking-[3%] leading-[54px] dark:text-white text-[#434343] font-avertaBold text-center -mt-2">
                  magicreview
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* what user sees */}
        <div className="mx-auto flex flex-col text-center">
          <p className="text-center font-poppins font-medium text-xl text-black mb-[4px]">
            Scan QR Code
          </p>
          <p className="text-[#888888] font-poppins text-[14px] mb-[56px]">
            Scan this code to view your magiclink
          </p>

          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
            </div>
          ) : (
            <img
              src={imageSrc}
              alt=""
              className="w-[55%] border border-[#BFBFBF] mx-auto rounded-[12px] mb-[65px]"
            />
          )}
        </div>
        <ReactToPrint
          trigger={() => (
            <button className="rounded-[7px] max-w-[349px] w-full text-white bg-newPrimary border border-newPrimary text-base text-center px-6 py-2 hover:bg-white hover:text-newPrimary transition-all duration-200 h-12 dark:hover:bg-darkLightBg">
              Print
            </button>
          )}
          content={() => printRef.current}
        />
        {/* <button
          className="rounded-[7px] w-[333px]  text-white bg-secondary border border-secondary text-base text-center px-6 py-2 hover:bg-white hover:text-secondary transition-all duration-200 h-12 dark:hover:bg-darkLightBg mt-3"
          onClick={handleSaveAsPng}
        >
          Save as PNG
        </button> */}
      </Modal.Body>
    </Modal>
  );
};

export default QrContainer;
