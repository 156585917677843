/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  ChevronDown,
  Command,
  CreditCard,
  LayoutGrid,
  LinkIcon,
  MessageSquareText,
  Settings,
  Sparkles,
  UsersRound,
} from "lucide-react";
import useWindowSize from "../../../hooks/useWindowSize";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "./CustomTooltipForJoyride";

export const LinkComponent = ({ link, icon, title, className }) => {
  const location = window.location.pathname;
  return (
    <Link
      to={link}
      className={`flex gap-[21px] items-center py-2.5 px-3 rounded-[7px] hover:bg-gray-200/70 dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === link ? "text-[#2a49b8] dark:text-secondary bg-[#f6f6ff] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-[#979A9B]"} ${className}`}
    >
      <>{icon}</>
      <p className="text-[14px]">{title}</p>
    </Link>
  );
};

const SideBar = ({ isOpenDrawer }) => {
  const [params] = useSearchParams();
  // const navigate = useNavigate();
  const savedCard = params.get("savedCard");
  const invoice = params.get("invoice");
  // const referer = params.get("referer");
  // const windowSize = useWindowSize();
  // const isMobile = windowSize.width <= 768;
  const location = window.location.pathname;
  const [isOpenSubscription, setIsOpenSubscription] = useState(
    location.includes("payment")
  );
  const [isOpenSetting, setIsOpenSetting] = useState(
    location.includes("settings")
  );

  // const joyRideStepsSideBar = [
  //   {
  //     icon: <MessageSquareText />,
  //     title: "Reviews",
  //     content:
  //       "View all generated AI reviews here. Easily download reviews for offline analysis.",
  //     target: ".reviews",
  //     placement: "right",
  //   },
  //   {
  //     icon: <Sparkles />,
  //     title: "Questions",
  //     content: "Track the number of AI-generated reviews posted by users.",
  //     target: ".questions",
  //     placement: "right",
  //   },
  //   {
  //     icon: <Command />,
  //     title: "SEO Keywords",
  //     content:
  //       "Add SEO keywords to ensure your reviews highlighted for better search visibility.",
  //     target: ".seo",
  //     placement: "right",
  //   },
  //   {
  //     icon: <Link />,
  //     title: "Magiclinks",
  //     content:
  //       "Enable and add MagicLinks to track the no. of reviews generated from each link.",
  //     target: ".magiclinks",
  //     placement: "right",
  //   },
  //   {
  //     icon: <UsersRound />,
  //     title: "Referral",
  //     content: "Copy your referral code and track who has used it.",
  //     target: ".referral",
  //     placement: "right",
  //   },
  //   {
  //     icon: <Settings />,
  //     title: "Settings",
  //     content:
  //       "Edit your profile details and integrate with different platforms.",
  //     target: ".settings",
  //     placement: "right",
  //     referrerFunc: function () {
  //       navigate("/dashboard/questions?referrer=verify-email");
  //     },
  //     referrer: "Go to question section",
  //   },
  // ];

  // const [step, setStep] = useState({
  //   run: false,
  //   steps: joyRideStepsSideBar,
  // });

  // const handleJoyrideCallback = (data) => {
  //   const { status, type, index, action } = data;
  //   const options = [STATUS.FINISHED, STATUS.SKIPPED];

  //   if (options.includes(status)) {
  //     setStep((prev) => {
  //       return { ...prev, run: false };
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (referer === "verify-email") {
  //     setStep((prev) => {
  //       return { ...prev, run: true };
  //     });
  //   }
  // }, [isOpenDrawer]);

  useEffect(() => {
    if (location.includes("payment")) {
      setIsOpenSubscription(true);
    } else {
      setIsOpenSubscription(false);
    }
  }, []);

  useEffect(() => {
    if (location.includes("settings")) {
      setIsOpenSetting(true);
    } else {
      setIsOpenSetting(false);
    }
  }, []);

  return (
    <div className="pr-5 lg:pr-0">
      <div className="flex flex-col font-medium mt-5 lg:mt-[38px] gap-3 text-base font-pro">
        <LinkComponent
          link={"/dashboard"}
          icon={<LayoutGrid />}
          title={"Overview"}
        />
        <LinkComponent
          link={"/dashboard/reviews"}
          icon={<MessageSquareText />}
          title={"Reviews"}
          className={"reviews"}
        />
        <LinkComponent
          link={"/dashboard/questions"}
          icon={<Sparkles />}
          title={"Questions"}
          className={"questions"}
        />
        <LinkComponent
          link={"/dashboard/keywords"}
          icon={<Command />}
          title={"SEO Keywords"}
          className={"seo"}
        />
        <LinkComponent
          link={"/dashboard/staffs"}
          icon={<LinkIcon />}
          title={"Magiclinks"}
          className={"magiclinks"}
        />
        <div>
          <div
            className={`flex items-center justify-between py-2.5 px-3 rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg ${location.includes("payment") ? "text-[#2a49b8] dark:text-secondary bg-[#f6f6ff] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
            onClick={() => setIsOpenSubscription((prev) => !prev)}
          >
            <div className="flex items-center gap-[22px]">
              <CreditCard />
              <p>Subscription</p>
            </div>
            <ChevronDown
              className={`w-4 transition-all duration-200 ${isOpenSubscription && "rotate-180"}`}
            />
          </div>
          {isOpenSubscription && (
            <div
              className={`space-y-3 pt-1 transition-height duration-200 text-[#3d3d3d] ${isOpenSubscription ? "h-fit" : "h-0"}`}
            >
              <Link
                className={`block hover:bg-gray-200/70 py-2.5 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${savedCard ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                to={"/dashboard/payment?savedCard=false"}
              >
                Plans
              </Link>
              <Link
                className={`block hover:bg-gray-200/70 py-2.5 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${invoice ? "text-[#2a49b8] dark:text-secondary " : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                to={"/dashboard/payment?invoice=true"}
              >
                Invoice History
              </Link>
              <Link
                className={`block hover:bg-gray-200/70 py-2.5 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/payment/buy-card" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                to={"/dashboard/payment/buy-card"}
              >
                Buy Card
              </Link>
            </div>
          )}
        </div>
        <LinkComponent
          link={"/dashboard/referral"}
          icon={<UsersRound />}
          title={"Referral"}
          className={"referral"}
        />
        <div>
          <div
            className={`settings flex items-center justify-between py-2.5 px-3 rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location.includes("settings") ? "text-[#2a49b8] dark:text-secondary bg-[#f6f6ff] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
            onClick={() => setIsOpenSetting((prev) => !prev)}
          >
            <div className="flex items-center gap-[22px]">
              <Settings />
              <p>Settings</p>
            </div>
            <ChevronDown
              className={`w-4 transition-all duration-200 ${isOpenSetting && "rotate-180"}`}
            />
          </div>
          {isOpenSetting && (
            <div
              className={`space-y-2 pt-1 transition-all duration-500 text-[#3d3d3d] ${isOpenSetting ? "h-fit" : "h-0"}`}
            >
              <Link
                className={`block hover:bg-gray-200/70 py-2.5 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/dashboard/settings/account" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                to={"/dashboard/settings/account"}
              >
                Account
              </Link>
              <Link
                className={`block hover:bg-gray-200/70 py-2.5 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/settings/integrations" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                to={"/dashboard/settings/integrations"}
              >
                Integrations
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
            zIndex: 10000,
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      /> */}
    </div>
  );
};

export default SideBar;
