/* eslint-disable react/prop-types */
import { Switch } from "@headlessui/react";
import { Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import {
  useGetToggleDirectReview,
  useGetToggleVoiceReview,
  usePostToggleDirectReview,
  usePostToggleVoiceReview,
} from "../../../query/useEntity";
import CustomDropdownForCategoryToggle from "./CustomDropdownForCategoryToggle";
import CustomDropdownForCategoryOrder from "./CustomDropdownForCategoryOrder";

const QuestionSettingsModal = ({ isOpenModal, setIsOpenModal }) => {
  const [user, setUser] = useState();
  const { data: directReview, refetch: refetchDirectReview } =
    useGetToggleDirectReview();
  const { mutate: toggleDirectReview, isLoading: toggleDirectReviewLoading } =
    usePostToggleDirectReview();
  const { data: voiceReview, refetch: refetchVoiceReview } =
    useGetToggleVoiceReview(
      { entityId: user?.entity?.uuid },
      {
        enabled: Boolean(user?.entity?.uuid),
      }
    );
  const { mutate: toggleVoiceReview, isLoading: toggleVoiceReviewLoading } =
    usePostToggleVoiceReview();
  const handleToggleDirectReview = () => {
    toggleDirectReview(
      {},
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchDirectReview();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  const handleToggleVoiceReview = () => {
    toggleVoiceReview(
      { entityId: user?.entity?.uuid },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchVoiceReview();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  return (
    <Modal
      dismissible
      popup
      show={isOpenModal}
      onClose={() => {
        setIsOpenModal(false);
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative w-full p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
    >
      <Modal.Header>
        <p className="font-pro font-medium text-xl pt-1 px-5">
          Question Settings
        </p>
      </Modal.Header>
      <Modal.Body className="h-auto">
        <div className="p-2 font-pro space-y-7">
          <div className="grid grid-cols-3 w-full ">
            <div className="col-span-2">
              <p className={`text-lg dark:text-white`}>Enable Direct Review</p>
              <p className="text-sm text-[#525252] dark:text-white">
                (Once enabled, it won&apos;t show any questions)
              </p>
            </div>
            <div className="flex items-center gap-x-3">
              <Switch
                disabled={toggleDirectReviewLoading}
                checked={directReview?.direct_review}
                onChange={handleToggleDirectReview}
                className={`${directReview?.direct_review ? "bg-[#3183FF]" : "bg-[#8E8B8B]"} relative h-[30px] w-[60px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
              >
                <span className="sr-only">Enable Direct Review</span>
                <span
                  aria-hidden="true"
                  className={`${directReview?.direct_review ? "translate-x-8" : "translate-x-0"} pointer-events-none inline-block h-6 w-6 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              {toggleDirectReviewLoading && (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full space-y-5">
            <div className="col-span-2">
              <p className={`text-lg dark:text-white`}>
                Display Keywords in dropdown
              </p>
              <p className="text-sm text-[#525252] dark:text-white">
                Select the categories in which the keywords should be display in
                dropdown format
              </p>
            </div>
            <CustomDropdownForCategoryToggle isMandatory={false} />
          </div>
          <div className="w-full space-y-5">
            <div className="col-span-2">
              <p className={`text-lg dark:text-white`}>Mandatory categories</p>
              <p className="text-sm text-[#525252] dark:text-white">
                Select the categories which should be mandatory to select in
                review page
              </p>
            </div>
            <CustomDropdownForCategoryToggle isMandatory={true} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionSettingsModal;
