import { useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate, useParams } from "react-router";
import { useVerifyEmail } from "../../query/useAuthentication";
import { toast } from "sonner";
import { useSearchParams } from "react-router-dom";
import { LogoLoading } from "../../components/loading/loading";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [otp, setOtp] = useState("");
  const isMobile = window.innerWidth <= 768;
  const { mutate: verifyEmail, isLoading: verifyEmailLoading } =
    useVerifyEmail();
  const id = params.get("id");

  const handleVerifyEmail = () => {
    verifyEmail(
      { id: id, code: otp },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          localStorage.setItem("token", res.token);
          navigate("/dashboard?referer=verify-email");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (verifyEmailLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full container min-h-screen flex xs:justify-center xs:items-center py-10 bg-[#F7F7F7]">
      <div className="flex flex-col items-center md:justify-center lg:w-auto w-full  bg-white p-10 rounded-[20px] h-auto">
        <img src="/magiclogo.svg" alt="" className="w-[100px]" />
        <h1 className="text-[26px] font-semibold font-poppins text-[#252323] mb-5 text-center">
          Verify your email address
        </h1>
        <p className="text-[#656565] mb-10 text-center font-poppins">
          To start using MagicReview, confirm your email <br /> address with the
          code we sent to your email.
        </p>
        <div className="">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              columnGap: "5px",
            }}
            inputStyle={{
              width: isMobile ? "35px" : "50px",
              height: isMobile ? "35px" : "50px",
              borderRadius: "8px",
            }}
          />
          {/* <div className="flex items-center gap-x-1 font-montserrat text-[14px] leading-7 mt-3">
      <p className="inline">Didn&apos;t receive a code?</p>
      <p
        className="inline text-secondary underline cursor-pointer"
       
      >
        Click to resend
      </p>
    </div> */}
          <button
            type="submit"
            disabled={otp.length < 6}
            className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-5 transition-all duration-300  font-poppins active:scale-95 w-full "
            onClick={handleVerifyEmail}
          >
            {/* {verifyEmailLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Verify Code"
            )} */}
            Verify Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
