/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import PasswordInput from "../../components/input/passwordinput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useSignIn } from "../../query/useAuthentication";
import { Helmet } from "react-helmet";
import { LoadingButton, LogoLoading } from "../../components/loading/loading";

const SignIn = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const { mutate, isLoading, isError } = useSignIn();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email,
      password,
    };

    mutate(data, {
      onSuccess: (data) => {
        if (data.token) {
          localStorage.setItem("token", data.token);
          toast.success("Logged in successfully");
          navigate("/dashboard");
        } else {
          toast.success(data.message);
          navigate(`/verify-email?id=${data.verificiation_id}`);
        }
      },
      onError: (error) => {
        if (error.response.status === 500) {
          toast.error(
            `Something went wrong, try again later ${error.response.status}`
          );
        } else {
          toast.error(error.response.data.detail);
        }
      },
    });
  };

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <div
      className={`w-full min-h-screen container flex lg:items-center lg:justify-center  bg-[#F7F7F7]`}
    >
      <Helmet>
        <link rel="canonical" href="https://www.magicreview.ai/sign-in" />
      </Helmet>
      <div className="w-full lg:w-1/2 flex flex-col md:justify-center md:items-center">
        <div className="flex flex-col items-center justify-center w-full mt-10 mb-5">
          <Link to="/">
            <img
              src="/new-logo-with-sidetext.svg"
              alt="logo"
              className="object-contain w-auto"
            />
          </Link>
          <h1 className="text-xl lg:text-[32px] leading-8 md:-tracking-[0.5px] font-bold text-[#323131] mt-[20px] font-opensans">
            Log Into Your Account
          </h1>
          {/* <p className="text-[#10182899] text-center text-sm mt-3 font-montserrat">
            Enter the fields below to get started
          </p> */}
        </div>
        <div className="w-full lg:max-w-md mx-auto mt-[30px]">
          <form className="flex flex-col gap-[21px]">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="email"
                className="text-sm text-[#585866] font-montserrat"
              >
                Email address
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                placeholder="e.g. mail@domain.com"
                className="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans placeholder:text-[#81818F]"
              />
            </div>
            <PasswordInput
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              loginPage={true}
            />
            <div className="flex items-center w-full justify-end">
              <Link
                to="/forgot-password"
                type="button"
                className="text-sm text-secondary hover:underline text-right font-montserrat
                    hover:text-secondary font-semibold
                  "
              >
                Forgot your Password?
              </Link>
            </div>
            <button
              disabled={!email.trim() || !password.trim() || isLoading}
              onClick={handleSubmit}
              type="submit"
              className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium active:scale-95"
            >
              {/* {isLoading ? <LoadingButton /> : "Log In"} */}
              Log In
            </button>
          </form>
          {/* <div className="flex items-center justify-center w-full mt-[30.57px]">
            <button
              className="w-full flex justify-center items-center gap-3 border border-[#C2C2C2] py-2.5 rounded-lg active:scale-95"
              onClick={handleGoogleLogin}
              disabled
            >
              <img loading="lazy"src="./google.svg" alt="" />
              <p className="font-montserrat text-[15px] text-[#2424247D]">
                Sign in with Google
              </p>
            </button>
          </div> */}
        </div>

        {/* /* Google Button */}

        <p className="text-gray-600 text-center lg:text-base text-sm mt-5 font-montserrat">
          Don&apos;t have an account?{" "}
          <Link
            to="/sign-up"
            className="text-[#4F46BA] hover:text-blue-600 font-semibold"
          >
            Sign up
          </Link>
        </p>
      </div>
      {/* <div className="w-full md:w-1/2 md:block hidden pl-10">
        <div className="max-w-[484px] mx-auto w-full rounded-tl-[100px] pt-[39px] px-[10px] relative bg-[#6B61E1] flex flex-col items-center justify-center">
          <div className="w-[60px] h-[60px] lg:flex justify-center  hidden rounded-full bg-white absolute right-[450px] bottom-[110px]">
            <img loading="lazy"src="/frown.svg" alt="logo" className=" w-[45px] " />
          </div>
          <div className="w-[60px] h-[60px] lg:flex justify-center rounded-full bg-white hidden absolute left-[450px] top-[190px]">
            <img loading="lazy"src="/smile.svg" alt="logo" className=" w-[45px]" />
          </div>
          <h1 className="text-[32px] font-bold font-opensans text-white mb-[30px]">
            Very good works are <WordBreak /> waiting for you.
            <WordBreak />
            Login Now!
          </h1>
          <LazyLoadImage
            src="/signin.png"
            className="max-w-[350px] w-full mx-auto"
          />
        </div>
      </div> */}
    </div>
  );
};

export default SignIn;
