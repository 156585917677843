/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import Layout from "./component/Layout";
import React, { PureComponent, useEffect, useRef } from "react";
import { DashboardLoading } from "../../components/loading/loading";
import { useState } from "react";
import { useAuth } from "../../context/authContext";
import Chart2 from "./component/chart";
import NoPlanModal from "./component/PlanModal";
import { ExpiredPlanModal } from "./component/PlanModal";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

// import { Select } from "flowbite-react";
import Slider from "./component/slider";
import { useGetStats, useGetStatsGraph } from "../../query/useEntity";
import { Link, useSearchParams } from "react-router-dom";
import { useUser } from "../../query/useAuthentication";
import { toast } from "sonner";
import {
  BarChart4,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CircleCheckBig,
  CircleX,
  Sparkles,
  SquareCheckBig,
  Star,
} from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useWindowSize from "../../hooks/useWindowSize";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import Google from "../../assets/reviewservices/google.svg";
import TrustPilot from "../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../assets/reviewservices/yelp.svg";
import Booking from "../../assets/reviewservices/booking.svg";
import TripAdvisor from "../../assets/reviewservices/tripadvisor.svg";
import { usePostVerifyPayment } from "../../query/useSubscription";
import { Modal } from "flowbite-react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { dayFormatter } from "../../utils/formatter";
import AiReviewPosted from "./routes/components/AiReviewPosted";
import { useGetAnnouncement } from "../../query/useAnnouncement";
import { useDarkMode } from "../../context/DarkModeProvider";

const platforms = [
  {
    name: "Google",
    value: "google",
    image: Google,
  },
  {
    name: "Trustpilot",
    value: "trustpilot",
    image: TrustPilot,
  },
  {
    name: "Yelp",
    value: "yelp",
    image: Yelp,
  },
  {
    name: "Booking",
    value: "booking",
    image: Booking,
  },
  {
    name: "TripAdvisor",
    value: "tripadvisor",
    image: TripAdvisor,
  },
];

const Dashboard = () => {
  const { setUserInfo } = useAuth();
  const [parmas] = useSearchParams();
  const sessionId = parmas.get("session_id");
  const [selectedPlatforms, setSelectedPlatforms] = useState("google");

  const { darkMode } = useDarkMode();

  const { data: stats, isLoading: isStatsLoading } = useGetStats();
  // const { data: announcement } = useGetAnnouncement();

  const { mutate: verifyPayment } = usePostVerifyPayment();

  const { refetch } = useUser({
    onSuccess: (res) => {
      setUserInfo(res);
      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("entityUuid", res?.entity?.uuid);
    },
  });

  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleSelectedServices = (platform) => {
    setSelectedPlatforms(platform);
  };

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});

  useEffect(() => {
    if (sessionId) {
      verifyPayment(
        { session_id: sessionId },
        {
          onSuccess: (res) => {
            refetch();
            toast.success(res.message);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  }, [sessionId]);

  const isMainLoading = isStatsLoading;

  return (
    <Layout>
      <div className="flex flex-col mt-[20px] w-full dark:bg-darkLightBg rounded-[16px] p-5 sm:p-[31px] min-h-[calc(100vh-200px)] 3xl:min-h-[calc(100vh-150px)]">
        {/* <div className="flex justify-between items-center w-full lg:w-[72%] ">
          <div
            className="prev-service cursor-pointer border border-[#f6f6ff] hover:border-[#F1F4FF] hover:bg-[#F1F4FF] w-fit rounded-full lg:p-2 active:scale-95 text-[#615e83] hover:text-secondary hidden lg:block xl:hidden"
            onClick={handlePrev}
          >
            <ChevronLeft />
          </div>
          <Swiper
            ref={swiperRef}
            modules={[Navigation]}
            navigation={{
              nextEl: "next-service",
              prevEl: "prev-service",
            }}
            // breakpoints={{
            //   200: {
            //     slidesPerView: 2.5,
            //   },
            //   768: {
            //     slidesPerView: 3,
            //   },
            //   1280: {
            //     slidesPerView: 4,
            //   },
            //   1440: {
            //     slidesPerView: 5,
            //   },
            // }}
            slidesPerView={"auto"}
            spaceBetween={10}
            className="py-3 lg:px-2 w-full"
          >
            {platforms.map((item) => (
              <SwiperSlide
                key={item.value}
                className={`py-1.5 px-2.5 text-center rounded-full cursor-pointer flex items-center gap-x-2 w-max flex-shrink border ${selectedPlatforms.includes(item.value) ? "bg-[#7190ff1f] border-[rgba(107,141,208,0.41)]" : "bg-white"}`}
                onClick={() => handleSelectedServices(item.value)}
              >
                <div className="bg-white rounded-full w-6 h-6 p-0.5 flex-shrink-0 flex justify-center items-center">
                  <img src={item.image} alt="" />
                </div>
                <p className="text-sm">{item.name}</p>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="next-service cursor-pointer border border-[#f6f6ff] hover:border-[#F1F4FF] hover:bg-[#F1F4FF] w-fit rounded-full lg:p-2 active:scale-95 text-[#615e83] hover:text-secondary hidden lg:block xl:hidden"
            onClick={handleNext}
          >
            <ChevronRight />
          </div>
        </div> */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className=" w-full gap-5 font-pro font-medium">
            {
              <div className="grid md:grid-cols-2 gap-5 w-full mb-5">
                <div className="flex flex-col rounded-[12px] p-3 bg-white dark:bg-darkBg shadow-sm 3xl:p-5 generated">
                  <div className="flex items-center gap-x-3">
                    {isMainLoading ? (
                      <Skeleton
                        circle
                        className="w-10 h-10 3xl:w-14 3xl:h-14"
                        containerClassName="flex-1"
                        inline
                        baseColor={darkMode && "#262626"}
                      />
                    ) : (
                      <div className="p-2 rounded-full bg-[#f5f7ff] dark:bg-darkLightBg">
                        <Sparkles className="text-[#4770FF] 3xl:w-10 3xl:h-10" />
                      </div>
                    )}
                    {isMainLoading ? (
                      <Skeleton
                        containerClassName="flex-1"
                        inline
                        className="w-full"
                        baseColor={darkMode && "#262626"}
                      />
                    ) : (
                      <p className="font-medium 3xl:text-xl">
                        AI Reviews Generated
                      </p>
                    )}
                  </div>
                  {isMainLoading ? (
                    <Skeleton
                      className="mt-5"
                      baseColor={darkMode && "#262626"}
                    />
                  ) : (
                    <p className="text-center text-[40px] 3xl:text-6xl">
                      {stats?.total_ai_generated_reviews || 0}
                    </p>
                  )}
                </div>
                <div className="flex flex-col rounded-[12px] p-3 3xl:p-5 bg-white dark:bg-darkBg shadow-sm redirected">
                  <div className="flex items-center gap-x-3">
                    {isMainLoading ? (
                      <Skeleton
                        circle
                        className="w-10 h-10 3xl:w-14 3xl:h-14"
                        containerClassName="flex-1"
                        inline
                        baseColor={darkMode && "#262626"}
                      />
                    ) : (
                      <div className="p-2 rounded-full bg-[#f5f7ff] dark:bg-darkLightBg">
                        <SquareCheckBig className="text-[#4770FF] 3xl:w-10 3xl:h-10" />
                      </div>
                    )}
                    {isMainLoading ? (
                      <Skeleton
                        containerClassName="flex-1"
                        inline
                        className="w-full"
                        baseColor={darkMode && "#262626"}
                      />
                    ) : (
                      <p className="font-medium 3xl:text-xl">
                        AI Reviews Redirected
                      </p>
                    )}
                  </div>
                  {isMainLoading ? (
                    <Skeleton
                      className="mt-5"
                      baseColor={darkMode && "#262626"}
                    />
                  ) : (
                    <p className="text-center text-[40px] 3xl:text-6xl">
                      {stats?.total_ai_posted_reviews || 0}
                    </p>
                  )}
                </div>
              </div>
            }
            <AiReviewPosted />
          </div>
          <div className="p-5 lg:p-8 pb-5 3xl:p-12 flex flex-col rounded-[12px] bg-white dark:bg-darkBg w-full shadow-sm">
            <header className="flex justify-between items-center mb-1">
              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <>
                  <h1 className="font-pro text-[20px] 3xl:text-3xl">
                    Latest Review
                  </h1>
                  <Link
                    to="/dashboard/reviews"
                    className="w-fit h-10 bg-[#f6f6ff] dark:bg-darkLightBg rounded-lg flex justify-center items-center px-5 text-[#615e83] dark:text-secondary font-poppins text-sm gap-x-2"
                  >
                    View All
                    <ChevronRight className="w-5 h-5" />
                  </Link>
                </>
              )}
            </header>
            <Slider isMainLoading={isMainLoading} />
          </div>
        </div>
        {/* <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-5 font-pro font-medium">
          {
            <>
              {/* <div
                className={`flex flex-col rounded-[12px] p-3 3xl:p-5 shadow-sm ${isMainLoading ? "bg-white" : "bg-secondary"}`}
              >
                <div className="flex items-center gap-x-3">
                  {isMainLoading ? (
                    <Skeleton
                      circle
                      className="w-10 h-10 3xl:w-14 3xl:h-14"
                      containerClassName="flex-1"
                      inline
                    />
                  ) : (
                    <div className="p-2 rounded-full bg-[#8fa7ff]">
                      <BarChart4 className="text-white 3xl:w-10 3xl:h-10" />
                    </div>
                  )}
                  {isMainLoading ? (
                    <Skeleton
                      containerClassName="flex-1"
                      inline
                      className="w-full"
                    />
                  ) : (
                    <p className="text-white font-medium 3xl:text-xl">
                      Actual User Reviews
                    </p>
                  )}
                </div>
                {isMainLoading ? (
                  <Skeleton className="mt-5" />
                ) : (
                  <p className="text-center text-[40px] 3xl:text-6xl text-white">
                    {stats?.platforms[selectedPlatforms]?.total_reviews_count ||
                      0}
                  </p>
                )}
              </div> 
              <div className="flex flex-col rounded-[12px] p-3 bg-white shadow-sm 3xl:p-5">
                <div className="flex items-center gap-x-3">
                  {isMainLoading ? (
                    <Skeleton
                      circle
                      className="w-10 h-10 3xl:w-14 3xl:h-14"
                      containerClassName="flex-1"
                      inline
                    />
                  ) : (
                    <div className="p-2 rounded-full bg-[#f5f7ff]">
                      <Sparkles className="text-[#4770FF] 3xl:w-10 3xl:h-10" />
                    </div>
                  )}
                  {isMainLoading ? (
                    <Skeleton
                      containerClassName="flex-1"
                      inline
                      className="w-full"
                    />
                  ) : (
                    <p className="font-medium 3xl:text-xl">
                      AI Reviews Generated
                    </p>
                  )}
                </div>
                {isMainLoading ? (
                  <Skeleton className="mt-5" />
                ) : (
                  <p className="text-center text-[40px] 3xl:text-6xl">
                    {stats?.total_ai_generated_reviews || 0}
                  </p>
                )}
              </div>
              <div className="flex flex-col rounded-[12px] p-3 3xl:p-5 bg-white shadow-sm">
                <div className="flex items-center gap-x-3">
                  {isMainLoading ? (
                    <Skeleton
                      circle
                      className="w-10 h-10 3xl:w-14 3xl:h-14"
                      containerClassName="flex-1"
                      inline
                    />
                  ) : (
                    <div className="p-2 rounded-full bg-[#f5f7ff]">
                      <SquareCheckBig className="text-[#4770FF] 3xl:w-10 3xl:h-10" />
                    </div>
                  )}
                  {isMainLoading ? (
                    <Skeleton
                      containerClassName="flex-1"
                      inline
                      className="w-full"
                    />
                  ) : (
                    <p className="font-medium 3xl:text-xl">
                      AI Reviews Redirected
                    </p>
                  )}
                </div>
                {isMainLoading ? (
                  <Skeleton className="mt-5" />
                ) : (
                  <p className="text-center text-[40px] 3xl:text-6xl">
                    {stats?.total_ai_posted_reviews || 0}
                  </p>
                )}
              </div>
              {/* <div className="flex flex-col rounded-[12px] p-3 3xl:p-5 bg-white shadow-sm">
                <div className="flex items-center gap-x-3">
                  {isMainLoading ? (
                    <Skeleton
                      circle
                      className="w-10 h-10 3xl:w-14 3xl:h-14"
                      containerClassName="flex-1"
                      inline
                    />
                  ) : (
                    <div className="p-2 rounded-full bg-[#f5f7ff]">
                      <Star className="text-[#4770FF] 3xl:w-10 3xl:h-10" />
                    </div>
                  )}
                  {isMainLoading ? (
                    <Skeleton
                      containerClassName="flex-1"
                      inline
                      className="w-full"
                    />
                  ) : (
                    <p className="font-medium 3xl:text-xl">Review</p>
                  )}
                </div>
                {isMainLoading ? (
                  <Skeleton className="mt-5" />
                ) : (
                  <p className="text-center text-[40px] 3xl:text-6xl">
                    {stats?.platforms[selectedPlatforms]?.rating || 0}{" "}
                    <span className="text-[46px] 3xl:text-7xl text-[#838383]">
                      /
                    </span>{" "}
                    <span className="text-[32px] 3xl:text-5xl text-[#838383]">
                      5
                    </span>
                  </p>
                )}
              </div>
            </>
          }
        </div> */}
        {/* <div className="flex xl:flex-row flex-col gap-5 mt-[20px] 3xl:mt-10 w-full"> */}
        {/* <AiReviewPosted /> */}
        {/* <div className="p-5 lg:p-8 pb-5 3xl:p-12 flex flex-col rounded-[12px] bg-white w-full xl:w-[45%] shadow-sm">
            <header className="flex justify-between items-center mb-1">
              {isMainLoading ? (
                <Skeleton containerClassName="flex-1" />
              ) : (
                <h1 className="font-pro text-black text-[20px] 3xl:text-3xl">
                  Latest Review
                </h1>
              )}
              {isMainLoading ? (
                <Skeleton containerClassName="flex-1" />
              ) : (
                <Link
                  to="/dashboard/reviews"
                  className="w-fit h-10 bg-[#f6f6ff] rounded-lg flex justify-between items-center px-5 text-[#615e83] font-poppins text-sm"
                >
                  View All
                </Link>
              )}
            </header>
            <Slider isMainLoading={isMainLoading} />
          </div> */}

        {/* <div className="flex flex-col gap-[15px] w-full lg:w-[55%] h-full">
            
            <div className="flex flex-col w-full p-[22px] rounded-[12px] bg-white justify-center">
              <header className="flex justify-between w-full mb-[60px]">
                <h1 className="font-pro text-black text-[20px]">Review</h1>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 items-center">
                    <div className="w-[12px] h-[12px] bg-[#D9D9D9] rounded-full"></div>
                    <p className="text-pro text-[12px]">Review Generated</p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-[12px] h-[12px] bg-secondary rounded-full"></div>
                    <p className="text-pro text-[12px]">Review Posted</p>
                  </div>
                </div>
              </header>

              <Chart2
                generated={stats?.total_ai_reviews_generated}
                posted={stats?.total_ai_reviews_posted}
              />
            </div>
          </div> */}
        {/* </div> */}
      </div>
      <Modal
        dismissible
        show={isPaymentSuccess}
        onClose={() => {
          setIsPaymentSuccess(false);
        }}
        className="z-[1000] "
        size={"sm"}
        theme={{
          content: {
            base: "relative w-full p-4 h-auto !ring-0 !outline-none !shadow-none !border-none !focus:ring-0 !focus:outline-none !focus:border-none !focus:shadow-none",
          },
        }}
      >
        <Modal.Body className="h-auto">
          <div className="flex flex-col justify-center items-center">
            {paymentStatus?.status === "success" ? (
              <CircleCheckBig className="text-[#73DF07] w-10 h-10" />
            ) : (
              <CircleX className="text-red-500 w-10 h-10" />
            )}
            <p className="text-xl mt-5 font-medium">{paymentStatus?.message}</p>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};
export default Dashboard;
