/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import IconCopy from "../../../assets/icons/IconCopy";
import IconEdit from "../../../assets/icons/IconEdit";
import IconOpenLink from "../../../assets/icons/IconOpenLink";
import {
  useUpdateUser,
  useUpdateUserProfile,
  useUser,
  usePostToggleStaffManagement,
} from "../../../query/useAuthentication";
import { Input } from "../component/Input";
import Layout from "../component/Layout";
import ImagePreview from "./components/ImagePreview";
import { Switch } from "@headlessui/react";
import { Drawer } from "flowbite-react";
import {
  useDeleteEntityTheme,
  useGetEntityInfo,
  useGetEntityTheme,
  usePutUpdateEntityTheme,
  useUpdateEntityInfo,
} from "../../../query/useEntity";
import { PlusCircle } from "lucide-react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import TagsInput from "react-tagsinput";
import ImageCropper from "./components/ImageCropper";
import blobToFile from "../../../utils/blobToFile";
import { LogoLoading } from "../../../components/loading/loading";

const Account = () => {
  const inputRef = useRef(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [industry, setIndustry] = useState("");
  const [aboutBusiness, setAboutBusiness] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [croppedImg, setCroppedImg] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isActiveStaffManagement, setIsActiveStaffManagement] = useState(false);
  const [entityId, setEntityId] = useState();
  const [bgColor, setBgColor] = useState("");
  const [btnColor, setBtnColor] = useState("");

  // Assuming you have some way of determining the environment
  const baseUrl = window.location.origin;

  const { refetch } = useUser({
    onSuccess: (res) => {
      setFirstName(res.first_name);
      setLastName(res.last_name);
      setEmail(res.email);
      setEntityId(res.entity.id);
      setCompanyName(res.entity.name);
      setWebsiteLink(res.entity.company_link);
      setIsActiveStaffManagement(
        res.profile.user_settings_staff_management_enabled
      );
      setUserProfile(res.profile.profile_picture);
      setAboutBusiness(res.profile.about_business);
      setBusinessLocation(res.entity.location);
      setIndustry(res.entity.industry);
      localStorage.setItem("user", JSON.stringify(res));
    },
  });

  const { refetch: refetchEntityTheme } = useGetEntityTheme({
    onSuccess: (res) => {
      setBgColor(res?.data?.bg_color);
      setBtnColor(res?.data?.button_color);
    },
  });
  const { mutate: updateEntityTheme, isLoading: updateEntityThemeLoading } =
    usePutUpdateEntityTheme();
  const { mutate: deleteEntityTheme, isLoading: deleteEntityThemeLoading } =
    useDeleteEntityTheme();

  const { mutate: updateUser, isLoading: updateUserLoading } = useUpdateUser();
  const { mutate: updateUserProfile, isLoading: updateProfilePictureLoading } =
    useUpdateUserProfile();

  const handleUpdateUser = () => {
    if (aboutBusiness?.length > 1000) {
      toast.error("About business is exceeded 1000 characters");
      return;
    }
    updateUser(
      {
        business_name: companyName || null,
        company_link: websiteLink || null,
        google_review_link: null,
        phone_number: null,
        first_name: firstName || null,
        last_name: lastName || null,
        about_business: aboutBusiness || null,
        business_location: businessLocation || null,
      },
      {
        onSuccess: (res) => {
          toast.success("User details have been updated!");
          refetch();
        },
        onError: (err) => {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        },
      }
    );
  };

  const handleChangeProfileClick = () => {
    inputRef.current.click();
    setIsEdit(true);
  };

  const handleChangeProfile = (e) => {
    setProfilePicture(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setProfilePictureUrl(objectUrl);
    inputRef.current.value = "";
  };

  const handleUpdateUserProfile = async () => {
    const croppedImgFile = await blobToFile(
      croppedImg,
      profilePicture?.name
    ).then((file) => file);
    updateUserProfile(
      {
        profile_picture: croppedImgFile,
      },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          setCroppedImg(null);
          toast.success("Profile picture has been updated!");
          refetch();
        },
        onError: (err) => {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
          setCroppedImg(null);
        },
      }
    );
  };

  const handleUpdateEntityTheme = () => {
    updateEntityTheme(
      {
        bg_color: bgColor,
        button_color: btnColor,
      },
      {
        onSuccess: (res) => {
          toast.success("Updated entity theme successfully");
          refetchEntityTheme();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteEntityTheme = () => {
    deleteEntityTheme(
      {},
      {
        onSuccess: (res) => {
          toast.success("Reseted entity theme successfully");
          refetchEntityTheme();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (profilePictureUrl && isEdit) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [profilePictureUrl, isEdit]);

  if (
    updateEntityThemeLoading ||
    updateProfilePictureLoading ||
    updateUserLoading ||
    deleteEntityThemeLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Layout title="Account Details">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] p-5 lg:p-8">
        <div className="flex justify-between items-center flex-wrap gap-[20px]">
          <p className="font-pro font-medium text-xl">
            Manage Your Profile Settings
          </p>
        </div>
        <hr className="mt-6" />
        <div
          className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
        >
          <div className="w-full lg:col-span-2">
            <label className=" text-lg font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              Profile photo
            </label>
            <div className="flex items-center gap-x-3">
              <div
                className="relative w-16 h-16 cursor-pointer"
                onClick={handleChangeProfileClick}
              >
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  hidden
                  onChange={handleChangeProfile}
                />
                <img
                  className="h-14 w-14 shrink-0 rounded-full mr-[21px] object-cover"
                  src={userProfile || "/new-logo.svg"}
                  alt=""
                />
                <div className="w-6 h-6 bg-black/80 rounded-full absolute bottom-2 right-1 flex justify-center items-center">
                  <IconEdit className=" w-4 h-4 text-white" />
                </div>
              </div>

              <button
                className="font-pro text-[#a7a5a5]"
                onClick={handleChangeProfileClick}
              >
                Upload photo
              </button>
            </div>
          </div>
          <Input
            label="First Name"
            placeholder="e.g. John"
            value={firstName}
            setValue={setFirstName}
          />
          <Input
            label="Last Name"
            placeholder="e.g. Doe"
            value={lastName}
            setValue={setLastName}
          />

          <Input
            label="Company Name"
            placeholder="e.g. xyz"
            value={companyName}
            setValue={setCompanyName}
          />
          <Input
            label="Email Address"
            placeholder="e.g. xyz@example.com"
            value={email}
            setValue={setEmail}
            disabled={true}
          />
          <Input
            label="Industry"
            placeholder="e.g. restaurant"
            value={industry}
            setValue={setIndustry}
            disabled={true}
          />
          <Input
            label="Website Link"
            placeholder="e.g. https://www.company.in"
            value={websiteLink}
            setValue={setWebsiteLink}
          />

          {/* <div className="w-full relative">
            <label className="text-[#000] text-lg font-pro font-medium mb-[10px] flex gap-1">
              {"Your MagicReview link"}
            </label>
            <textarea
              className="border-[1px] border-[#E7E4E4] rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full pr-10 scrollbar-none disabled:text-[#A7A5A5]"
              type="text"
              placeholder={"Paste website link"}
              value={magicLink}
              onChange={(e) => setMagicLink(e.target.value)}
              disabled={true}
              rows={1}
            />
            <div className="absolute top-[54px] right-3 flex items-center gap-x-1">
              <IconCopy
                className="w-6 h-6 cursor-pointer"
                onClick={handleCopy}
              />
              <IconOpenLink
                className="w-6 h-6 cursor-pointer"
                onClick={handleGotoNewtab}
              />
            </div>
          </div> */}
          <Input
            label="Business Location"
            placeholder="e.g. Dubai"
            value={businessLocation}
            setValue={setBusinessLocation}
          />
          <div className="w-full">
            <label className=" text-lg font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              {"About Business"}
            </label>
            <textarea
              className="border-[1px] border-[#E7E4E4] dark:border-[#454545] rounded-[8px] pt-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full pr-10 scrollbar-none dark:bg-darkBg"
              type="text"
              placeholder={"Write about your business"}
              value={aboutBusiness}
              onChange={(e) => setAboutBusiness(e.target.value)}
              // disabled={aboutBusiness?.length >= 1000}
              rows={5}
            />
            <p className="text-right -mt-5 text-gray-500 font-pro text-xs">
              {aboutBusiness?.length || 0} / 1000 characters
            </p>
          </div>
        </div>
        <div className="w-full flex justify-end mt-5">
          <button
            className="bg-newPrimary border border-newPrimary w-[121px] py-3 rounded-[7px] text-white hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
            onClick={handleUpdateUser}
          >
            {/* {updateUserLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Save"
            )} */}
            Save
          </button>
        </div>
        <hr className="my-6" />
        <div className="flex justify-between items-center flex-wrap gap-[20px]">
          <p className="font-pro font-medium text-xl">Theme of Review Page</p>
        </div>
        {/* <hr className="mt-6" /> */}
        <div
          className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
        >
          <div className="w-full">
            <label className=" text-lg font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              Background Color
            </label>
            <input
              className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
              type="color"
              // placeholder={placeholder}
              value={bgColor}
              onChange={(e) => setBgColor(e.target.value)}
            />
          </div>
          <div className="w-full">
            <label className=" text-lg font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              Button Color
            </label>
            <input
              className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
              type="color"
              // placeholder={placeholder}
              value={btnColor}
              onChange={(e) => setBtnColor(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full flex justify-end items-center gap-5 flex-wrap mt-5">
          <button
            className="bg-newPrimary border border-newPrimary w-[121px] py-3 rounded-[7px] text-white hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
            onClick={handleDeleteEntityTheme}
            disabled={deleteEntityThemeLoading}
          >
            {/* {deleteEntityThemeLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Reset"
            )} */}
            Reset
          </button>
          <button
            className="bg-newPrimary border border-newPrimary w-[121px] py-3 rounded-[7px] text-white hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
            onClick={handleUpdateEntityTheme}
            disabled={updateEntityThemeLoading}
          >
            {/* {updateEntityThemeLoading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
              </div>
            ) : (
              "Save"
            )} */}
            Save
          </button>
        </div>
      </div>

      {/* <ImagePreview
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleUpdateUserProfile={handleUpdateUserProfile}
        profilePictureUrl={profilePictureUrl}
        isLoading={updateProfilePictureLoading}
      /> */}
      <ImageCropper
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleUpdateUserProfile={handleUpdateUserProfile}
        profilePictureUrl={profilePictureUrl}
        isLoading={updateProfilePictureLoading}
        croppedImage={croppedImg}
        setCroppedImage={setCroppedImg}
      />
    </Layout>
  );
};

export default Account;
